.filtersMap {
	padding: 0 !important;
	.filters {
		padding: 20px 24px 32px 24px;
		background: var(--Text-Gray-200, #eeeff4);
		border-radius: 12px;
		display: flex;
		flex-direction: column;
		align-items: start;
		gap: 16px;
		width: 100%;

		h3 {
			color: var(--Text-Gray-900, #000433);
			font-family: Manrope;
			font-size: 18px;
			font-style: normal;
			font-weight: 500;
			line-height: 24px; /* 133.333% */
		}

		.filtersForm {
			display: flex;
			flex-direction: column;
			gap: 20px;
			width: 100%;

			.input-wrapper {
				display: flex;
				flex-direction: column;
				gap: 12px;

				.form-label {
					color: var(--Text-Gray-700, #969bab);
					font-family: Manrope;
					font-size: 12px;
					font-style: normal;
					font-weight: 500;
					line-height: 12px; /* 100% */
					padding: 0;
					margin: 0;
				}

				input,
				textarea {
					padding: 16px;
					border-radius: 8px;
					position: relative;
					box-shadow: none;
					border: 1px solid #d9dbe1;

					&:hover {
						border: 1px solid #969bab;
					}

					&:active,
					&:focus,
					&:focus-visible {
						border: 1px solid #8c30f5;
						outline: none;
					}

					&.is-invalid {
						border-color: var(--bs-form-invalid-border-color);
					}

					&::placeholder {
						color: #969bab;
					}
				}

				&.datepicker {
					display: flex;
					flex-direction: column;
				}
			}

			.formsRow {
				display: flex;
				align-items: center;
				gap: 18px;
			}

			.showTerminalCheckbox {
				display: flex;
				gap: 8px;
				align-items: center;
				accent-color: #00ffd0;
				border-radius: 100px;
				margin: 4px 0;

				.form-check-input {
					width: 24px;
					height: 24px;
					padding: 0;
					margin: 0;
					border-radius: 4px !important;
					box-shadow: none;

					&:checked {
						background-color: #8c30f5;
						border-color: #8c30f5;
						// background-image: url('../../assets/icons/Line.svg');
						box-shadow: none;
						position: relative;
						background-image: none;

						&::before {
							content: '';
							position: absolute;
							top: 0;
							left: 0;
							width: 100%;
							height: 100%;
							background-image: url('../../../../assets/icons/Line.svg');
							background-size: cover;
							background-repeat: no-repeat;
							filter: brightness(0) saturate(100%) invert(100%) sepia(0%)
								saturate(0%) hue-rotate(182deg) brightness(106%) contrast(101%);
						}
					}
				}

				.form-check-label {
					color: var(--Text-Gray-900, #000433);
					font-feature-settings: 'liga' off;
					/* Body / Body 2 */
					font-family: Manrope;
					font-size: 14px;
					font-style: normal;
					font-weight: 400;
					line-height: 24px; /* 171.429% */
				}
			}

			.formBtns {
				display: flex;
				flex-direction: column;
				align-items: center;
				gap: 12px;

				.searchBtn {
					background-color: #00ffd0;
					border-color: #00ffd0;
					border-radius: 12px;
					color: #000433 !important;
					font-size: 16px;
					font-weight: 700;
					line-height: 26px;
					padding: 8px 32px;
					width: 100%;

					&:hover {
						background-color: #41ffdc !important;
						border-color: #41ffdc !important;
					}

					&:active {
						background-color: #02fbcd !important;
						border-color: #02fbcd !important;
					}

					&:disabled {
						background-color: #80ffe7 !important;
						border-color: #80ffe7 !important;
					}
				}

				.clearFiltersBtn {
					border: none;
					background: none;
					box-shadow: none;
					padding: 0;
					margin: 0;

					color: var(--Brand-Purple, #8c30f5);
					font-feature-settings: 'liga' off;
					/* Body / Body 2 */
					font-family: Manrope;
					font-size: 14px;
					font-style: normal;
					font-weight: 400;
					line-height: 24px; /* 171.429% */
				}
			}
		}
	}
}
