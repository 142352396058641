.desktop {
	.mapBtn {
		border-radius: 12px;
		width: 310px;
		height: 170px;
		background-image: url('../../../../assets/ba8ef247bb119584f8f46de421b9fb76.png');
		background-size: 100%;
		background-position: center;
		background-repeat: no-repeat;
		display: flex;
		align-items: end;
		justify-content: center;
		padding: 20px 48px;

		.openMapBtn {
			padding: 8px 32px;
			border-radius: 8px;
			background: var(--Brand-Purple, #8c30f5);
			border-color: #8c30f5;

			color: var(--Default-White, #fff);
			text-align: center;
			font-feature-settings: 'liga' off;
			font-family: Manrope;
			font-size: 16px;
			font-style: normal;
			font-weight: 700;
			line-height: 26px; /* 162.5% */
			text-wrap: nowrap;

			&:hover {
				background-color: #923bf4 !important;
				border-color: #923bf4 !important;
			}

			&:active {
				background-color: #8830ec !important;
				border-color: #8830ec !important;
			}

			&:disabled {
				background-color: #c597fa !important;
				border-color: #c597fa !important;
			}
		}
	}
}

.tablet {
	.mapBtn {
		border-radius: 12px;
		width: 310px;
		height: 72px;
		background-image: url('../../../../assets/ba8ef247bb119584f8f46de421b9fb76.png');
		background-size: 100%;
		background-position: center;
		background-repeat: no-repeat;
		display: flex;
		align-items: end;
		justify-content: center;
		padding: 12px 48px;

		.openMapBtn {
			padding: 8px 32px;
			border-radius: 8px;
			background: var(--Brand-Purple, #8c30f5);
			border-color: #8c30f5;

			color: var(--Default-White, #fff);
			text-align: center;
			font-feature-settings: 'liga' off;
			font-family: Manrope;
			font-size: 16px;
			font-style: normal;
			font-weight: 700;
			line-height: 26px; /* 162.5% */
			text-wrap: nowrap;

			&:hover {
				background-color: #923bf4 !important;
				border-color: #923bf4 !important;
			}

			&:active {
				background-color: #8830ec !important;
				border-color: #8830ec !important;
			}

			&:disabled {
				background-color: #c597fa !important;
				border-color: #c597fa !important;
			}
		}
	}
}

.mobile {
	.mapBtn {
		border-radius: 12px;
		width: 100%;
		height: 72px;
		background-image: url('../../../../assets/ba8ef247bb119584f8f46de421b9fb76.png');
		background-size: 100%;
		background-position: center;
		background-repeat: no-repeat;
		display: flex;
		align-items: end;
		justify-content: center;
		padding: 12px 48px;

		.openMapBtn {
			padding: 8px 32px;
			border-radius: 8px;
			background: var(--Brand-Purple, #8c30f5);
			border-color: #8c30f5;

			color: var(--Default-White, #fff);
			text-align: center;
			font-feature-settings: 'liga' off;
			font-family: Manrope;
			font-size: 16px;
			font-style: normal;
			font-weight: 700;
			line-height: 26px; /* 162.5% */
			text-wrap: nowrap;

			&:hover {
				background-color: #923bf4 !important;
				border-color: #923bf4 !important;
			}

			&:active {
				background-color: #8830ec !important;
				border-color: #8830ec !important;
			}

			&:disabled {
				background-color: #c597fa !important;
				border-color: #c597fa !important;
			}
		}
	}
}
