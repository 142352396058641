.desktop {
	.objectPage {
		padding-top: 42px;
		padding-bottom: 140px;
		display: flex;
		flex-direction: column;
		align-items: start;
		gap: 24px;

		.nameMeta {
			position: relative;
			display: flex;
			flex-direction: column;
			align-items: start;
			gap: 8px;

			.name {
				color: var(--Text-Gray-900, #000433);
				font-feature-settings: 'liga' off;
				/* Heading/H5 */
				font-family: Manrope;
				font-size: 24px;
				font-style: normal;
				font-weight: 700;
				line-height: 32px; /* 133.333% */
			}

			.meta {
				display: flex;
				align-items: center;
				gap: 24px;

				.location {
					display: flex;
					align-items: center;
					gap: 4px;

					a {
						color: var(--Brand-Purple, #8c30f5);
						font-feature-settings: 'liga' off;
						font-family: Manrope;
						font-size: 14px;
						font-style: normal;
						font-weight: 400;
						line-height: 24px; /* 171.429% */
						text-decoration-line: underline;
					}
				}

				.siteId {
					color: var(--Brand-Purple, #8c30f5);
					font-feature-settings: 'liga' off;
					font-family: Manrope;
					font-size: 14px;
					font-style: normal;
					font-weight: 400;
					line-height: normal;
					text-decoration-line: underline;
				}

				.createDate,
				.actualDate {
					display: flex;
					align-items: center;
					gap: 5px;

					p {
						&:first-child {
							color: var(--Text-Gray-700, #969bab);
							font-feature-settings: 'liga' off, 'clig' off;
							font-family: Manrope;
							font-size: 14px;
							font-style: normal;
							font-weight: 400;
							line-height: 18px; /* 128.571% */
						}

						&:last-child {
							color: var(--Text-Gray-700, #969bab);
							font-feature-settings: 'liga' off, 'clig' off;
							font-family: Manrope;
							font-size: 14px;
							font-style: normal;
							font-weight: 600;
							line-height: 18px; /* 128.571% */
						}
					}
				}
			}

			.backBtn {
				position: absolute;
				border-radius: 50%;
				padding: 9px;
				border-color: #d9dbe1 !important;
				box-shadow: none !important;
				background: none !important;
				left: -54px;
				top: 0;
			}
		}

		.objectInnerCard {
			display: flex;
			justify-content: space-between;
			width: 100%;

			.leftColumn {
				display: flex;
				flex-direction: column;
				gap: 32px;
				// width: 61%;
				width: 672px;

				.photos {
					position: relative;
					border-radius: 12px;
					border: 1px solid var(--Text-Gray-300, #d9dbe1);
					padding: 12px;
					display: flex;
					flex-direction: column;
					gap: 16px;

					.fade {
						width: 50px;
						height: 120px;
						position: absolute;
						right: 12px;
						bottom: 12px;
						background: linear-gradient(
							270deg,
							#fff 0%,
							rgba(255, 255, 255, 0) 100%
						);
						opacity: 1;
						pointer-events: none;
					}
				}

				.mainPhoto {
					position: relative;
					display: flex;
					align-items: center;
					justify-content: center;
					width: 652px;
					height: 368px;

					.slick-slider {
						position: relative;
						width: 652px;
						height: 368px;

						.slick-prev,
						.slick-next {
							position: absolute;
							background-image: url('../../assets/icons/chevron-right.svg');
							background-size: 32px 32px;
							background-position: center;
							background-repeat: no-repeat;
							padding: 16px;
							z-index: 1;

							&::before {
								display: none;
							}
						}

						.slick-prev {
							top: 162.5px;
							left: 12px;
							transform: rotate(180deg);
						}

						.slick-next {
							right: 12px;
						}

						.slick-list {
							border-radius: 12px;
							height: 100%;

							.slick-track {
								height: 100%;
								.slick-slide {
									height: 100%;
									div {
										width: 100%;
										height: 100%;
									}
									img {
										object-fit: cover;
										height: 100%;
									}
								}
							}
						}

						.slick-dots {
							position: absolute;
							padding: 0 12px;
							bottom: 0px;

							li {
								button:before {
									color: transparent;
									border: 2px solid #fff;
									border-radius: 50%;
									width: 10px;
									height: 10px;
								}
							}

							li.slick-active {
								button:before {
									border: 2px solid #fff;
									background-color: #fff;
									opacity: 1;
								}
							}
						}
					}

					.noPhoto {
						height: 365px;
						object-fit: cover;
						border-radius: 12px;
					}

					.logo {
						position: absolute;
						top: 159.25px;
						left: 233.305px;
						filter: brightness(0) saturate(100%) invert(11%) sepia(25%)
							saturate(7002%) hue-rotate(225deg) brightness(58%) contrast(115%);

						img {
							width: 48px !important;
							height: auto;
						}

						span {
							color: var(--Text-Gray-900, #000433);
							font-feature-settings: 'liga' off, 'clig' off;
							font-family: Caros;
							font-size: 27px;
							font-style: normal;
							font-weight: 700;
							line-height: 30px; /* 111.111% */
						}
					}
				}

				.etcPhotos {
					// display: flex;
					gap: 12px;
					width: 100% !important;
					justify-content: space-between;
					overflow-x: hidden;
					cursor: grab;

					&.grabbing {
						cursor: grabbing;
					}

					.slick-slider {
						.slick-list {
							border-radius: 12px;

							.slick-track {
								display: flex !important;
								justify-content: start;
								gap: 12px;

								&::before,
								&::after {
									display: none;
								}

								.slick-slide {
									width: fit-content !important;

									.etcPhoto {
										width: 212px !important;
										height: 120px !important;
										object-fit: cover;
										border-radius: 12px;
										box-sizing: border-box;
									}
								}
							}
						}
					}
				}

				.favouriteBtn {
					padding: 8px;
					border-radius: 50%;
					background-color: #ffffff;
					border-color: #969bab2f;
					box-shadow: none;
					position: absolute;
					top: 15px;
					right: 15px;

					img {
						filter: brightness(0) saturate(100%) invert(11%) sepia(25%)
							saturate(7002%) hue-rotate(225deg) brightness(58%) contrast(115%);
					}
				}

				.descriptionPrice {
					display: flex;
					align-items: start;
					justify-content: space-between;

					.title {
						color: var(--Text-Gray-900, #000433);
						font-feature-settings: 'liga' off;
						/* Heading/H6 */
						font-family: Manrope;
						font-size: 20px;
						font-style: normal;
						font-weight: 600;
						line-height: 30px; /* 150% */
					}

					.description {
						max-width: 436px;
						display: flex;
						flex-direction: column;
						gap: 12px;
						align-items: start;

						.content {
							color: var(--Text-Gray-900, #000433);
							font-feature-settings: 'liga' off;
							/* Body / Body 2 */
							font-family: Manrope;
							font-size: 14px;
							font-style: normal;
							font-weight: 400;
							line-height: 24px; /* 171.429% */
							text-wrap: pretty;
						}

						.moreBtn {
							color: var(--Brand-Purple, #8c30f5);
							font-feature-settings: 'liga' off;
							/* Lead / Lead 2 */
							font-family: Manrope;
							font-size: 14px;
							font-style: normal;
							font-weight: 500;
							line-height: 24px; /* 171.429% */
							text-decoration: none;
							width: fit-content;
							height: fit-content;
							background: none;
							border: none;
							box-shadow: none;
							padding: 0;
						}
					}

					.price {
						display: flex;
						flex-direction: column;
						gap: 12px;
						align-items: start;

						.content {
							color: var(--Text-Gray-900, #000433);
							font-feature-settings: 'liga' off;
							font-family: Manrope;
							font-size: 24px;
							font-style: normal;
							font-weight: 700;
							line-height: 30px; /* 125% */

							span {
								font-size: 20px;
								font-style: normal;
								font-weight: 700;
								line-height: 20px; /* 125% */
							}
						}
					}
				}

				.files {
					display: flex;
					flex-direction: column;
					gap: 12px;
					align-items: start;

					.title {
						color: var(--Text-Gray-900, #000433);
						font-feature-settings: 'liga' off;
						/* Heading/H6 */
						font-family: Manrope;
						font-size: 20px;
						font-style: normal;
						font-weight: 600;
						line-height: 30px; /* 150% */
					}

					.content {
						display: flex;
						flex-wrap: wrap;
						row-gap: 16px;
						gap: 12px;
						align-items: center;
					}
				}
			}

			.rightColumn {
				display: flex;
				flex-direction: column;
				gap: 32px;
				// width: 33%;
				width: 396px;

				.table {
					margin: 0;

					tbody {
						tr {
							border-style: none;

							th,
							td {
								border-style: none;
								padding: 0;
							}

							th {
								padding: 12px 12px 12px 0;
								color: var(--Text-Gray-900, #000433);
								font-family: Manrope;
								font-size: 16px;
								font-style: normal;
								font-weight: 700;
								line-height: 24px; /* 250% */
								width: 0%;
							}

							td {
								padding: 12px 0;
								color: var(--Text-Gray-900, #000433);
								font-family: Manrope;
								font-size: 16px;
								font-style: normal;
								font-weight: 400;
								line-height: 24px; /* 250% */
								width: 0%;
							}
						}
					}

					.types {
						width: 100%;
						display: flex;
						align-items: center;
						gap: 8px;
						flex-wrap: wrap;

						.type {
							padding: 4px 12px;
							background-color: #23ce6b;
							border-radius: 5px;
							width: fit-content;

							p {
								color: var(--Default-White, #fff);
								font-feature-settings: 'liga' off, 'clig' off;
								font-family: Manrope;
								font-size: 12px;
								font-style: normal;
								font-weight: 700;
								line-height: normal;
								text-wrap: nowrap;
							}
						}

						.typeTooltip {
							padding: 8px 16px;
							background: rgb(255, 255, 255);
							color: #000433;
							border: 1px solid var(--Text-Gray-300, #d9dbe1);
							border-radius: 12px;
							font-size: 14px;
							font-weight: 600;
							font-family: 'Manrope', sans-serif;
							line-height: 20px;
							opacity: 1;
							z-index: 1030;

							.react-tooltip-arrow {
								border-bottom: 1px solid var(--Text-Gray-300, #d9dbe1);
								border-right: 1px solid var(--Text-Gray-300, #d9dbe1);
							}
						}
					}

					.address {
						color: var(--Brand-Purple, #8c30f5);
						text-decoration: none;

						&:hover {
							text-decoration: underline;
							text-decoration-color: #8c30f5;
						}
					}

					.googleMapBtn {
						display: flex;
						width: fit-content;
						align-items: center;
						justify-content: center;
						border-radius: 50%;
						padding: 4px;
						border-color: #d9dbe1 !important;
						box-shadow: none !important;
						background: none !important;

						img {
							transform: rotate(180deg);
							width: 20px;
							height: 20px;
						}
					}

					.estimatedValue {
						span {
							font-size: 14px;
							font-style: normal;
							font-weight: 400;
							line-height: 14px;
						}
					}
				}

				.map {
					width: 100%;
					height: 230px;
					border-radius: 8px;
					overflow: hidden; /* Это ключевой момент для того, чтобы радиус применился */
					position: relative; /* Это для того, чтобы работали все стили */

					.activeMarker {
						width: 36px;
					}
				}

				.auctionInfo {
					.accordion-item {
						padding: 16px 16px 16px 20px;
						border-radius: 12px !important;
						position: relative;

						.accordion-button {
							padding: 0;
							background-color: white;
							box-shadow: none;

							color: var(--Text-Gray-900, #000433);
							font-feature-settings: 'liga' off;
							/* Subtitle / Subtitle 2 */
							font-family: Manrope;
							font-size: 18px;
							font-style: normal;
							font-weight: 500;
							line-height: 28px; /* 155.556% */

							&::after {
								filter: brightness(0) saturate(100%) invert(95%) sepia(6%)
									saturate(157%) hue-rotate(187deg) brightness(93%)
									contrast(91%);
							}
						}

						.accordion-header {
							border-radius: 12px !important;
						}

						.accordion-body {
							padding: 20px 0 22px 0;
							border-radius: 12px !important;

							display: flex;
							flex-direction: column;
							align-items: center;
							gap: 22px;

							.table {
								margin: 0;

								tbody {
									tr {
										border-style: none;

										th,
										td {
											border-style: none;
											padding: 0;
										}

										th {
											padding: 12px 12px 12px 0;
											color: var(--Text-Gray-900, #000433);
											font-family: Manrope;
											font-size: 16px;
											font-style: normal;
											font-weight: 700;
											line-height: 24px; /* 250% */
											width: 0%;
										}

										td {
											padding: 12px 0;
											color: var(--Text-Gray-900, #000433);
											font-family: Manrope;
											font-size: 16px;
											font-style: normal;
											font-weight: 400;
											line-height: 24px; /* 250% */
											width: 0%;
										}
									}
								}

								.terminated {
									color: #f5306b !important;
								}

								.auctionPrice {
									span {
										font-size: 14px;
										font-style: normal;
										font-weight: 400;
										line-height: 14px;
									}
								}

								.googleMapLink {
									color: var(--Brand-Purple, #8c30f5);
									text-decoration: none;

									&:hover {
										text-decoration: underline;
										text-decoration-color: #8c30f5;
									}
								}

								.gerlichtBtn {
									display: flex;
									width: fit-content;
									align-items: center;
									justify-content: center;
									border-radius: 50%;
									padding: 4px;
									border-color: #d9dbe1 !important;
									box-shadow: none !important;
									background: none !important;

									img {
										transform: rotate(180deg);
										width: 20px;
										height: 20px;
									}
								}
							}

							h5 {
								color: #f5306b !important;
								text-align: center;
							}
						}
					}
				}
			}
		}
	}
}

.tablet {
	.objectPage {
		padding-top: 32px;
		padding-bottom: 100px;
		display: flex;
		flex-direction: column;
		align-items: start;
		gap: 20px;

		.nameMeta {
			position: relative;
			display: flex;
			flex-direction: column;
			align-items: start;
			gap: 8px;

			.name {
				color: var(--Text-Gray-900, #000433);
				font-feature-settings: 'liga' off;
				/* Heading/H5 */
				font-family: Manrope;
				font-size: 24px;
				font-style: normal;
				font-weight: 700;
				line-height: 32px; /* 133.333% */
			}

			.meta {
				display: flex;
				align-items: center;
				gap: 24px;

				.location {
					display: flex;
					align-items: center;
					gap: 4px;

					a {
						color: var(--Brand-Purple, #8c30f5);
						font-feature-settings: 'liga' off;
						font-family: Manrope;
						font-size: 14px;
						font-style: normal;
						font-weight: 400;
						line-height: 24px; /* 171.429% */
						text-decoration-line: underline;
					}
				}

				.siteId {
					color: var(--Brand-Purple, #8c30f5);
					font-feature-settings: 'liga' off;
					font-family: Manrope;
					font-size: 14px;
					font-style: normal;
					font-weight: 400;
					line-height: normal;
					text-decoration-line: underline;
				}

				.createDate,
				.actualDate {
					display: flex;
					align-items: center;
					gap: 5px;

					p {
						&:first-child {
							color: var(--Text-Gray-700, #969bab);
							font-feature-settings: 'liga' off, 'clig' off;
							font-family: Manrope;
							font-size: 14px;
							font-style: normal;
							font-weight: 400;
							line-height: 18px; /* 128.571% */
						}

						&:last-child {
							color: var(--Text-Gray-700, #969bab);
							font-feature-settings: 'liga' off, 'clig' off;
							font-family: Manrope;
							font-size: 14px;
							font-style: normal;
							font-weight: 600;
							line-height: 18px; /* 128.571% */
						}
					}
				}
			}

			/* .backBtn {
				position: absolute;
				border-radius: 50%;
				padding: 9px;
				border-color: #d9dbe1 !important;
				box-shadow: none !important;
				background: none !important;
				left: -54px;
				top: 0;
				display: none;
			} */
		}

		.photoSlider {
			padding-top: 16px;
			display: flex;
			gap: 24px;
			flex-direction: row;
			flex-wrap: nowrap;
			overflow-x: scroll;
			max-width: 100%;
			scrollbar-width: thin;
			position: relative;

			&::after {
				content: '';
			}

			.favouriteBtn {
				padding: 8px;
				border-radius: 50%;
				background-color: #ffffff;
				border-color: #969bab2f;
				box-shadow: none;
				position: absolute;
				top: 15px;
				left: 15px;

				img {
					filter: brightness(0) saturate(100%) invert(11%) sepia(25%)
						saturate(7002%) hue-rotate(225deg) brightness(58%) contrast(115%);
				}
			}
		}

		.picture {
			border-radius: 8px;
			height: 368px;
		}

		.noPhoto {
			height: 368px;
			object-fit: cover;
			border-radius: 8px;
		}

		.logo {
			position: absolute;
			top: 160.75px;
			left: 235.93px;
			filter: brightness(0) saturate(100%) invert(11%) sepia(25%)
				saturate(7002%) hue-rotate(225deg) brightness(58%) contrast(115%);

			img {
				width: 48px !important;
				height: auto;
			}

			span {
				color: var(--Text-Gray-900, #000433);
				font-feature-settings: 'liga' off, 'clig' off;
				font-family: Caros;
				font-size: 27px;
				font-style: normal;
				font-weight: 700;
				line-height: 30px; /* 111.111% */
			}
		}

		.objectInnerCard {
			display: flex;
			justify-content: space-between;
			width: 100%;
			gap: 39px;

			.leftColumn {
				display: flex;
				flex-direction: column;
				gap: 32px;
				// width: 61%;
				width: 42%;

				.descriptionPrice {
					display: flex;
					flex-direction: column-reverse;
					align-items: start;
					gap: 24px;

					.title {
						color: var(--Text-Gray-900, #000433);
						font-feature-settings: 'liga' off;
						/* Heading/H6 */
						font-family: Manrope;
						font-size: 20px;
						font-style: normal;
						font-weight: 600;
						line-height: 30px; /* 150% */
					}

					.description {
						max-width: 100%;
						display: flex;
						flex-direction: column;
						gap: 12px;
						align-items: start;

						.content {
							color: var(--Text-Gray-900, #000433);
							font-feature-settings: 'liga' off;
							/* Body / Body 2 */
							font-family: Manrope;
							font-size: 14px;
							font-style: normal;
							font-weight: 400;
							line-height: 24px; /* 171.429% */
							text-wrap: pretty;
						}

						.moreBtn {
							color: var(--Brand-Purple, #8c30f5);
							font-feature-settings: 'liga' off;
							/* Lead / Lead 2 */
							font-family: Manrope;
							font-size: 14px;
							font-style: normal;
							font-weight: 500;
							line-height: 24px; /* 171.429% */
							text-decoration: none;
							width: fit-content;
							height: fit-content;
							background: none;
							border: none;
							box-shadow: none;
							padding: 0;
						}
					}

					.price {
						display: flex;
						flex-direction: column;
						gap: 12px;
						align-items: start;

						.content {
							color: var(--Text-Gray-900, #000433);
							font-feature-settings: 'liga' off;
							font-family: Manrope;
							font-size: 24px;
							font-style: normal;
							font-weight: 700;
							line-height: 30px; /* 125% */

							span {
								font-size: 20px;
								font-style: normal;
								font-weight: 700;
								line-height: 20px; /* 125% */
							}
						}
					}
				}

				.files {
					display: flex;
					flex-direction: column;
					gap: 16px;
					align-items: start;

					.title {
						color: var(--Text-Gray-900, #000433);
						font-feature-settings: 'liga' off;
						/* Heading/H6 */
						font-family: Manrope;
						font-size: 20px;
						font-style: normal;
						font-weight: 600;
						line-height: 30px; /* 150% */
					}

					.content {
						display: flex;
						flex-wrap: wrap;
						row-gap: 16px;
						gap: 12px;
						align-items: center;
					}
				}

				.map {
					width: 100%;
					height: 230px;
					border-radius: 8px;
					overflow: hidden; /* Это ключевой момент для того, чтобы радиус применился */
					position: relative; /* Это для того, чтобы работали все стили */

					.activeMarker {
						width: 36px;
					}
				}
			}

			.rightColumn {
				display: flex;
				flex-direction: column;
				gap: 32px;
				// width: 33%;
				width: 396px;

				.table {
					margin: 0;

					tbody {
						tr {
							border-style: none;

							th,
							td {
								border-style: none;
								padding: 0;
							}

							th {
								padding: 12px 12px 12px 0;
								color: var(--Text-Gray-900, #000433);
								font-family: Manrope;
								font-size: 16px;
								font-style: normal;
								font-weight: 700;
								line-height: 24px; /* 250% */
								width: 0%;
							}

							td {
								padding: 12px 0;
								color: var(--Text-Gray-900, #000433);
								font-family: Manrope;
								font-size: 16px;
								font-style: normal;
								font-weight: 400;
								line-height: 24px; /* 250% */
								width: 0%;
							}
						}
					}

					.types {
						width: 100%;
						display: flex;
						align-items: center;
						gap: 8px;
						flex-wrap: wrap;

						.type {
							padding: 4px 12px;
							background-color: #23ce6b;
							border-radius: 5px;
							width: fit-content;

							p {
								color: var(--Default-White, #fff);
								font-feature-settings: 'liga' off, 'clig' off;
								font-family: Manrope;
								font-size: 12px;
								font-style: normal;
								font-weight: 700;
								line-height: normal;
								text-wrap: nowrap;
							}
						}

						.typeTooltip {
							padding: 8px 16px;
							background: rgb(255, 255, 255);
							color: #000433;
							border: 1px solid var(--Text-Gray-300, #d9dbe1);
							border-radius: 12px;
							font-size: 14px;
							font-weight: 600;
							font-family: 'Manrope', sans-serif;
							line-height: 20px;
							opacity: 1;
							z-index: 1030;

							.react-tooltip-arrow {
								border-bottom: 1px solid var(--Text-Gray-300, #d9dbe1);
								border-right: 1px solid var(--Text-Gray-300, #d9dbe1);
							}
						}
					}

					.address {
						color: var(--Brand-Purple, #8c30f5);
						text-decoration: none;

						&:hover {
							text-decoration: underline;
							text-decoration-color: #8c30f5;
						}
					}

					.googleMapBtn {
						display: flex;
						width: fit-content;
						align-items: center;
						justify-content: center;
						border-radius: 50%;
						padding: 4px;
						border-color: #d9dbe1 !important;
						box-shadow: none !important;
						background: none !important;

						img {
							transform: rotate(180deg);
							width: 20px;
							height: 20px;
						}
					}

					.estimatedValue {
						span {
							font-size: 14px;
							font-style: normal;
							font-weight: 400;
							line-height: 14px;
						}
					}
				}

				.auctionInfo {
					.accordion-item {
						padding: 16px 16px 16px 20px;
						border-radius: 12px !important;
						position: relative;

						.accordion-button {
							padding: 0;
							background-color: white;
							box-shadow: none;

							color: var(--Text-Gray-900, #000433);
							font-feature-settings: 'liga' off;
							/* Subtitle / Subtitle 2 */
							font-family: Manrope;
							font-size: 18px;
							font-style: normal;
							font-weight: 500;
							line-height: 28px; /* 155.556% */

							&::after {
								filter: brightness(0) saturate(100%) invert(95%) sepia(6%)
									saturate(157%) hue-rotate(187deg) brightness(93%)
									contrast(91%);
							}
						}

						.accordion-header {
							border-radius: 12px !important;
						}

						.accordion-body {
							padding: 20px 0 22px 0;
							border-radius: 12px !important;

							display: flex;
							flex-direction: column;
							align-items: center;
							gap: 22px;

							.table {
								margin: 0;

								tbody {
									tr {
										border-style: none;

										th,
										td {
											border-style: none;
											padding: 0;
										}

										th {
											padding: 12px 12px 12px 0;
											color: var(--Text-Gray-900, #000433);
											font-family: Manrope;
											font-size: 16px;
											font-style: normal;
											font-weight: 700;
											line-height: 24px; /* 250% */
											width: 0%;
										}

										td {
											padding: 12px 0;
											color: var(--Text-Gray-900, #000433);
											font-family: Manrope;
											font-size: 16px;
											font-style: normal;
											font-weight: 400;
											line-height: 24px; /* 250% */
											width: 0%;
										}
									}
								}

								.terminated {
									color: #f5306b !important;
								}

								.auctionPrice {
									span {
										font-size: 14px;
										font-style: normal;
										font-weight: 400;
										line-height: 14px;
									}
								}

								.googleMapLink {
									color: var(--Brand-Purple, #8c30f5);
									text-decoration: none;

									&:hover {
										text-decoration: underline;
										text-decoration-color: #8c30f5;
									}
								}

								.gerlichtBtn {
									display: flex;
									width: fit-content;
									align-items: center;
									justify-content: center;
									border-radius: 50%;
									padding: 4px;
									border-color: #d9dbe1 !important;
									box-shadow: none !important;
									background: none !important;

									img {
										transform: rotate(180deg);
										width: 20px;
										height: 20px;
									}
								}
							}

							h5 {
								color: #f5306b !important;
								text-align: center;
							}
						}
					}
				}
			}
		}
	}
}

.mobile {
	.objectPage {
		padding-top: 24px;
		padding-bottom: 100px;
		display: flex;
		flex-direction: column;
		align-items: start;
		gap: 24px;

		.photoSlider {
			display: flex;
			gap: 12px;
			flex-direction: row;
			flex-wrap: nowrap;
			overflow-x: scroll;
			max-width: 100%;
			scrollbar-width: thin;
			position: relative;

			&::after {
				content: '';
			}

			.favouriteBtn {
				padding: 8px;
				border-radius: 50%;
				background-color: #ffffff;
				border-color: #969bab2f;
				box-shadow: none;
				position: absolute;
				top: 15px;
				left: 15px;

				img {
					filter: brightness(0) saturate(100%) invert(11%) sepia(25%)
						saturate(7002%) hue-rotate(225deg) brightness(58%) contrast(115%);
				}
			}
		}

		.picture {
			border-radius: 8px;
			height: 175px;
		}

		.noPhoto {
			height: 175px;
			object-fit: cover;
			border-radius: 8px;
		}

		.logo {
			position: absolute;
			top: 72px;
			left: 93.74px;
			filter: brightness(0) saturate(100%) invert(11%) sepia(25%)
				saturate(7002%) hue-rotate(225deg) brightness(58%) contrast(115%);

			span {
				color: var(--Text-Gray-900, #000433);
				font-feature-settings: 'liga' off, 'clig' off;
				font-family: Caros;
				font-size: 18px;
				font-style: normal;
				font-weight: 700;
				line-height: 20px; /* 111.111% */
			}
		}

		.nameMeta {
			position: relative;
			display: flex;
			flex-direction: column;
			align-items: start;
			gap: 8px;

			.name {
				color: var(--Text-Gray-900, #000433);
				font-feature-settings: 'liga' off;
				/* Heading/H5 */
				font-family: Manrope;
				font-size: 24px;
				font-style: normal;
				font-weight: 700;
				line-height: 32px; /* 133.333% */
			}

			.meta {
				display: flex;
				flex-direction: column;
				align-items: start;
				gap: 8px;

				.locationSiteId {
					display: flex;
					align-items: start;
					gap: 8px;

					.location {
						display: flex;
						align-items: center;
						gap: 4px;

						a {
							color: var(--Brand-Purple, #8c30f5);
							font-feature-settings: 'liga' off;
							font-family: Manrope;
							font-size: 14px;
							font-style: normal;
							font-weight: 400;
							line-height: 24px; /* 171.429% */
							text-decoration-line: underline;
						}
					}

					.siteId {
						color: var(--Brand-Purple, #8c30f5);
						font-feature-settings: 'liga' off;
						font-family: Manrope;
						font-size: 14px;
						font-style: normal;
						font-weight: 400;
						line-height: normal;
						text-decoration-line: underline;
					}
				}

				.dates {
					display: flex;
					flex-direction: column;
					align-items: start;
					gap: 8px;

					.createDate,
					.actualDate {
						display: flex;
						align-items: center;
						gap: 5px;

						p {
							&:first-child {
								color: var(--Text-Gray-700, #969bab);
								font-feature-settings: 'liga' off, 'clig' off;
								font-family: Manrope;
								font-size: 14px;
								font-style: normal;
								font-weight: 400;
								line-height: 18px; /* 128.571% */
							}

							&:last-child {
								color: var(--Text-Gray-700, #969bab);
								font-feature-settings: 'liga' off, 'clig' off;
								font-family: Manrope;
								font-size: 14px;
								font-style: normal;
								font-weight: 600;
								line-height: 18px; /* 128.571% */
							}
						}
					}
				}
			}

			/* .backBtn {
				position: absolute;
				border-radius: 50%;
				padding: 9px;
				border-color: #d9dbe1 !important;
				box-shadow: none !important;
				background: none !important;
				left: -54px;
				top: 0;
				display: none;
			} */
		}

		.price {
			display: flex;
			flex-direction: column;
			gap: 12px;
			align-items: start;

			.title {
				color: var(--Text-Gray-900, #000433);
				font-feature-settings: 'liga' off;
				/* Heading/H6 */
				font-family: Manrope;
				font-size: 20px;
				font-style: normal;
				font-weight: 600;
				line-height: 30px; /* 150% */
			}

			.content {
				color: var(--Text-Gray-900, #000433);
				font-feature-settings: 'liga' off;
				font-family: Manrope;
				font-size: 24px;
				font-style: normal;
				font-weight: 700;
				line-height: 30px; /* 125% */

				span {
					font-size: 20px;
					font-style: normal;
					font-weight: 700;
					line-height: 20px; /* 125% */
				}
			}
		}

		.table {
			margin: 0;

			tbody {
				tr {
					border-style: none;

					th,
					td {
						padding: 0;
						border-style: none;
					}

					th {
						padding: 12px 12px 12px 0;
						color: var(--Text-Gray-900, #000433);
						font-family: Manrope;
						font-size: 16px;
						font-style: normal;
						font-weight: 700;
						line-height: 24px; /* 250% */
						width: 0%;
					}

					td {
						padding: 12px 0;
						color: var(--Text-Gray-900, #000433);
						font-family: Manrope;
						font-size: 16px;
						font-style: normal;
						font-weight: 400;
						line-height: 24px; /* 250% */
						width: 30%;
					}
				}
			}

			.types {
				width: 100%;
				display: flex;
				align-items: center;
				gap: 8px;
				flex-wrap: wrap;

				.type {
					padding: 4px 12px;
					background-color: #23ce6b;
					border-radius: 5px;
					width: fit-content;

					p {
						color: var(--Default-White, #fff);
						font-feature-settings: 'liga' off, 'clig' off;
						font-family: Manrope;
						font-size: 12px;
						font-style: normal;
						font-weight: 700;
						line-height: normal;
						text-wrap: nowrap;
					}
				}

				.typeTooltip {
					padding: 8px 4px;
					background: rgb(255, 255, 255);
					color: #000433;
					border: 1px solid var(--Text-Gray-300, #d9dbe1);
					border-radius: 12px;
					font-size: 14px;
					font-weight: 600;
					font-family: 'Manrope', sans-serif;
					line-height: 20px;
					opacity: 1;
					z-index: 1030;

					.react-tooltip-arrow {
						border-bottom: 1px solid var(--Text-Gray-300, #d9dbe1);
						border-right: 1px solid var(--Text-Gray-300, #d9dbe1);
					}
				}
			}

			.address {
				color: var(--Brand-Purple, #8c30f5);
				text-decoration: none;

				&:hover {
					text-decoration: underline;
					text-decoration-color: #8c30f5;
				}
			}

			.googleMapBtn {
				display: flex;
				width: fit-content;
				align-items: center;
				justify-content: center;
				border-radius: 50%;
				padding: 4px;
				border-color: #d9dbe1 !important;
				box-shadow: none !important;
				background: none !important;

				img {
					transform: rotate(180deg);
					width: 20px;
					height: 20px;
				}
			}

			.estimatedValue {
				span {
					font-size: 14px;
					font-style: normal;
					font-weight: 400;
					line-height: 14px;
				}
			}
		}

		.description {
			max-width: 100%;
			display: flex;
			flex-direction: column;
			gap: 12px;
			align-items: start;

			.title {
				color: var(--Text-Gray-900, #000433);
				font-feature-settings: 'liga' off;
				/* Heading/H6 */
				font-family: Manrope;
				font-size: 20px;
				font-style: normal;
				font-weight: 600;
				line-height: 30px; /* 150% */
			}

			.content {
				color: var(--Text-Gray-900, #000433);
				font-feature-settings: 'liga' off;
				/* Body / Body 2 */
				font-family: Manrope;
				font-size: 14px;
				font-style: normal;
				font-weight: 400;
				line-height: 24px; /* 171.429% */
				text-wrap: pretty;
			}

			.moreBtn {
				color: var(--Brand-Purple, #8c30f5);
				font-feature-settings: 'liga' off;
				/* Lead / Lead 2 */
				font-family: Manrope;
				font-size: 14px;
				font-style: normal;
				font-weight: 500;
				line-height: 24px; /* 171.429% */
				text-decoration: none;
				width: fit-content;
				height: fit-content;
				background: none;
				border: none;
				box-shadow: none;
				padding: 0;
			}
		}

		.files {
			display: flex;
			flex-direction: column;
			gap: 16px;
			align-items: start;

			.title {
				color: var(--Text-Gray-900, #000433);
				font-feature-settings: 'liga' off;
				/* Heading/H6 */
				font-family: Manrope;
				font-size: 20px;
				font-style: normal;
				font-weight: 600;
				line-height: 30px; /* 150% */
			}

			.content {
				display: flex;
				flex-wrap: wrap;
				row-gap: 16px;
				gap: 12px;
				align-items: center;
			}
		}

		.map {
			width: 100%;
			height: 230px;
			border-radius: 8px;
			overflow: hidden; /* Это ключевой момент для того, чтобы радиус применился */
			position: relative; /* Это для того, чтобы работали все стили */

			.activeMarker {
				width: 36px;
			}
		}

		.auctionInfo {
			width: 100%;
			.accordion-item {
				padding: 16px 16px 16px 20px;
				border-radius: 12px !important;
				position: relative;

				.accordion-button {
					padding: 0;
					background-color: white;
					box-shadow: none;

					color: var(--Text-Gray-900, #000433);
					font-feature-settings: 'liga' off;
					/* Subtitle / Subtitle 2 */
					font-family: Manrope;
					font-size: 18px;
					font-style: normal;
					font-weight: 500;
					line-height: 28px; /* 155.556% */

					&::after {
						filter: brightness(0) saturate(100%) invert(95%) sepia(6%)
							saturate(157%) hue-rotate(187deg) brightness(93%) contrast(91%);
					}
				}

				.accordion-header {
					border-radius: 12px !important;
				}

				.accordion-body {
					padding: 20px 0 22px 0;
					border-radius: 12px !important;

					display: flex;
					flex-direction: column;
					align-items: center;
					gap: 22px;

					.table {
						margin: 0;

						tbody {
							tr {
								border-style: none;

								th,
								td {
									border-style: none;
									padding: 0;
								}

								th {
									padding: 12px 12px 12px 0;
									color: var(--Text-Gray-900, #000433);
									font-family: Manrope;
									font-size: 16px;
									font-style: normal;
									font-weight: 700;
									line-height: 24px; /* 250% */
									width: 0%;
								}

								td {
									padding: 12px 0;
									color: var(--Text-Gray-900, #000433);
									font-family: Manrope;
									font-size: 16px;
									font-style: normal;
									font-weight: 400;
									line-height: 24px; /* 250% */
									width: 0%;
								}
							}
						}

						.terminated {
							color: #f5306b !important;
						}

						.auctionPrice {
							span {
								font-size: 14px;
								font-style: normal;
								font-weight: 400;
								line-height: 14px;
							}
						}

						.googleMapLink {
							color: var(--Brand-Purple, #8c30f5);
							text-decoration: none;

							&:hover {
								text-decoration: underline;
								text-decoration-color: #8c30f5;
							}
						}

						.gerlichtBtn {
							display: flex;
							width: fit-content;
							align-items: center;
							justify-content: center;
							border-radius: 50%;
							padding: 4px;
							border-color: #d9dbe1 !important;
							box-shadow: none !important;
							background: none !important;

							img {
								transform: rotate(180deg);
								width: 20px;
								height: 20px;
							}
						}
					}

					h5 {
						color: #f5306b !important;
						text-align: center;
					}
				}
			}
		}
	}
}
