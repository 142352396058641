// .popover {
// 	.objectCard {
// 		padding: 20px;
// 		display: flex;
// 		align-items: start;
// 		gap: 30px;
// 		width: 768px;
// 		// height: 238px;

// 		border-radius: 12px;
// 		background: var(--Default-White, #fff);
// 		/* Grey Shadow */
// 		box-shadow: 0px 30px 40px 0px rgba(212, 217, 232, 0.2);

// 		&.terminated {
// 			position: relative;
// 			opacity: 0.5;
// 			/* &::before {
// 			position: absolute;
// 			content: '';
// 			width: 100%;
// 			height: 100%;
// 			top: 0;
// 			left: 0;
// 			background-color: rgba(255, 255, 255, 0.6);
// 			z-index: 1;
// 			pointer-events: none;
// 		} */

// 			.extraInfo {
// 				.datesFiles {
// 					.createDate {
// 						p {
// 							z-index: 1;
// 						}
// 					}

// 					.terminDate {
// 						p {
// 							color: #f5306b !important;
// 							z-index: 1;
// 						}
// 					}
// 				}
// 			}
// 		}

// 		.photos {
// 			width: 305px;
// 			height: 100%;
// 			position: relative;
// 			display: flex;
// 			flex-direction: column;
// 			align-items: center;
// 			gap: 12px;

// 			&:hover {
// 				.slick-slider {
// 					.slick-prev,
// 					.slick-next {
// 						display: block !important;
// 					}
// 				}
// 			}

// 			.noPhoto {
// 				width: 305px;
// 				height: 204px;
// 				border-radius: 12px;
// 			}

// 			.logo {
// 				position: absolute;
// 				top: 86.5px;
// 				left: 93px;
// 				filter: brightness(0) saturate(100%) invert(11%) sepia(25%)
// 					saturate(7002%) hue-rotate(225deg) brightness(58%) contrast(115%);

// 				span {
// 					color: var(--Text-Gray-900, #000433);
// 					font-feature-settings: 'liga' off, 'clig' off;
// 					font-family: Caros;
// 					font-size: 18px;
// 					font-style: normal;
// 					font-weight: 700;
// 					line-height: 20px; /* 111.111% */
// 				}
// 			}

// 			.slick-slider {
// 				z-index: 0;
// 				margin: 0;
// 				width: 305px;
// 				height: 204px;
// 				position: relative;
// 				// border: 2px solid #8C30F5;
// 				// border-radius: 12px;

// 				.slick-prev,
// 				.slick-next {
// 					position: absolute;
// 					background-image: url('../../assets/icons/chevron-right.svg');
// 					background-size: 32px 32px;
// 					background-position: center;
// 					background-repeat: no-repeat;
// 					padding: 16px;
// 					z-index: 1;
// 					display: none !important;

// 					&::before {
// 						display: none;
// 					}
// 				}

// 				.slick-prev {
// 					left: 12px;
// 					top: 80px;
// 					transform: rotate(180deg);
// 				}

// 				.slick-next {
// 					right: 12px;
// 				}

// 				.slick-list {
// 					height: 100%;
// 					border-radius: 12px !important;

// 					.slick-track {
// 						height: 100%;
// 						.slick-slide {
// 							height: 100%;
// 							div {
// 								height: 100%;
// 								width: 100%;
// 							}
// 							img {
// 								object-fit: cover;
// 								height: 100%;
// 								width: 100%;
// 							}
// 						}
// 					}
// 				}
// 			}

// 			.types {
// 				position: absolute;
// 				top: 15px;
// 				left: 15px;
// 				right: 15px;
// 				display: flex;
// 				align-items: center;
// 				gap: 8px;
// 				width: calc(100% - 87px);
// 				overflow-y: scroll;
// 				scrollbar-width: none;
// 				cursor: grab;

// 				&.grabbing {
// 					cursor: grabbing;
// 				}
// 			}

// 			.type {
// 				padding: 4px 12px;
// 				background-color: #23ce6b;
// 				border-radius: 5px;
// 				width: fit-content;

// 				p {
// 					color: var(--Default-White, #fff);
// 					font-feature-settings: 'liga' off, 'clig' off;
// 					font-family: Manrope;
// 					font-size: 12px;
// 					font-style: normal;
// 					font-weight: 700;
// 					line-height: normal;
// 					text-wrap: nowrap;
// 					pointer-events: none;
// 				}
// 			}

// 			.typeTooltip {
// 				background: rgb(255, 255, 255);
// 				color: #000433;
// 				border: 1px solid var(--Text-Gray-300, #d9dbe1);
// 				border-radius: 12px;
// 				font-size: 14px;
// 				font-weight: 600;
// 				font-family: 'Manrope', sans-serif;
// 				line-height: 20px;
// 				opacity: 1;
// 				z-index: 1;

// 				.react-tooltip-arrow {
// 					border-bottom: 1px solid var(--Text-Gray-300, #d9dbe1);
// 					border-right: 1px solid var(--Text-Gray-300, #d9dbe1);
// 				}
// 			}

// 			.favouriteBtn {
// 				padding: 8px;
// 				border-radius: 50%;
// 				background-color: #ffffff;
// 				border-color: #969bab2f;
// 				box-shadow: none;
// 				position: absolute;
// 				top: 15px;
// 				right: 15px;

// 				img {
// 					filter: brightness(0) saturate(100%) invert(11%) sepia(25%)
// 						saturate(7002%) hue-rotate(225deg) brightness(58%) contrast(115%);
// 				}
// 			}

// 			.metaInfo {
// 				display: flex;
// 				width: 100%;
// 				align-items: center;
// 				justify-content: space-between;
// 				padding: 0 8px;

// 				.id {
// 					display: flex;
// 					align-items: start;
// 					gap: 0;

// 					h4 {
// 						color: var(--Text-Gray-900, #000433);
// 						font-feature-settings: 'liga' off;
// 						/* Heading/H6 */
// 						font-family: Manrope;
// 						font-size: 20px;
// 						font-style: normal;
// 						font-weight: 600;
// 						line-height: 30px; /* 150% */
// 						text-wrap: nowrap;
// 					}
// 				}

// 				.price {
// 					color: var(--Text-Gray-900, #000433);
// 					font-feature-settings: 'liga' off;
// 					/* Heading/H6 */
// 					font-family: Manrope;
// 					font-size: 20px;
// 					font-style: normal;
// 					font-weight: 600;
// 					line-height: 30px; /* 150% */
// 					text-wrap: nowrap;
// 				}
// 			}
// 		}

// 		.info {
// 			display: flex;
// 			flex-direction: column;
// 			align-items: start;
// 			gap: 12px;
// 			height: 100%;

// 			.mainInfoEnterBtn {
// 				display: flex;
// 				align-items: start;
// 				gap: 16px;
// 				justify-content: space-between;

// 				.nameLocation {
// 					display: flex;
// 					flex-direction: column;
// 					align-items: start;
// 					gap: 0;

// 					.name {
// 						color: var(--Text-Gray-900, #000433);
// 						font-feature-settings: 'liga' off;
// 						/* Heading/H5 */
// 						font-family: Manrope;
// 						font-size: 24px;
// 						font-style: normal;
// 						font-weight: 700;
// 						line-height: 32px; /* 133.333% */
// 					}

// 					.location {
// 						display: flex;
// 						align-items: center;
// 						gap: 4px;

// 						p {
// 							color: var(--Brand-Purple, #8c30f5);
// 							font-feature-settings: 'liga' off;
// 							/* Lead / Lead 2 */
// 							font-family: Manrope;
// 							font-size: 14px;
// 							font-style: normal;
// 							font-weight: 500;
// 							line-height: 24px; /* 171.429% */
// 						}
// 					}
// 				}

// 				.enterBtn {
// 					border-radius: 50%;
// 					padding: 9px;
// 					border-color: #d9dbe1 !important;
// 					box-shadow: none !important;
// 					background: none !important;
// 					transform: rotate(180deg);
// 				}
// 			}

// 			.description {
// 				color: var(--Text-Gray-900, #000433);
// 				font-feature-settings: 'liga' off;
// 				/* Body / Body 2 */
// 				font-family: Manrope;
// 				font-size: 14px;
// 				font-style: normal;
// 				font-weight: 400;
// 				line-height: 24px; /* 171.429% */
// 				text-wrap: pretty;
// 			}

// 			.extraInfo {
// 				display: flex;
// 				align-items: center;
// 				justify-content: space-between;
// 				width: 100%;
// 				gap: 16px;

// 				.datesFiles {
// 					display: flex;
// 					align-items: center;
// 					gap: 16px;
// 					width: 100%;
// 					justify-content: space-between;

// 					.createDate,
// 					.terminDate,
// 					.actualDate {
// 						display: flex;
// 						flex-direction: column;
// 						gap: 0;
// 						align-items: start;

// 						p {
// 							color: var(--Text-Gray-700, #969bab);
// 							font-feature-settings: 'liga' off, 'clig' off;
// 							font-family: Manrope;
// 							font-size: 12px;
// 							font-style: normal;
// 							font-weight: 400;
// 							line-height: 18px; /* 150% */
// 						}
// 					}

// 					.files {
// 						background: none;
// 						box-shadow: none;
// 						border: none;
// 						position: relative;
// 						padding: 0;
// 						margin: 0;

// 						.filesCount {
// 							position: absolute;
// 							padding: 0px 6px;
// 							background-color: #8c30f5;
// 							border-radius: 50%;
// 							top: -3px;
// 							left: -4px;

// 							color: var(--Default-White, #fff);
// 							font-family: Manrope;
// 							font-size: 11px;
// 							font-style: normal;
// 							font-weight: 600;
// 							line-height: normal;
// 						}
// 					}
// 				}
// 			}
// 		}
// 	}
// }

// .objectCard {
// 	padding: 20px;
// 	display: flex;
// 	align-items: start;
// 	gap: 30px;
// 	width: 768px;
// 	// height: 238px;

// 	border-radius: 12px;
// 	background: var(--Default-White, #fff);
// 	/* Grey Shadow */
// 	box-shadow: 0px 30px 40px 0px rgba(212, 217, 232, 0.2);

// 	&.terminated {
// 		position: relative;
// 		opacity: 0.5;
// 		/* &::before {
// 		position: absolute;
// 		content: '';
// 		width: 100%;
// 		height: 100%;
// 		top: 0;
// 		left: 0;
// 		background-color: rgba(255, 255, 255, 0.6);
// 		z-index: 1;
// 		pointer-events: none;
// 	} */

// 		.extraInfo {
// 			.datesFiles {
// 				.createDate {
// 					p {
// 						z-index: 1;
// 					}
// 				}

// 				.terminDate {
// 					p {
// 						color: #f5306b !important;
// 						z-index: 1;
// 					}
// 				}
// 			}
// 		}
// 	}

// 	.photos {
// 		width: 305px;
// 		height: 100%;
// 		position: relative;
// 		display: flex;
// 		flex-direction: column;
// 		align-items: center;
// 		gap: 12px;

// 		&:hover {
// 			.slick-slider {
// 				.slick-prev,
// 				.slick-next {
// 					display: block !important;
// 				}
// 			}
// 		}

// 		.noPhoto {
// 			width: 305px;
// 			height: 204px;
// 			border-radius: 12px;
// 		}

// 		.logo {
// 			position: absolute;
// 			top: 86.5px;
// 			left: 93px;
// 			filter: brightness(0) saturate(100%) invert(11%) sepia(25%)
// 				saturate(7002%) hue-rotate(225deg) brightness(58%) contrast(115%);

// 			span {
// 				color: var(--Text-Gray-900, #000433);
// 				font-feature-settings: 'liga' off, 'clig' off;
// 				font-family: Caros;
// 				font-size: 18px;
// 				font-style: normal;
// 				font-weight: 700;
// 				line-height: 20px; /* 111.111% */
// 			}
// 		}

// 		.slick-slider {
// 			z-index: 0;
// 			margin: 0;
// 			width: 305px;
// 			height: 204px;
// 			position: relative;
// 			// border: 2px solid #8C30F5;
// 			// border-radius: 12px;

// 			.slick-prev,
// 			.slick-next {
// 				position: absolute;
// 				background-image: url('../../assets/icons/chevron-right.svg');
// 				background-size: 32px 32px;
// 				background-position: center;
// 				background-repeat: no-repeat;
// 				padding: 16px;
// 				z-index: 1;
// 				display: none !important;

// 				&::before {
// 					display: none;
// 				}
// 			}

// 			.slick-prev {
// 				left: 12px;
// 				top: 80px;
// 				transform: rotate(180deg);
// 			}

// 			.slick-next {
// 				right: 12px;
// 			}

// 			.slick-list {
// 				height: 100%;
// 				border-radius: 12px !important;

// 				.slick-track {
// 					height: 100%;
// 					.slick-slide {
// 						height: 100%;
// 						div {
// 							height: 100%;
// 							width: 100%;
// 						}
// 						img {
// 							object-fit: cover;
// 							height: 100%;
// 							width: 100%;
// 						}
// 					}
// 				}
// 			}
// 		}

// 		.types {
// 			position: absolute;
// 			top: 15px;
// 			left: 15px;
// 			right: 15px;
// 			display: flex;
// 			align-items: center;
// 			gap: 8px;
// 			width: calc(100% - 87px);
// 			overflow-y: scroll;
// 			scrollbar-width: none;
// 			cursor: grab;

// 			&.grabbing {
// 				cursor: grabbing;
// 			}
// 		}

// 		.type {
// 			padding: 4px 12px;
// 			background-color: #23ce6b;
// 			border-radius: 5px;
// 			width: fit-content;

// 			p {
// 				color: var(--Default-White, #fff);
// 				font-feature-settings: 'liga' off, 'clig' off;
// 				font-family: Manrope;
// 				font-size: 12px;
// 				font-style: normal;
// 				font-weight: 700;
// 				line-height: normal;
// 				text-wrap: nowrap;
// 				pointer-events: none;
// 			}
// 		}

// 		.typeTooltip {
// 			background: rgb(255, 255, 255);
// 			color: #000433;
// 			border: 1px solid var(--Text-Gray-300, #d9dbe1);
// 			border-radius: 12px;
// 			font-size: 14px;
// 			font-weight: 600;
// 			font-family: 'Manrope', sans-serif;
// 			line-height: 20px;
// 			opacity: 1;
// 			z-index: 1;

// 			.react-tooltip-arrow {
// 				border-bottom: 1px solid var(--Text-Gray-300, #d9dbe1);
// 				border-right: 1px solid var(--Text-Gray-300, #d9dbe1);
// 			}
// 		}

// 		.favouriteBtn {
// 			padding: 8px;
// 			border-radius: 50%;
// 			background-color: #ffffff;
// 			border-color: #969bab2f;
// 			box-shadow: none;
// 			position: absolute;
// 			top: 15px;
// 			right: 15px;

// 			img {
// 				filter: brightness(0) saturate(100%) invert(11%) sepia(25%)
// 					saturate(7002%) hue-rotate(225deg) brightness(58%) contrast(115%);
// 			}
// 		}

// 		.metaInfo {
// 			display: flex;
// 			width: 100%;
// 			align-items: center;
// 			justify-content: space-between;
// 			padding: 0 8px;

// 			.id {
// 				display: flex;
// 				align-items: start;
// 				gap: 0;

// 				h4 {
// 					color: var(--Text-Gray-900, #000433);
// 					font-feature-settings: 'liga' off;
// 					/* Heading/H6 */
// 					font-family: Manrope;
// 					font-size: 20px;
// 					font-style: normal;
// 					font-weight: 600;
// 					line-height: 30px; /* 150% */
// 					text-wrap: nowrap;
// 				}
// 			}

// 			.price {
// 				color: var(--Text-Gray-900, #000433);
// 				font-feature-settings: 'liga' off;
// 				/* Heading/H6 */
// 				font-family: Manrope;
// 				font-size: 20px;
// 				font-style: normal;
// 				font-weight: 600;
// 				line-height: 30px; /* 150% */
// 				text-wrap: nowrap;
// 			}
// 		}
// 	}

// 	.info {
// 		display: flex;
// 		flex-direction: column;
// 		align-items: start;
// 		gap: 12px;
// 		height: 100%;

// 		.mainInfoEnterBtn {
// 			display: flex;
// 			align-items: start;
// 			gap: 16px;
// 			justify-content: space-between;

// 			.nameLocation {
// 				display: flex;
// 				flex-direction: column;
// 				align-items: start;
// 				gap: 0;

// 				.name {
// 					color: var(--Text-Gray-900, #000433);
// 					font-feature-settings: 'liga' off;
// 					/* Heading/H5 */
// 					font-family: Manrope;
// 					font-size: 24px;
// 					font-style: normal;
// 					font-weight: 700;
// 					line-height: 32px; /* 133.333% */
// 				}

// 				.location {
// 					display: flex;
// 					align-items: center;
// 					gap: 4px;

// 					p {
// 						color: var(--Brand-Purple, #8c30f5);
// 						font-feature-settings: 'liga' off;
// 						/* Lead / Lead 2 */
// 						font-family: Manrope;
// 						font-size: 14px;
// 						font-style: normal;
// 						font-weight: 500;
// 						line-height: 24px; /* 171.429% */
// 					}
// 				}
// 			}

// 			.enterBtn {
// 				border-radius: 50%;
// 				padding: 9px;
// 				border-color: #d9dbe1 !important;
// 				box-shadow: none !important;
// 				background: none !important;
// 				transform: rotate(180deg);
// 			}
// 		}

// 		.description {
// 			color: var(--Text-Gray-900, #000433);
// 			font-feature-settings: 'liga' off;
// 			/* Body / Body 2 */
// 			font-family: Manrope;
// 			font-size: 14px;
// 			font-style: normal;
// 			font-weight: 400;
// 			line-height: 24px; /* 171.429% */
// 			text-wrap: pretty;
// 		}

// 		.extraInfo {
// 			display: flex;
// 			align-items: center;
// 			justify-content: space-between;
// 			width: 100%;
// 			gap: 16px;

// 			.datesFiles {
// 				display: flex;
// 				align-items: center;
// 				gap: 16px;
// 				width: 100%;
// 				justify-content: space-between;

// 				.createDate,
// 				.terminDate,
// 				.actualDate {
// 					display: flex;
// 					flex-direction: column;
// 					gap: 0;
// 					align-items: start;

// 					p {
// 						color: var(--Text-Gray-700, #969bab);
// 						font-feature-settings: 'liga' off, 'clig' off;
// 						font-family: Manrope;
// 						font-size: 12px;
// 						font-style: normal;
// 						font-weight: 400;
// 						line-height: 18px; /* 150% */
// 					}
// 				}

// 				.files {
// 					background: none;
// 					box-shadow: none;
// 					border: none;
// 					position: relative;
// 					padding: 0;
// 					margin: 0;

// 					.filesCount {
// 						position: absolute;
// 						padding: 0px 6px;
// 						background-color: #8c30f5;
// 						border-radius: 50%;
// 						top: -3px;
// 						left: -4px;

// 						color: var(--Default-White, #fff);
// 						font-family: Manrope;
// 						font-size: 11px;
// 						font-style: normal;
// 						font-weight: 600;
// 						line-height: normal;
// 					}
// 				}
// 			}
// 		}
// 	}
// }

.desktop {
	.mapPage {
		position: relative;
		scrollbar-width: none;

		.mainFilters {
			z-index: 10;
			left: 340px;
			right: 400px;
			max-width: calc(100% - 740px);
			scrollbar-width: none;
		}

		.landSelector,
		.citySelector,
		.typeSelector {
			width: 100%;
		}

		.landSelector__control,
		.typeSelector__control,
		.citySelector__control,
		.terminDateSelector__control {
			border-radius: 8px;
			padding: 8px 8px 8px 16px;
			display: flex;
			gap: 32px;
			align-items: center;
			border: 1px solid #d9dbe1;
			box-shadow: none;

			&:focus,
			&:active,
			&:hover {
				border: 1px solid #8c30f5;
				box-shadow: none;
			}

			.landSelector__value-container,
			.typeSelector__value-container,
			.citySelector__value-container,
			.terminDateSelector__value-container {
				padding: 0;
				overflow-x: auto;
				flex-wrap: nowrap;
				scrollbar-width: thin; /* Hide scrollbar in Firefox */
				scrollbar-color: #d9dbe1 transparent;
				scroll-behavior: smooth;

				display: flex;
				align-items: center;

				.prefix {
					color: var(--Text-Gray-800, #474a57);
					font-family: Manrope;
					font-size: 14px;
					font-style: normal;
					font-weight: 500;
					line-height: 24px; /* 171.429% */
				}

				.landSelector__placeholder,
				.typeSelector__placeholder,
				.citySelector__placeholder,
				.terminDateSelector__placeholder {
					color: var(--Text-Gray-900, #000433);
					font-family: Manrope;
					font-size: 14px;
					font-style: normal;
					font-weight: 500;
					line-height: 24px; /* 171.429% */
				}

				.landSelector__multi-value,
				.typeSelector__multi-value,
				.citySelector__multi-value,
				.terminDateSelector__multi-value {
					min-width: fit-content;
				}
			}
		}

		.landSelector__menu,
		.typeSelector__menu,
		.citySelector__menu,
		.terminDateSelector__menu {
			margin: 12px 0;

			.landSelector__menu-list,
			.typeSelector__menu-list,
			.citySelector__menu-list,
			.terminDateSelector__menu-list {
				padding: 14px 0 !important;

				.landSelector__option,
				.typeSelector__option,
				.citySelector__option,
				.sortBySelector__option,
				.terminDateSelector__option {
					color: var(--Text-Gray-900, #000433);
					font-feature-settings: 'liga' off;
					/* Body / Body 2 */
					font-family: Manrope;
					font-size: 14px;
					font-style: normal;
					font-weight: 400;
					line-height: 24px; /* 171.429% */
					text-wrap: pretty;

					&:hover,
					&:active,
					&:focus {
						background-color: #f1e4ff;
						color: var(--Brand-Purple, #8c30f5);
						font-feature-settings: 'liga' off;
						/* Body / Body 2 */
						font-family: Manrope;
						font-size: 14px;
						font-style: normal;
						font-weight: 400;
						line-height: 24px; /* 171.429% */
					}

					.selectMultiOption {
						display: flex;
						align-items: center;
						justify-content: space-between;
						gap: 4px;

						.optionCheckbox {
							width: 24px;
							height: 24px;
							border: 2px solid #d9dbe1;
							border-radius: 4px;
							flex-shrink: 0;

							&.checked {
								background-color: #8c30f5;
								border-color: #8c30f5;
								// background-image: url('../../assets/icons/Line.svg');
								box-shadow: none;
								position: relative;
								background-image: none;

								&::before {
									content: '';
									position: absolute;
									top: 0;
									left: 0;
									width: 100%;
									height: 100%;
									background-image: url('../../assets/icons/Line.svg');
									background-size: cover;
									background-repeat: no-repeat;
									filter: brightness(0) saturate(100%) invert(100%) sepia(0%)
										saturate(0%) hue-rotate(182deg) brightness(106%)
										contrast(101%);
								}
							}
						}
					}
				}

				.landSelector__option--is-focused,
				.typeSelector__option--is-focused,
				.citySelector__option--is-focused,
				.terminDateSelector__option--is-focused {
					background-color: #f1e4ff !important;
					color: var(--Brand-Purple, #8c30f5) !important;

					.optionCheckbox {
						&:not(.checked) {
							border-color: white !important;
						}
					}
				}

				.landSelector__option--is-selected,
				.typeSelector__option--is-selected,
				.citySelector__option--is-selected,
				.terminDateSelector__option--is-selected {
					background-color: white;
					// color: var(--Brand-Purple, #8c30f5);
				}
			}
		}

		.landSelector__indicator-separator,
		.typeSelector__indicator-separator,
		.citySelector__indicator-separator,
		.terminDateSelector__indicator-separator {
			display: none;
		}

		//__control--is-focused

		.landSelector__dropdown-indicator,
		.typeSelector__dropdown-indicator,
		.citySelector__dropdown-indicator,
		.terminDateSelector__dropdown-indicator {
			border: 1px solid #d9dbe1;
			border-radius: 50%;
			padding: 4px;
			margin: 0;
		}

		.filtersMap {
			position: absolute;
			display: flex;
			flex-direction: column;
			gap: 24px;
			align-items: start;
			z-index: 1;
			top: 16px;
			max-width: calc(100% - 64px);
			left: 32px;
			right: 32px;
			margin: 0 auto;
			pointer-events: none;
			scrollbar-width: none;

			.backBtn {
				padding: 8px 24px;
				display: flex;
				align-items: center;
				gap: 20px;
				border-radius: 8px;
				border: 1px solid var(--Default-White, #fff);
				background: var(--Default-White, #fff);
				pointer-events: all;

				&:focus {
					box-shadow: none;
				}

				p {
					color: var(--Text-Gray-900, #000433);
					font-family: Manrope;
					font-size: 14px;
					font-style: normal;
					font-weight: 500;
					line-height: 24px; /* 171.429% */
				}
			}

			.filtersAccordion {
				border-radius: 12px;
				width: 310px;
				pointer-events: all;

				.accordion-item {
					border-radius: 12px;
					background: rgba(255, 255, 255, 0.4);
					backdrop-filter: blur(18px);

					.accordion-header {
						border-radius: 12px;

						.accordion-button {
							padding: 20px 24px;
							box-shadow: none;
							background: none;

							color: var(--Text-Gray-900, #000433);
							font-family: Manrope;
							font-size: 18px;
							font-style: normal;
							font-weight: 500;
							line-height: 24px; /* 133.333% */

							&::after {
								filter: brightness(0) saturate(100%) invert(71%) sepia(2%)
									saturate(2405%) hue-rotate(189deg) brightness(86%)
									contrast(95%);
							}
						}
					}

					.accordion-body {
						padding: 0;
						border-radius: 12px;

						.filters {
							background: inherit;
							h3 {
								display: none;
							}
						}
					}
				}
			}
		}

		.sidebar {
			position: absolute;
			right: 0;
			top: 0;
			height: 100%;
			overflow: hidden;
			scrollbar-width: none;

			.ps-sidebar-container {
				display: flex;
				align-items: center;
				gap: 0;
				background-color: transparent !important;
				overflow: hidden;
				scrollbar-width: 0;
			}

			.toggleBtnBlock {
				height: 100%;
				width: fit-content;
				background-color: transparent !important;

				.toggleCollapseBtn {
					position: relative;
					top: 50%;
					padding: 10px 2px 10px 4px;
					margin: 0;
					left: 0;
					display: flex;
					align-items: center;
					justify-content: center;
					border-top-left-radius: 8px;
					border-bottom-left-radius: 8px;

					background-color: #8c30f5 !important;
					border: 1px solid #8c30f5;

					&:hover {
						background-color: #923bf4 !important;
						border-color: #923bf4 !important;
					}

					&:active {
						background-color: #8830ec !important;
						border-color: #8830ec !important;
					}

					&:disabled {
						background-color: #c597fa !important;
						border-color: #c597fa !important;
					}

					img {
						transform: rotate(180deg);

						&.active {
							transform: rotate(0deg);
						}
					}
				}
			}

			.cardsList {
				align-self: start;
				min-height: 100%;
				max-height: 100%;
				background: rgba(255, 255, 255, 0.4);
				backdrop-filter: blur(18px);
				padding: 16px;
				overflow-y: auto;
				display: flex;
				flex-direction: column;
				align-items: center;
				gap: 16px;
				width: 100%;
			}
		}

		/* .collapseBlock {
			position: absolute;
			width: 40px;
			height: 100%;
			top: 0;
			right: 0;
			background-color: transparent;
			z-index: 10000000;
			overflow-y: auto;
			transition: width 0.3s ease; // Плавное раскрытие блока
		
			&.open {
				width: 402px; // Ширина открытого блока
				z-index: 10000000;
			}
		
			.toggleCollapseBtn {
				position: relative;
				top: 50%;
				width: 100px;
				height: 100px;
				background-color: yellow;
				border: yellow;
				color: yellow;
				border-radius: 4px;
				cursor: pointer;
				box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
				transition: right 0.3s ease;
				z-index: 1;
		
				// Когда блок открыт, сдвигаем кнопку влево
				.collapseBlock.open & {
					right: calc(100% + 10px); // Отступ кнопки от блока
					z-index: 10000000;
				}
			}

			.cardsList {
				position: relative;
				background: wheat;
				width: 100%;
				height: 100%;
				transition: right 0.3s ease;
			}
		} */

		.mapComponent {
			width: 100vw;
			height: calc(100vh - 160px);
			scrollbar-width: none;

			.cluster {
				&.clusterSm,
				&.clusterMd,
				&.clusterLg {
					color: var(--Default-White, #fff);
					text-align: center;
					font-family: Manrope;
					font-size: 24px;
					font-style: normal;
					font-weight: 600;
					line-height: 24px; /* 100% */

					border: 2px solid white;
					background-color: #474a57;

					/* &.active {
						background-color: #8c30f5;
					} */
				}

				&.clusterSm {
					padding: 9px 14px;
					border-radius: 50%;
				}

				&.clusterMd {
					padding: 18.7px 16px;
					border-radius: 50%;
				}

				&.clusterLg {
					padding: 28.7px 18px;
					border-radius: 50%;
				}

				&[data-active='1'] {
					background-color: #8c30f5;
				}
			}

			/* .marker {
					z-index: -10000;
					position: relative;
	
					img {
						z-index: -100;
						position: relative;
					}
				} */
		}

		.cardTooltip {
			padding: 0;
			background: rgb(255, 255, 255);
			// color: #000433;
			// border: 1px solid var(--Text-Gray-300, #d9dbe1);
			border-radius: 12px;
			// font-size: 14px;
			// font-weight: 600;
			// font-family: 'Manrope', sans-serif;
			// line-height: 20px;
			opacity: 1;
			z-index: 1;

			.react-tooltip-arrow {
				border-bottom: 1px solid var(--Text-Gray-300, #d9dbe1);
				border-right: 1px solid var(--Text-Gray-300, #d9dbe1);
			}
			pointer-events: all;
		}
	}
}

.tablet {
	.mapPage {
		position: relative;
		scrollbar-width: none;

		.mainFilters {
			z-index: 10;
			scrollbar-width: none;
		}

		.landSelector,
		.citySelector,
		.typeSelector {
			width: 100%;
		}

		.landSelector__control,
		.typeSelector__control,
		.citySelector__control {
			border-radius: 8px;
			padding: 8px 8px 8px 16px;
			display: flex;
			gap: 32px;
			align-items: center;
			border: 1px solid #d9dbe1;
			box-shadow: none;

			&:focus,
			&:active,
			&:hover {
				border: 1px solid #8c30f5;
				box-shadow: none;
			}

			.landSelector__value-container,
			.typeSelector__value-container,
			.citySelector__value-container {
				padding: 0;
				overflow-x: auto;
				flex-wrap: nowrap;
				scrollbar-width: thin; /* Hide scrollbar in Firefox */
				scrollbar-color: #d9dbe1 transparent;
				scroll-behavior: smooth;

				display: flex;
				align-items: center;

				.prefix {
					color: var(--Text-Gray-800, #474a57);
					font-family: Manrope;
					font-size: 14px;
					font-style: normal;
					font-weight: 500;
					line-height: 24px; /* 171.429% */
				}

				.landSelector__placeholder,
				.typeSelector__placeholder,
				.citySelector__placeholder {
					color: var(--Text-Gray-900, #000433);
					font-family: Manrope;
					font-size: 14px;
					font-style: normal;
					font-weight: 500;
					line-height: 24px; /* 171.429% */
				}

				.landSelector__multi-value,
				.typeSelector__multi-value,
				.citySelector__multi-value {
					min-width: fit-content;
				}
			}
		}

		.landSelector__menu,
		.typeSelector__menu,
		.citySelector__menu {
			margin: 12px 0;

			.landSelector__menu-list,
			.typeSelector__menu-list,
			.citySelector__menu-list {
				padding: 14px 0 !important;

				.landSelector__option,
				.typeSelector__option,
				.citySelector__option {
					color: var(--Text-Gray-900, #000433);
					font-feature-settings: 'liga' off;
					/* Body / Body 2 */
					font-family: Manrope;
					font-size: 14px;
					font-style: normal;
					font-weight: 400;
					line-height: 24px; /* 171.429% */
					text-wrap: pretty;

					&:hover,
					&:active,
					&:focus {
						background-color: #f1e4ff;
						color: var(--Brand-Purple, #8c30f5);
						font-feature-settings: 'liga' off;
						/* Body / Body 2 */
						font-family: Manrope;
						font-size: 14px;
						font-style: normal;
						font-weight: 400;
						line-height: 24px; /* 171.429% */
					}

					.selectMultiOption {
						display: flex;
						align-items: center;
						justify-content: space-between;
						gap: 4px;

						.optionCheckbox {
							width: 24px;
							height: 24px;
							border: 2px solid #d9dbe1;
							border-radius: 4px;
							flex-shrink: 0;

							&.checked {
								background-color: #8c30f5;
								border-color: #8c30f5;
								// background-image: url('../../assets/icons/Line.svg');
								box-shadow: none;
								position: relative;
								background-image: none;

								&::before {
									content: '';
									position: absolute;
									top: 0;
									left: 0;
									width: 100%;
									height: 100%;
									background-image: url('../../assets/icons/Line.svg');
									background-size: cover;
									background-repeat: no-repeat;
									filter: brightness(0) saturate(100%) invert(100%) sepia(0%)
										saturate(0%) hue-rotate(182deg) brightness(106%)
										contrast(101%);
								}
							}
						}
					}
				}

				.landSelector__option--is-focused,
				.typeSelector__option--is-focused,
				.citySelector__option--is-focused {
					background-color: #f1e4ff !important;
					color: var(--Brand-Purple, #8c30f5) !important;

					.optionCheckbox {
						&:not(.checked) {
							border-color: white !important;
						}
					}
				}

				.landSelector__option--is-selected,
				.typeSelector__option--is-selected,
				.citySelector__option--is-selected {
					background-color: white;
					// color: var(--Brand-Purple, #8c30f5);
				}
			}
		}

		.landSelector__indicator-separator,
		.typeSelector__indicator-separator,
		.citySelector__indicator-separator {
			display: none;
		}

		//__control--is-focused

		.landSelector__dropdown-indicator,
		.typeSelector__dropdown-indicator,
		.citySelector__dropdown-indicator {
			border: 1px solid #d9dbe1;
			border-radius: 50%;
			padding: 4px;
			margin: 0;
		}

		.filtersMap {
			position: absolute;
			z-index: 1;
			top: -36px;
			left: 32px;
			right: 32px;
			scrollbar-width: none;

			.openFiltersBtn {
				padding: 12px 42px;
				height: 72px;
				width: 100%;
				display: flex;
				align-items: center;
				justify-content: center;
				gap: 10px;

				border-radius: 8px;
				background: var(--Brand-Purple-100, white);
				border-color: white;

				p {
					color: var(--Brand-Purple, #8c30f5);
					text-align: center;
					font-feature-settings: 'liga' off;
					/* Label/Large Label */
					font-family: Manrope;
					font-size: 20px;
					font-style: normal;
					font-weight: 700;
					line-height: 30px; /* 150% */
					text-wrap: nowrap;
				}
			}
		}

		.mapComponent {
			width: 100vw;
			height: calc(100vh - 105px);
			scrollbar-width: none;

			.cluster {
				&.clusterSm,
				&.clusterMd,
				&.clusterLg {
					color: var(--Default-White, #fff);
					text-align: center;
					font-family: Manrope;
					font-size: 24px;
					font-style: normal;
					font-weight: 600;
					line-height: 24px; /* 100% */

					border: 2px solid white;
					background-color: #474a57;

					/* &.active {
						background-color: #8c30f5;
					} */
				}

				&.clusterSm {
					padding: 9px 14px;
					border-radius: 50%;
				}

				&.clusterMd {
					padding: 18.7px 16px;
					border-radius: 50%;
				}

				&.clusterLg {
					padding: 28.7px 18px;
					border-radius: 50%;
				}

				&[data-active='1'] {
					background-color: #8c30f5;
				}
			}

			/* .marker {
					z-index: -10000;
					position: relative;
	
					img {
						z-index: -100;
						position: relative;
					}
				} */
		}

		.cardTooltip {
			padding: 0px;
			background: rgb(255, 255, 255);
			// color: #000433;
			// border: 1px solid var(--Text-Gray-300, #d9dbe1);
			border-radius: 12px;
			// font-size: 14px;
			// font-weight: 600;
			// font-family: 'Manrope', sans-serif;
			// line-height: 20px;
			opacity: 1;
			z-index: 1;

			.react-tooltip-arrow {
				border-bottom: 1px solid var(--Text-Gray-300, #d9dbe1);
				border-right: 1px solid var(--Text-Gray-300, #d9dbe1);
			}
			pointer-events: all;
		}
	}
}

.mobile {
	.mapPage {
		position: relative;
		scrollbar-width: none;

		.mainFilters {
			z-index: 10;
			scrollbar-width: none;
		}

		.landSelector,
		.citySelector {
			width: 100%;
		}

		.sortBySelector {
			width: 100%;
		}

		.landSelector__control,
		.typeSelector__control,
		.citySelector__control {
			border-radius: 8px;
			padding: 8px 8px 8px 16px;
			display: flex;
			gap: 32px;
			align-items: center;
			border: 1px solid #d9dbe1;
			box-shadow: none;

			&:focus,
			&:active,
			&:hover {
				border: 1px solid #8c30f5;
				box-shadow: none;
			}

			.landSelector__value-container,
			.typeSelector__value-container,
			.citySelector__value-container {
				padding: 0;
				overflow-x: auto;
				flex-wrap: nowrap;
				scrollbar-width: thin; /* Hide scrollbar in Firefox */
				scrollbar-color: #d9dbe1 transparent;
				scroll-behavior: smooth;

				display: flex;
				align-items: center;

				.prefix {
					color: var(--Text-Gray-800, #474a57);
					font-family: Manrope;
					font-size: 14px;
					font-style: normal;
					font-weight: 500;
					line-height: 24px; /* 171.429% */
				}

				.landSelector__placeholder,
				.typeSelector__placeholder,
				.citySelector__placeholder {
					color: var(--Text-Gray-900, #000433);
					font-family: Manrope;
					font-size: 14px;
					font-style: normal;
					font-weight: 500;
					line-height: 24px; /* 171.429% */
				}

				.landSelector__multi-value,
				.typeSelector__multi-value,
				.citySelector__multi-value {
					min-width: fit-content;
				}
			}
		}

		.landSelector__menu,
		.typeSelector__menu,
		.citySelector__menu {
			margin: 12px 0;

			.landSelector__menu-list,
			.typeSelector__menu-list,
			.citySelector__menu-list {
				padding: 14px 0 !important;

				.landSelector__option,
				.typeSelector__option,
				.citySelector__option {
					color: var(--Text-Gray-900, #000433);
					font-feature-settings: 'liga' off;
					/* Body / Body 2 */
					font-family: Manrope;
					font-size: 14px;
					font-style: normal;
					font-weight: 400;
					line-height: 24px; /* 171.429% */
					text-wrap: pretty;

					&:hover,
					&:active,
					&:focus {
						background-color: #f1e4ff;
						color: var(--Brand-Purple, #8c30f5);
						font-feature-settings: 'liga' off;
						/* Body / Body 2 */
						font-family: Manrope;
						font-size: 14px;
						font-style: normal;
						font-weight: 400;
						line-height: 24px; /* 171.429% */
					}

					.selectMultiOption {
						display: flex;
						align-items: center;
						justify-content: space-between;
						gap: 4px;

						.optionCheckbox {
							width: 24px;
							height: 24px;
							border: 2px solid #d9dbe1;
							border-radius: 4px;
							flex-shrink: 0;

							&.checked {
								background-color: #8c30f5;
								border-color: #8c30f5;
								// background-image: url('../../assets/icons/Line.svg');
								box-shadow: none;
								position: relative;
								background-image: none;

								&::before {
									content: '';
									position: absolute;
									top: 0;
									left: 0;
									width: 100%;
									height: 100%;
									background-image: url('../../assets/icons/Line.svg');
									background-size: cover;
									background-repeat: no-repeat;
									filter: brightness(0) saturate(100%) invert(100%) sepia(0%)
										saturate(0%) hue-rotate(182deg) brightness(106%)
										contrast(101%);
								}
							}
						}
					}
				}

				.landSelector__option--is-focused,
				.typeSelector__option--is-focused,
				.citySelector__option--is-focused {
					background-color: #f1e4ff !important;
					color: var(--Brand-Purple, #8c30f5) !important;

					.optionCheckbox {
						&:not(.checked) {
							border-color: white !important;
						}
					}
				}

				.landSelector__option--is-selected,
				.typeSelector__option--is-selected,
				.citySelector__option--is-selected {
					background-color: white;
					// color: var(--Brand-Purple, #8c30f5);
				}
			}
		}

		.landSelector__indicator-separator,
		.typeSelector__indicator-separator,
		.citySelector__indicator-separator {
			display: none;
		}

		//__control--is-focused

		.landSelector__dropdown-indicator,
		.typeSelector__dropdown-indicator,
		.citySelector__dropdown-indicator {
			border: 1px solid #d9dbe1;
			border-radius: 50%;
			padding: 4px;
			margin: 0;
		}

		.filtersMap {
			position: absolute;
			z-index: 1;
			top: 16px;
			left: 16px;
			right: 16px;
			scrollbar-width: none;

			.openFiltersBtn {
				padding: 12px;
				height: fit-content;
				width: fit-content;
				display: flex;
				align-items: center;
				justify-content: center;
				gap: 10px;

				border-radius: 8px;
				background: var(--Brand-Purple-100, white);
				border-color: white;

				p {
					color: var(--Brand-Purple, #8c30f5);
					text-align: center;
					font-feature-settings: 'liga' off;
					/* Label/Large Label */
					font-family: Manrope;
					font-size: 20px;
					font-style: normal;
					font-weight: 700;
					line-height: 30px; /* 150% */
					text-wrap: nowrap;
				}
			}
		}

		.mapComponent {
			width: 100vw;
			height: calc(100vh - 89.75px);
			scrollbar-width: none;

			.cluster {
				&.clusterSm,
				&.clusterMd,
				&.clusterLg {
					color: var(--Default-White, #fff);
					text-align: center;
					font-family: Manrope;
					font-size: 24px;
					font-style: normal;
					font-weight: 600;
					line-height: 24px; /* 100% */

					border: 2px solid white;
					background-color: #474a57;

					/* &.active {
						background-color: #8c30f5;
					} */
				}

				&.clusterSm {
					padding: 9px 14px;
					border-radius: 50%;
				}

				&.clusterMd {
					padding: 18.7px 16px;
					border-radius: 50%;
				}

				&.clusterLg {
					padding: 28.7px 18px;
					border-radius: 50%;
				}

				&[data-active='1'] {
					background-color: #8c30f5;
				}
			}

			/* .marker {
					z-index: -10000;
					position: relative;
	
					img {
						z-index: -100;
						position: relative;
					}
				} */
		}

		.cardTooltip {
			padding: 0;
			background: rgb(255, 255, 255);
			// color: #000433;
			// border: 1px solid var(--Text-Gray-300, #d9dbe1);
			border-radius: 12px;
			// font-size: 14px;
			// font-weight: 600;
			// font-family: 'Manrope', sans-serif;
			// line-height: 20px;
			opacity: 1;
			z-index: 1;

			.react-tooltip-arrow {
				border-bottom: 1px solid var(--Text-Gray-300, #d9dbe1);
				border-right: 1px solid var(--Text-Gray-300, #d9dbe1);
			}
			pointer-events: all;
		}
	}
}
