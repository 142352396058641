.desktop {
	.objectPage {
		.timer {
			display: flex;
			align-items: center;

			.block {
				display: flex;
				flex-direction: column;
				align-items: center;
				width: 58.52px;

				p {
					&:first-child {
						color: var(--Text-Gray-900, #000433);
						font-feature-settings: 'liga' off;
						font-family: Manrope;
						font-size: 24px;
						font-style: normal;
						font-weight: 400;
						line-height: 32px; /* 133.333% */
					}
					&:last-child {
						color: var(--Text-Gray-700, #969bab);
						font-feature-settings: 'liga' off;
						font-family: Manrope;
						font-size: 12px;
						font-style: normal;
						font-weight: 400;
						line-height: 18px; /* 150% */
						text-align: center;
					}
				}
			}
			.dots {
				color: var(--black, #000);

				font-family: Manrope;
				font-size: 24px;
				font-style: normal;
				font-weight: 400;
				line-height: 32px; /* 133.333% */
			}
		}
	}
}

.tablet {
	.objectPage {
		.timer {
			display: flex;
			align-items: center;

			.block {
				display: flex;
				flex-direction: column;
				align-items: center;
				width: 58.52px;

				p {
					&:first-child {
						color: var(--Text-Gray-900, #000433);
						font-feature-settings: 'liga' off;
						font-family: Manrope;
						font-size: 24px;
						font-style: normal;
						font-weight: 400;
						line-height: 32px; /* 133.333% */
					}
					&:last-child {
						color: var(--Text-Gray-700, #969bab);
						font-feature-settings: 'liga' off;
						font-family: Manrope;
						font-size: 12px;
						font-style: normal;
						font-weight: 400;
						line-height: 18px; /* 150% */
						text-align: center;
					}
				}
			}
			.dots {
				color: var(--black, #000);

				font-family: Manrope;
				font-size: 24px;
				font-style: normal;
				font-weight: 400;
				line-height: 32px; /* 133.333% */
			}
		}
	}
}

.mobile {
	.objectPage {
		.timer {
			display: flex;
			align-items: center;

			.block {
				display: flex;
				flex-direction: column;
				align-items: center;
				width: 58.52px;

				p {
					&:first-child {
						color: var(--Text-Gray-900, #000433);
						font-feature-settings: 'liga' off;
						font-family: Manrope;
						font-size: 24px;
						font-style: normal;
						font-weight: 400;
						line-height: 32px; /* 133.333% */
					}
					&:last-child {
						color: var(--Text-Gray-700, #969bab);
						font-feature-settings: 'liga' off;
						font-family: Manrope;
						font-size: 12px;
						font-style: normal;
						font-weight: 400;
						line-height: 18px; /* 150% */
						text-align: center;
					}
				}
			}
			.dots {
				color: var(--black, #000);

				font-family: Manrope;
				font-size: 24px;
				font-style: normal;
				font-weight: 400;
				line-height: 32px; /* 133.333% */
			}
		}
	}
}
