@font-face {
	font-family: 'Caros';
	font-weight: 800;
	src: url('../public/fonts/Caros_ExtraBold.otf') format('opentype');
}

@font-face {
	font-family: 'Manrope';
	font-weight: 400;
	src: url('../public/fonts/manrope-regular.otf') format('opentype');
}

@font-face {
	font-family: 'Manrope';
	font-weight: 700;
	src: url('../public/fonts/manrope-bold.otf') format('opentype');
}

@font-face {
	font-family: 'Manrope';
	font-weight: 800;
	src: url('../public/fonts/manrope-extrabold.otf') format('opentype');
}


@font-face {
	font-family: 'Poppins';
	font-weight: 500;
	src: url('../public/fonts/Poppins-Medium.ttf') format('opentype');
}

html,
body {
	font-family: 'Manrope', sans-serif;
	font-size: 14px;
	font-weight: normal;
	color: white;
	margin: 0;
}

.root {
	position: relative;
	height: 100%;
}

// TOASTIFY:
.Toastify {
	.Toastify__toast {
		border-radius: 5px;
		border-left: 8px solid;
		.Toastify__toast-body {
			margin: 0;
			padding: 4px 48px;
			.Toastify__toast-icon {
				display: none;
			}

			div {
				&:last-child {
					text-align: center;
					font-feature-settings: 'liga' off, 'clig' off;
					/* Label / Medium Label */
					font-family: Manrope;
					font-size: 14px;
					font-style: normal;
					font-weight: 700;
					line-height: 20px; /* 142.857% */
				}
			}
		}

		&.Toastify__toast--error {
			border-left-color: #f39f9f;
			background: #fae1e1;

			.Toastify__toast-body {
				div {
					&:last-child {
						color: #d94040;
					}
				}
			}

			.Toastify__progress-bar--wrp {
				.Toastify__progress-bar--bg {
					background-color: transparent;
				}

				.Toastify__progress-bar {
					background-color: #f39f9f;
					border-bottom-left-radius: 0;
					border-bottom-right-radius: 8px;
					border-top-right-radius: 8px;
				}
			}
		}

		&.Toastify__toast--success {
			border-left-color: #00ffd0;
			background: #e2f9f2;

			.Toastify__toast-body {
				div {
					&:last-child {
						color: #13dfba;
					}
				}
			}

			.Toastify__progress-bar--wrp {
				.Toastify__progress-bar--bg {
					background-color: transparent;
				}

				.Toastify__progress-bar {
					background-color: #00ffd0;
					border-bottom-left-radius: 0;
					border-bottom-right-radius: 8px;
					border-top-right-radius: 8px;
				}
			}
		}

		&.Toastify__toast--warning {
			border-left-color: #ffc278;
			background: #faf3e1;

			.Toastify__toast-body {
				div {
					&:last-child {
						color: #fe9a22;
					}
				}
			}

			.Toastify__progress-bar--wrp {
				.Toastify__progress-bar--bg {
					background-color: transparent;
				}

				.Toastify__progress-bar {
					background-color: #ffc278;
					border-bottom-left-radius: 0;
					border-bottom-right-radius: 8px;
					border-top-right-radius: 8px;
				}
			}
		}
	}
}
