.loadingModal {
	.modal-dialog {
		margin: 0;
		width: 100vw;
		height: 100vh;
		display: flex;
		justify-content: center;
		align-items: center;
		max-width: 100vw;

		.modal-content {
			width: fit-content;
			--bs-modal-bg: none;
			--bs-modal-border-color: none;

			.modal-body {
				width: fit-content;
				padding: 0;
			}
		}
	}
}
