.objectCardsModal {
	padding: 0 !important;
	--bs-modal-border-width: 0 !important;
	--bs-modal-bg: none !important;
	backdrop-filter: blur(18px) !important;

	&.tablet {
		.modal-dialog {
			padding: 0;
			margin: 0;
			position: absolute;
			right: 0;
			top: 0;
			height: 100%;
		}

		.modal-body {
			width: 402px;
			min-height: 100%;
			height: fit-content;
			padding: 16px;
			background: rgba(255, 255, 255, 0.4) !important;
			backdrop-filter: blur(18px) !important;

			.cardsList {
				display: flex;
				flex-direction: column;
				align-items: center;
				gap: 16px;
			}
		}
	}

	&.mobile {
		.modal-dialog {
			padding: 0;
			margin: 0;
			position: absolute;
			bottom: 0;
			height: 100%;
			width: 100%;
			// height: fit-content;
		}

		.modal-content {
			height: 100%;
			width: 100%;
			// height: fit-content;
		}

		/* .modal-header {
			margin: 0;
			padding: 0;
			border: none;
			padding: 12px;
			display: flex;
			align-items: center;
			justify-content: end;
			width: 100%;
			height: fit-content;
		} */

		.modal-body {
			// height: 100%;
			width: 100%;
			padding: 17px;
			display: flex;
			flex-direction: column;
			gap: 12px;
			justify-content: center;
			background: rgba(255, 255, 255, 0.4) !important;
			backdrop-filter: blur(18px) !important;
			border-radius: 12px 12px 0px 0px;
			height: fit-content;
			// max-height: 90%;

			.slick-slider {
				.slick-slide {
					& > div {
						display: flex;
						align-items: center;
						justify-content: center;
					}
				}
				.slick-dots {
					width: 100%;
					position: relative;
					bottom: 0;
					display: flex !important;
					flex-wrap: wrap;
					align-items: center;
					justify-content: center;
					gap: 2px;
					margin-top: 12px;

					li {
						margin: 0;

						button {
							display: flex;
						}

						button:before {
							color: transparent;
							border: 2px solid #fff;
							border-radius: 50%;
							width: 10px;
							height: 10px;
							position: relative;
						}
					}

					li.slick-active {
						button:before {
							border: 2px solid #fff;
							background-color: #fff;
							opacity: 1;
							position: relative;
						}
					}
				}
			}
		}
	}

	.closeBtn {
		position: relative;
		width: 24px;
		height: 24px;
		align-self: end;
		background: none;
		box-shadow: none;
		padding: 0;
		border: none;
		filter: brightness(0) saturate(100%) invert(99%) sepia(99%) saturate(15%)
			hue-rotate(240deg) brightness(104%) contrast(100%);
	}

	.objectCard {
		align-self: center;
		position: relative;
		padding: 20px;
		display: flex;
		flex-direction: column;
		align-items: center;
		gap: 12px;
		width: 350px;
		// height: 238px;

		* {
			margin: 0;
			padding: 0;
		}

		border-radius: 12px;
		background: var(--Default-White, #fff);
		/* Grey Shadow */
		// box-shadow: 0px 30px 40px 0px rgba(212, 217, 232, 0.2);

		&.terminated {
			position: relative;
			opacity: 0.5;
			/* &::before {
			position: absolute;
			content: '';
			width: 100%;
			height: 100%;
			top: 0;
			left: 0;
			background-color: rgba(255, 255, 255, 0.6);
			z-index: 1;
			pointer-events: none;
		} */

			.extraInfo {
				.datesFiles {
					.createDate {
						p {
							z-index: 1;
						}
					}

					.terminDate {
						p {
							color: #f5306b !important;
							z-index: 1;
						}
					}
				}
			}
		}

		.photosTags {
			display: flex;
			flex-direction: column;
			gap: 12px;

			.photos {
				width: 300px;
				height: 198px;
				position: relative;
				align-self: center;
				justify-self: center;

				&:hover {
					.slick-slider {
						.slick-prev,
						.slick-next {
							display: block !important;
						}
					}
				}

				.noPhoto {
					width: 300px;
					height: 198px;
					border-radius: 12px;
				}

				.logo {
					position: absolute;
					top: 86.5px;
					left: 93px;
					filter: brightness(0) saturate(100%) invert(11%) sepia(25%)
						saturate(7002%) hue-rotate(225deg) brightness(58%) contrast(115%);

					span {
						color: var(--Text-Gray-900, #000433);
						font-feature-settings: 'liga' off, 'clig' off;
						font-family: Caros;
						font-size: 18px;
						font-style: normal;
						font-weight: 700;
						line-height: 20px; /* 111.111% */
					}
				}

				.slick-slider {
					z-index: 0;
					margin: 0;
					width: 300px;
					height: 100%;
					position: relative;

					.slick-prev,
					.slick-next {
						position: absolute;
						background-image: url('../../../../assets/icons/chevron-right.svg');
						background-size: 32px 32px;
						background-position: center;
						background-repeat: no-repeat;
						padding: 16px;
						z-index: 1;
						display: none !important;

						&::before {
							display: none;
						}
					}

					.slick-prev {
						left: 12px;
						top: 80px;
						transform: rotate(180deg);
					}

					.slick-next {
						right: 12px;
					}

					.slick-list {
						height: 100%;
						border-radius: 12px !important;

						.slick-track {
							height: 100%;
							.slick-slide {
								height: 100%;
								div {
									height: 100%;
									width: 100%;
								}
								img {
									object-fit: cover;
									height: 100%;
									width: 100%;
								}
							}
						}
					}
				}

				/* .types {
					position: absolute;
					top: 15px;
					left: 15px;
					right: 15px;
					display: flex;
					align-items: center;
					gap: 8px;
					width: calc(100% - 87px);
					overflow-y: scroll;
					scrollbar-width: none;
					cursor: grab;
	
					&.grabbing {
						cursor: grabbing;
					}
				}
	
				.type {
					padding: 4px 12px;
					background-color: #23ce6b;
					border-radius: 5px;
					width: fit-content;
	
					p {
						color: var(--Default-White, #fff);
						font-feature-settings: 'liga' off, 'clig' off;
						font-family: Manrope;
						font-size: 12px;
						font-style: normal;
						font-weight: 700;
						line-height: normal;
						text-wrap: nowrap;
						pointer-events: none;
					}
				}
	
				.typeTooltip {
					background: rgb(255, 255, 255);
					color: #000433;
					border: 1px solid var(--Text-Gray-300, #d9dbe1);
					border-radius: 12px;
					font-size: 14px;
					font-weight: 600;
					font-family: 'Manrope', sans-serif;
					line-height: 20px;
					opacity: 1;
					z-index: 1;
	
					.react-tooltip-arrow {
						border-bottom: 1px solid var(--Text-Gray-300, #d9dbe1);
						border-right: 1px solid var(--Text-Gray-300, #d9dbe1);
					}
				}
	
				.favouriteBtn {
					padding: 8px;
					border-radius: 50%;
					background-color: #ffffff;
					border-color: #969bab2f;
					box-shadow: none;
					position: absolute;
					top: 12px;
					right: 12px;
	
					img {
						filter: brightness(0) saturate(100%) invert(11%) sepia(25%)
							saturate(7002%) hue-rotate(225deg) brightness(58%) contrast(115%);
					}
				} */
			}

			.types {
				// position: absolute;
				// top: 15px;
				// left: 15px;
				// right: 15px;
				width: 100%;
				display: flex;
				align-items: center;
				gap: 8px;
				// width: calc(100% - 87px);
				// overflow-y: scroll;
				// scrollbar-width: none;
				// cursor: grab;

				/* &.grabbing {
					cursor: grabbing;
				} */

				/* p {
					color: var(--Text-Gray-900, #000433);
					font-feature-settings: 'liga' off, 'clig' off;
					font-family: Manrope;
					font-size: 12px;
					font-style: normal;
					font-weight: 700;
					line-height: normal;
				} */

				.type {
					padding: 4px 12px;
					background-color: #23ce6b;
					border-radius: 5px;
					width: fit-content;

					p {
						color: var(--Default-White, #fff);
						font-feature-settings: 'liga' off, 'clig' off;
						font-family: Manrope;
						font-size: 12px;
						font-style: normal;
						font-weight: 700;
						line-height: normal;
						text-wrap: nowrap;
						// pointer-events: none;
					}
				}
			}

			.typeTooltip {
				padding: 8px 16px;
				background: rgb(255, 255, 255);
				color: #000433;
				border: 1px solid var(--Text-Gray-300, #d9dbe1);
				border-radius: 12px;
				padding: 4px;
				font-size: 14px;
				font-weight: 600;
				font-family: 'Manrope', sans-serif;
				line-height: 20px;
				opacity: 1;
				z-index: 1;

				.react-tooltip-arrow {
					border-bottom: 1px solid var(--Text-Gray-300, #d9dbe1);
					border-right: 1px solid var(--Text-Gray-300, #d9dbe1);
				}
			}
		}

		.info {
			display: flex;
			flex-direction: column;
			align-items: start;
			gap: 12px;
			height: 100%;

			.mainInfoEnterBtn {
				display: flex;
				flex-direction: row;
				align-items: start;
				justify-content: space-between;
				gap: 12px;

				.nameLocationId {
					display: flex;
					flex-direction: column;
					align-items: start;
					gap: 0;

					.name {
						color: var(--Text-Gray-900, #000433);
						font-feature-settings: 'liga' off;
						/* Heading/H5 */
						font-family: Manrope;
						font-size: 24px;
						font-style: normal;
						font-weight: 700;
						line-height: 32px; /* 133.333% */
					}

					.location {
						display: flex;
						align-items: center;
						gap: 4px;
						box-shadow: none;
						border: none;
						background: none;

						p {
							color: var(--Brand-Purple, #8c30f5);
							font-feature-settings: 'liga' off;
							/* Lead / Lead 2 */
							font-family: Manrope;
							font-size: 14px;
							font-style: normal;
							font-weight: 500;
							line-height: 24px; /* 171.429% */
							text-align: start;
						}
					}
				}

				.id {
					position: absolute;
					top: 5px;
					right: 5px;
					width: fit-content;
					display: flex;
					align-items: start;
					gap: 0;

					h4 {
						color: var(--Text-Gray-900, #969bab);
						font-feature-settings: 'liga' off;
						/* Heading/H6 */
						font-family: Manrope;
						font-size: 10px;
						font-style: normal;
						font-weight: 100;
						line-height: 10px; /* 150% */
						text-wrap: nowrap;
					}
				}

				/* .enterBtn {
					border-radius: 50%;
					padding: 4.5px;
					border-color: #d9dbe1 !important;
					box-shadow: none !important;
					background: none !important;
					transform: rotate(180deg);

					img {
						width: 24px;
						height: 24px;
					}
				} */
			}

			.description {
				color: var(--Text-Gray-900, #000433);
				font-feature-settings: 'liga' off;
				/* Body / Body 2 */
				font-family: Manrope;
				font-size: 14px;
				font-style: normal;
				font-weight: 400;
				line-height: 24px; /* 171.429% */
				text-wrap: pretty;
			}

			.extraInfo {
				display: flex;
				align-items: center;
				width: 100%;
				justify-content: space-between;

				/* .datesFiles {
					display: flex;
					align-items: center;
					justify-content: space-between; */

				.createDate,
				.terminDate,
				.actualDate {
					display: flex;
					flex-direction: column;
					gap: 0;
					align-items: start;

					p {
						color: var(--Text-Gray-700, #969bab);
						font-feature-settings: 'liga' off, 'clig' off;
						font-family: Manrope;
						font-size: 12px;
						font-style: normal;
						font-weight: 400;
						line-height: 18px; /* 150% */
					}
				}

				.price {
					color: var(--Text-Gray-900, #000433);
					font-feature-settings: 'liga' off;
					/* Heading/H6 */
					font-family: Manrope;
					font-size: 20px;
					font-style: normal;
					font-weight: 600;
					line-height: 30px; /* 150% */
					text-wrap: nowrap;
				}
				// }
			}

			.metaInfo {
				display: flex;
				width: 100%;
				align-items: center;
				justify-content: space-between;

				.id {
					display: flex;
					align-items: start;
					gap: 0;

					h4 {
						color: var(--Text-Gray-900, #000433);
						font-feature-settings: 'liga' off;
						/* Heading/H6 */
						font-family: Manrope;
						font-size: 20px;
						font-style: normal;
						font-weight: 600;
						line-height: 30px; /* 150% */
						text-wrap: nowrap;
					}
				}

				.files {
					background: none;
					box-shadow: none;
					border: none;
					position: relative;
					padding: 0;
					margin: 0;

					.filesCount {
						position: absolute;
						padding: 0px 6px;
						background-color: #8c30f5;
						border-radius: 50%;
						top: -3px;
						left: -4px;

						color: var(--Default-White, #fff);
						font-family: Manrope;
						font-size: 11px;
						font-style: normal;
						font-weight: 600;
						line-height: normal;
					}
				}
			}
		}
	}
}
