.mobileMenuBG {
	width: 100%;
	height: 100%;
	background: linear-gradient(to right, rgb(0, 0, 0) 5%, rgba(0, 0, 0, 0) 100%),
		url('../../../../../public/signInBackground.png') no-repeat;
	background-size: cover;
	background-position: center;
}

.mobileMenu {
	padding: 0 !important;
	backdrop-filter: blur(8px);
	background-color: rgba(0, 4, 51, 0.6) !important;

	p {
		margin: 0;
		padding: 0;
	}

	.modal-dialog {
		margin: 0;
	}

	.modal-content {
		width: 100vw;
		background: none;

		.modal-body {
			display: flex;
			flex-direction: column;
			padding: 56px 0px 42px 42px;
			width: 100%;
			height: 100%;
			min-height: 100vh;
			position: relative;

			.closeBtn {
				position: absolute;
				width: 24px;
				height: 24px;
				top: 32px;
				right: 32px;
				background: none;
				box-shadow: none;
				padding: 0;
				border: none;
			}

			.nav-link {
				font-size: 14px;
				font-weight: bold;
				line-height: 20px;
				color: white;
				padding: 11px 0 !important;
				border-bottom: 3px solid transparent;

				&.active {
					color: white;
				}

				&.disabled {
					color: rgba(var(--bs-emphasis-color-rgb), 0.3);
				}
			}

			.links {
				display: flex;
				flex-direction: column;
				gap: 32px;
				align-items: start;

				& > a {
					&.active {
						border-bottom: 3px solid #00ffd0;
					}

					&:hover {
						color: white;
						border-bottom: 3px solid #00ffd0;
					}
				}

				.dropdown {
					.dropdown-toggle {
						color: var(--Default-White, #fff);
						font-feature-settings: 'liga' off, 'clig' off;
						/* Label / Medium Label */
						font-family: Manrope;
						font-size: 14px;
						font-style: normal;
						font-weight: 700;
						line-height: 20px; /* 142.857% */

						display: flex;
						align-items: center;
						gap: 4px;
						padding: 0;
						background: none;
						border: none;

						box-shadow: 0px 10px 20px 0px rgba(41, 41, 42, 0.07);

						&::after {
							display: none;
						}

						&.show {
							.dropdownArrow {
								transform: rotate(180deg);
								transition: transform 0.5s;
							}
						}

						.dropdownArrow {
							transition: transform 0.5s;
						}
					}

					.dropdown-menu {
						margin-top: 12px;
						padding: 11px 0;
						min-width: fit-content;

						border-radius: 5px;
						border: 1px solid var(--Text-Gray-300, #d9dbe1);
						background: var(--Default-White, #fff);
						/* Dark Shadow */
						box-shadow: 0px 10px 20px 0px rgba(41, 41, 42, 0.07);

						.dropdown-item {
							padding: 5px 16px;

							color: var(--Text-Gray-900, #000433);
							font-feature-settings: 'liga' off;
							/* Body / Body 2 */
							font-family: Manrope;
							font-size: 14px;
							font-style: normal;
							font-weight: 400;
							line-height: 24px; /* 171.429% */

							&:hover,
							&:active {
								background: var(--Brand-Purple-100, #f1e4ff);
							}
						}
					}
				}

				.exitBtn {
					padding: 0;
					background: none;
					border: none;
				}
			}
		}
	}
}
