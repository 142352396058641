.modal-backdrop {
	content: '';
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 4, 51, 0.6) !important;
	backdrop-filter: blur(8px);
	z-index: 100;
	--bs-backdrop-opacity: 0.5 !important;
	opacity: 1 !important;
}

.exitModal {
	p {
		margin: 0;
		padding: 0;
	}

	.modal-dialog {
		display: flex;
		justify-content: center;
	}

	/* .formCircle {
		position: absolute;
		top: -40px;
		left: 50%;
		transform: translateX(-50%);
		border-radius: 70%;
		background: white;
		display: flex;
		align-items: center;
		justify-content: center;
		box-shadow: 0px 10px 20px 0px rgba(41, 41, 42, 0.07);
		z-index: 1060;
		padding: 20px;
	}

	.formCircle .bi-file-earmark-richtext {
		font-size: 32px;
		width: 32px;
		height: 32px;
		display: flex;
		align-self: center;
		color: #969bab;
	} */

	.modal-body {
		display: flex;
		flex-direction: column;
		padding: 55px 32px 42px 32px;
		gap: 24px;

		.exitButton {
			background-color: #00ffd0;
			border-color: #00ffd0;
			border-radius: 8px;
			color: #000433 !important;
			font-size: 20px;
			font-weight: 700;
			line-height: 30px;
			padding: 16px 56px;

			&:hover {
				background-color: #41ffdc !important;
				border-color: #41ffdc !important;
			}

			&:active {
				background-color: #02fbcd !important;
				border-color: #02fbcd !important;
			}

			&:disabled {
				background-color: #80ffe7 !important;
				border-color: #80ffe7 !important;
			}
		}
	}

	.modal-content {
		color: #000433;
		text-align: center;
		border-radius: 12px;
		width: fit-content;
	}

	.modal-title {
		font-size: 28px;
		font-weight: 800;
		line-height: 40px;
	}

	.modalHeader {
		margin-bottom: 8px;
		p {
			padding: 0;
			margin: 0;
		}
	}

	@media (max-width: 1000px) {
		.modal-body {
			width: 335px !important;
		}

		button {
			padding: 16px 32px !important;
		}

		.formBtns {
			flex-direction: column-reverse;
			gap: 16px;
		}
	}

	.formBtns {
		display: flex;
		justify-content: center;
		gap: 24px;

		.closeButton {
			background-color: #eeeff4;
			border-color: #eeeff4;
			border-radius: 8px;
			color: #000433 !important;
			font-size: 20px;
			font-weight: 700;
			line-height: 30px;
			padding: 16px 56px;

			&:hover {
				background-color: #f4f5f7 !important;
				border-color: #f4f5f7 !important;
			}

			&:active {
				background-color: #eeeff4 !important;
				border-color: #eeeff4 !important;
			}

			&:disabled {
				background-color: #f7f7f9 !important;
				border-color: #f7f7f9 !important;
			}
		}
	}
}
