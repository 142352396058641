.modal-backdrop {
	content: '';
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 4, 51, 0.6) !important;
	backdrop-filter: blur(8px);
	z-index: 100;
	--bs-backdrop-opacity: 0.5 !important;
	opacity: 1 !important;
}

.filtersModal {
	padding: 0 !important;
	p {
		margin: 0;
		padding: 0;
	}

	.modal-dialog {
		margin: 0;
	}

	.modal-content {
		width: 310px;

		.modal-body {
			display: flex;
			flex-direction: column;
			padding: 42px 24px 32px 24px;
			width: 100%;
			height: 100%;
			min-height: 100vh;
			position: relative;

			.closeBtn {
				position: absolute;
				width: 24px;
				height: 24px;
				top: 12px;
				right: 12px;
				background: none;
				box-shadow: none;
				padding: 0;
				border: none;
			}

			.filters {
				display: flex;
				flex-direction: column;
				align-items: start;
				gap: 16px;
				width: 100%;

				h3 {
					color: var(--Text-Gray-900, #000433);
					font-family: Manrope;
					font-size: 18px;
					font-style: normal;
					font-weight: 500;
					line-height: 24px; /* 133.333% */
				}

				.filtersForm {
					display: flex;
					flex-direction: column;
					gap: 20px;
					width: 100%;

					.typeSelector,
					.landSelector,
					.citySelector {
						width: 100%;
					}

					.landSelector__control,
					.citySelector__control,
					.typeSelector__control,
					.terminDateSelector__control {
						border-radius: 8px;
						padding: 8px 8px 8px 16px;
						display: flex;
						gap: 32px;
						align-items: center;
						border: 1px solid #d9dbe1;
						box-shadow: none;

						&:focus,
						&:active,
						&:hover {
							border: 1px solid #8c30f5;
							box-shadow: none;
						}

						.typeSelector__value-container,
						.citySelector__value-container,
						.landSelector__value-container,
						.terminDateSelector__value-container {
							padding: 0;
							overflow-x: auto;
							flex-wrap: nowrap;
							scrollbar-width: thin; /* Hide scrollbar in Firefox */
							scrollbar-color: #d9dbe1 transparent;
							scroll-behavior: smooth;

							display: flex;
							align-items: center;

							.prefix {
								color: var(--Text-Gray-800, #474a57);
								font-family: Manrope;
								font-size: 14px;
								font-style: normal;
								font-weight: 500;
								line-height: 24px; /* 171.429% */
							}

							.typeSelector__placeholder,
							.citySelector__placeholder,
							.landSelector__placeholder,
							.terminDateSelector__placeholder {
								color: var(--Text-Gray-900, #000433);
								font-family: Manrope;
								font-size: 14px;
								font-style: normal;
								font-weight: 500;
								line-height: 24px; /* 171.429% */
							}

							.typeSelector__multi-value,
							.citySelector__multi-value,
							.landSelector__multi-value,
							.terminDateSelector__multi-value {
								min-width: fit-content;
							}
						}
					}

					.typeSelector__menu,
					.citySelector__menu,
					.landSelector__menu,
					.terminDateSelector__menu {
						margin: 12px 0;

						.typeSelector__menu-list,
						.citySelector__menu-list,
						.landSelector__menu-list,
						.terminDateSelector__menu-list {
							padding: 14px 0 !important;

							.typeSelector__option,
							.citySelector__option,
							.landSelector__option,
							.terminDateSelector__option {
								color: var(--Text-Gray-900, #000433);
								font-feature-settings: 'liga' off;
								/* Body / Body 2 */
								font-family: Manrope;
								font-size: 14px;
								font-style: normal;
								font-weight: 400;
								line-height: 24px; /* 171.429% */
								text-wrap: pretty;

								&:hover,
								&:active,
								&:focus {
									background-color: #f1e4ff;
									color: var(--Brand-Purple, #8c30f5);
									font-feature-settings: 'liga' off;
									/* Body / Body 2 */
									font-family: Manrope;
									font-size: 14px;
									font-style: normal;
									font-weight: 400;
									line-height: 24px; /* 171.429% */
								}

								.selectMultiOption {
									display: flex;
									align-items: center;
									justify-content: space-between;
									gap: 4px;

									.optionCheckbox {
										width: 24px;
										height: 24px;
										border: 2px solid #d9dbe1;
										border-radius: 4px;
										flex-shrink: 0;

										&.checked {
											background-color: #8c30f5;
											border-color: #8c30f5;
											// background-image: url('../../assets/icons/Line.svg');
											box-shadow: none;
											position: relative;
											background-image: none;

											&::before {
												content: '';
												position: absolute;
												top: 0;
												left: 0;
												width: 100%;
												height: 100%;
												background-image: url('../../assets/icons/Line.svg');
												background-size: cover;
												background-repeat: no-repeat;
												filter: brightness(0) saturate(100%) invert(100%)
													sepia(0%) saturate(0%) hue-rotate(182deg)
													brightness(106%) contrast(101%);
											}
										}
									}
								}
							}

							.typeSelector__option--is-focused,
							.citySelector__option--is-focused,
							.landSelector__option--is-focused,
							.terminDateSelector__option--is-focused {
								background-color: #f1e4ff !important;
								color: var(--Brand-Purple, #8c30f5) !important;

								.optionCheckbox {
									&:not(.checked) {
										border-color: white !important;
									}
								}
							}

							.typeSelector__option--is-selected,
							.citySelector__option--is-selected,
							.landSelector__option--is-selected,
							.terminDateSelector__option--is-selected {
								background-color: white;
								// color: var(--Brand-Purple, #8c30f5);
							}
						}
					}

					.typeSelector__indicator-separator,
					.citySelector__indicator-separator,
					.landSelector__indicator-separator,
					.terminDateSelector__indicator-separator {
						display: none;
					}

					//__control--is-focused

					.typeSelector__dropdown-indicator,
					.citySelector__dropdown-indicator,
					.landSelector__dropdown-indicator,
					.terminDateSelector__dropdown-indicator {
						border: 1px solid #d9dbe1;
						border-radius: 50%;
						padding: 4px;
						margin: 0;
					}

					.input-wrapper {
						display: flex;
						flex-direction: column;
						gap: 12px;

						.form-label {
							color: var(--Text-Gray-700, #969bab);
							font-family: Manrope;
							font-size: 12px;
							font-style: normal;
							font-weight: 500;
							line-height: 12px; /* 100% */
							padding: 0;
							margin: 0;
						}

						input,
						textarea {
							padding: 16px;
							border-radius: 8px;
							position: relative;
							box-shadow: none;
							border: 1px solid #d9dbe1;

							&:hover {
								border: 1px solid #969bab;
							}

							&:active,
							&:focus,
							&:focus-visible {
								border: 1px solid #8c30f5;
								outline: none;
							}

							&.is-invalid {
								border-color: var(--bs-form-invalid-border-color);
							}

							&::placeholder {
								color: #969bab;
							}
						}

						&.datepicker {
							display: flex;
							flex-direction: column;
						}
					}

					.formsRow {
						display: flex;
						align-items: center;
						gap: 18px;
					}

					.showTerminalCheckbox {
						display: flex;
						gap: 8px;
						align-items: center;
						accent-color: #00ffd0;
						border-radius: 100px;
						margin: 4px 0;

						.form-check-input {
							width: 24px;
							height: 24px;
							padding: 0;
							margin: 0;
							border-radius: 4px !important;
							box-shadow: none;

							&:checked {
								background-color: #8c30f5;
								border-color: #8c30f5;
								// background-image: url('../../assets/icons/Line.svg');
								box-shadow: none;
								position: relative;
								background-image: none;

								&::before {
									content: '';
									position: absolute;
									top: 0;
									left: 0;
									width: 100%;
									height: 100%;
									background-image: url('../../assets/icons/Line.svg');
									background-size: cover;
									background-repeat: no-repeat;
									filter: brightness(0) saturate(100%) invert(100%) sepia(0%)
										saturate(0%) hue-rotate(182deg) brightness(106%)
										contrast(101%);
								}
							}
						}

						.form-check-label {
							color: var(--Text-Gray-900, #000433);
							font-feature-settings: 'liga' off;
							/* Body / Body 2 */
							font-family: Manrope;
							font-size: 14px;
							font-style: normal;
							font-weight: 400;
							line-height: 24px; /* 171.429% */
						}
					}

					.formBtns {
						display: flex;
						flex-direction: column;
						align-items: center;
						gap: 12px;

						.searchBtn {
							background-color: #00ffd0;
							border-color: #00ffd0;
							border-radius: 12px;
							color: #000433 !important;
							font-size: 16px;
							font-weight: 700;
							line-height: 26px;
							padding: 8px 32px;
							width: 100%;

							&:hover {
								background-color: #41ffdc !important;
								border-color: #41ffdc !important;
							}

							&:active {
								background-color: #02fbcd !important;
								border-color: #02fbcd !important;
							}

							&:disabled {
								background-color: #80ffe7 !important;
								border-color: #80ffe7 !important;
							}
						}

						.clearFiltersBtn {
							border: none;
							background: none;
							box-shadow: none;
							padding: 0;
							margin: 0;

							color: var(--Brand-Purple, #8c30f5);
							font-feature-settings: 'liga' off;
							/* Body / Body 2 */
							font-family: Manrope;
							font-size: 14px;
							font-style: normal;
							font-weight: 400;
							line-height: 24px; /* 171.429% */
						}
					}
				}
			}
		}
	}
}
