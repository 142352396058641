.modal-backdrop {
	content: '';
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 4, 51, 0.6) !important;
	backdrop-filter: blur(8px);
	z-index: 100;
	--bs-backdrop-opacity: 0.5 !important;
	opacity: 1 !important;
}

.signInModal {
	.modal-dialog {
		display: flex;
		justify-content: center;
	}

	.formCircle {
		position: absolute;
		top: -40px;
		left: 50%;
		transform: translateX(-50%);
		border-radius: 70%;
		background: white;
		display: flex;
		align-items: center;
		justify-content: center;
		box-shadow: 0px 10px 20px 0px rgba(41, 41, 42, 0.07);
		z-index: 1060;
		padding: 20px;

		img {
			font-size: 32px;
			width: 32px;
			height: 32px;
			display: flex;
			align-self: center;
			filter: brightness(0) saturate(100%) invert(74%) sepia(6%) saturate(706%)
				hue-rotate(188deg) brightness(84%) contrast(83%);
		}
	}

	.modal-body {
		display: flex;
		flex-direction: column;
		padding: 55px 32px 42px 32px;
		gap: 24px;
	}

	.modal-content {
		color: #000433;
		text-align: center;
		border-radius: 12px;
		width: fit-content;
	}

	.modal-title {
		font-size: 28px;
		font-weight: 800;
		line-height: 40px;
	}

	.modalHeader {
		margin-bottom: 8px;
		p {
			padding: 0;
			margin: 0;

			&:last-child {
				color: var(--Text-Gray-900, #000433);
				text-align: center;
				font-feature-settings: 'liga' off;

				/* Body / Body 2 */
				font-family: Manrope;
				font-size: 14px;
				font-style: normal;
				font-weight: 400;
				line-height: 24px; /* 171.429% */
			}
		}
	}

	.signInForm {
		display: flex;
		flex-direction: column;
		gap: 16px;
		min-width: 360px;
	}

	.input-wrapper {
		position: relative;

		input {
			padding: 16px;
			border-radius: 8px;
			box-shadow: none;

			&:hover {
				border: 1px solid #969bab;
			}

			&:active,
			&:focus {
				border: 1px solid #8c30f5;
			}
		}

		input:hover + img,
		input:focus + img,
		input:active + img,
		input.is-invalid + img {
			filter: brightness(0) saturate(100%) invert(47%) sepia(3%) saturate(1431%)
				hue-rotate(167deg) brightness(94%) contrast(87%);
		}

		.envelope {
			position: absolute;
			right: 16px;
			top: 16px;
			filter: brightness(0) saturate(100%) invert(89%) sepia(13%) saturate(76%)
				hue-rotate(187deg) brightness(100%) contrast(85%);
			pointer-events: none;
		}

		.showPassword {
			position: absolute;
			right: 16px;
			top: 16px;
			filter: brightness(0) saturate(100%) invert(89%) sepia(13%) saturate(76%)
				hue-rotate(187deg) brightness(100%) contrast(85%);

			&:hover {
				filter: brightness(0) saturate(100%) invert(47%) sepia(3%)
					saturate(1431%) hue-rotate(167deg) brightness(94%) contrast(87%);
			}
		}
	}

	.rememberMeCheckbox {
		display: flex;
		gap: 8px;
		align-items: center;
		accent-color: #00ffd0;
		border-radius: 100px;

		.form-check-input {
			width: 24px;
			height: 24px;
			padding: 0;
			margin: 0;
			border-radius: 4px !important;
			box-shadow: none;

			&:checked {
				background-color: #00ffd0;
				border-color: #00ffd0;
				color: #000433;
				background-image: url('../../../assets/icons/Line.svg');
				box-shadow: none;
			}
		}

		.form-check-label {
			font-weight: 600;
			line-height: 24px;
			font-size: 14px;
		}
	}

	.signInButton {
		background-color: #00ffd0;
		border-color: #00ffd0;
		border-radius: 8px;
		color: #000433 !important;
		font-size: 20px;
		font-weight: 700;
		line-height: 30px;
		padding: 16px 42px;
		margin-top: 16px;

		&:hover {
			background-color: #41ffdc !important;
			border-color: #41ffdc !important;
		}

		&:active {
			background-color: #02fbcd !important;
			border-color: #02fbcd !important;
		}

		&:disabled {
			background-color: #80ffe7 !important;
			border-color: #80ffe7 !important;
		}
	}

	.registrationLink {
		display: flex;
		justify-content: center;
		align-items: center;
		gap: 12px;
	}

	.registrationLink > p {
		margin: 0;
		color: #6c757dc9;
	}

	.registrationLink > a {
		text-decoration: none;
		color: #8c30f5;
	}

	.invalid-feedback {
		color: var(--Accent-Peach, #f39f9f);
		font-family: Manrope;
		font-size: 12px;
		font-style: normal;
		font-weight: 600;
		line-height: 24px; /* 200% */
		margin-top: 4px !important;
		padding-left: 4px;
		text-align: start;
	}

	.form-control.is-invalid {
		background-image: none;
		border-color: #f39f9f;

		&:focus {
			box-shadow: none;
		}
	}

	@media (max-width: 1000px) {
		.modal-body {
			padding: 55px 24px 24px 24px;
		}

		.modalHeader {
			.modal-title {
				color: var(--Text-Gray-900, #000433);
				text-align: center;
				font-feature-settings: 'liga' off;
				/* Heading/H5 */
				font-family: Manrope;
				font-size: 24px;
				font-style: normal;
				font-weight: 700;
				line-height: 32px; /* 133.333% */
			}

			p {
				&:last-child {
					color: var(--Text-Gray-900, #000433);
					text-align: center;
					font-feature-settings: 'liga' off;

					/* Body / Body 2 */
					font-family: Manrope;
					font-size: 14px;
					font-style: normal;
					font-weight: 400;
					line-height: 24px; /* 171.429% */
				}
			}
		}

		.signInForm {
			min-width: 290px;
		}

		.form-check-label {
			color: var(--Text-Gray-900, #000433);
			font-family: Manrope;
			font-size: 14px;
			font-style: normal;
			font-weight: 600;
			line-height: 24px; /* 171.429% */
		}
		.signInButton {
			margin-top: 8px;
		}
	}
}
