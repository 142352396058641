.desktop {
	.mainFilters {
		padding: 32px 48px;
		display: flex;
		align-items: center;
		gap: 24px;
		position: absolute;
		top: -56px;
		z-index: 1030;
		left: 165px;
		right: 165px;
		max-width: calc(100% - 330px);

		border-radius: 12px;
		background: #fff;
		/* Grey Shadow */
		box-shadow: 0px 30px 40px 0px rgba(212, 217, 232, 0.2);

		.searchBtn {
			background-color: #00ffd0;
			border-color: #00ffd0;
			border-radius: 12px;
			color: #000433 !important;
			font-size: 20px;
			font-weight: 700;
			line-height: 30px;
			padding: 12px 42px;
			width: 238px;

			&:hover {
				background-color: #41ffdc !important;
				border-color: #41ffdc !important;
			}

			&:active {
				background-color: #02fbcd !important;
				border-color: #02fbcd !important;
			}

			&:disabled {
				background-color: #80ffe7 !important;
				border-color: #80ffe7 !important;
			}
		}
	}
}

.tablet {
	.mainFilters {
		padding: 24px 32px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		gap: 16px;
		position: absolute;
		top: -48px;
		left: 32px;
		right: 32px;
		z-index: 1030;

		border-radius: 12px;
		background: #fff;
		/* Grey Shadow */
		box-shadow: 0px 30px 40px 0px rgba(212, 217, 232, 0.2);

		.searchBtn {
			background-color: #00ffd0;
			border-color: #00ffd0;
			border-radius: 12px;
			color: #000433 !important;
			font-size: 20px;
			font-weight: 700;
			line-height: 30px;
			padding: 12px 42px;
			width: 168px;

			&:hover {
				background-color: #41ffdc !important;
				border-color: #41ffdc !important;
			}

			&:active {
				background-color: #02fbcd !important;
				border-color: #02fbcd !important;
			}

			&:disabled {
				background-color: #80ffe7 !important;
				border-color: #80ffe7 !important;
			}
		}
	}
}

.mobile {
	.mainFilters {
		padding: 16px;
		display: flex;
		flex-direction: column;
		gap: 12px;
		position: absolute;
		left: 16px;
		right: 16px;
		top: -70px;
		z-index: 1030;

		border-radius: 12px;
		background: #fff;
		/* Grey Shadow */
		box-shadow: 0px 30px 40px 0px rgba(212, 217, 232, 0.2);

		.searchBtn {
			background-color: #00ffd0;
			border-color: #00ffd0;
			border-radius: 12px;
			color: #000433 !important;
			font-size: 20px;
			font-weight: 700;
			line-height: 30px;
			padding: 12px 42px;
			width: 100%;
			margin-top: 4px;

			&:hover {
				background-color: #41ffdc !important;
				border-color: #41ffdc !important;
			}

			&:active {
				background-color: #02fbcd !important;
				border-color: #02fbcd !important;
			}

			&:disabled {
				background-color: #80ffe7 !important;
				border-color: #80ffe7 !important;
			}
		}
	}
}
