//////////////////////////////////////////////////// DESKTOP ///////////////////////////////////////////////////////////////
.desktop,
.tablet {
	.signInBackgroundWrapper {
		background: linear-gradient(
				to right,
				rgb(0, 0, 0) 5%,
				rgba(0, 0, 0, 0) 100%
			),
			url('../../../public/signInBackground.png') no-repeat;
		background-size: cover;
		background-position: center;
		width: 100%;
		height: 100%;
		position: absolute;
	}
}
