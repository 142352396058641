.desktop,
.tablet {
	.cardsList {
		.objectCard {
			position: relative;
			padding: 20px;
			display: flex;
			flex-direction: column;
			align-items: center;
			gap: 12px;
			width: 350px;
			// height: 238px;

			* {
				margin: 0;
				padding: 0;
			}

			border-radius: 12px;
			background: var(--Default-White, #fff);
			/* Grey Shadow */
			// box-shadow: 0px 30px 40px 0px rgba(212, 217, 232, 0.2);

			&.terminated {
				position: relative;
				opacity: 0.5;
				/* &::before {
				position: absolute;
				content: '';
				width: 100%;
				height: 100%;
				top: 0;
				left: 0;
				background-color: rgba(255, 255, 255, 0.6);
				z-index: 1;
				pointer-events: none;
			} */

				.extraInfo {
					.createDate {
						p {
							z-index: 1;
						}
					}

					.terminDate {
						p {
							color: #f5306b !important;
							z-index: 1;
						}
					}
				}
			}

			.photosTags {
				display: flex;
				flex-direction: column;
				gap: 12px;

				.photos {
					width: 300px;
					height: 198px;
					position: relative;
					align-self: center;
					justify-self: center;

					&:hover {
						.slick-slider {
							.slick-prev,
							.slick-next {
								display: block !important;
							}
						}
					}

					.noPhoto {
						width: 300px;
						height: 198px;
						border-radius: 12px;
					}

					.logo {
						position: absolute;
						top: 83.5px;
						left: 90.615px;
						filter: brightness(0) saturate(100%) invert(11%) sepia(25%)
							saturate(7002%) hue-rotate(225deg) brightness(58%) contrast(115%);

						span {
							color: var(--Text-Gray-900, #000433);
							font-feature-settings: 'liga' off, 'clig' off;
							font-family: Caros;
							font-size: 18px;
							font-style: normal;
							font-weight: 700;
							line-height: 20px; /* 111.111% */
						}
					}

					.slick-slider {
						z-index: 0;
						margin: 0;
						width: 300px;
						height: 100%;
						position: relative;

						.slick-prev,
						.slick-next {
							position: absolute;
							background-image: url('../../../../assets/icons/chevron-right.svg');
							background-size: 32px 32px;
							background-position: center;
							background-repeat: no-repeat;
							padding: 16px;
							z-index: 1;
							display: none !important;

							&::before {
								display: none;
							}
						}

						.slick-prev {
							left: 12px;
							top: 80px;
							transform: rotate(180deg);
						}

						.slick-next {
							right: 12px;
						}

						.slick-list {
							height: 100%;
							border-radius: 12px !important;

							.slick-track {
								height: 100%;
								.slick-slide {
									height: 100%;
									div {
										height: 100%;
										width: 100%;
									}
									img {
										object-fit: cover;
										height: 100%;
										width: 100%;
									}
								}
							}
						}
					}

					/* .types {
						position: absolute;
						top: 15px;
						left: 15px;
						right: 15px;
						display: flex;
						align-items: center;
						gap: 8px;
						width: calc(100% - 87px);
						overflow-y: scroll;
						scrollbar-width: none;
						cursor: grab;
		
						&.grabbing {
							cursor: grabbing;
						}
					}
		
					.type {
						padding: 4px 12px;
						background-color: #23ce6b;
						border-radius: 5px;
						width: fit-content;
		
						p {
							color: var(--Default-White, #fff);
							font-feature-settings: 'liga' off, 'clig' off;
							font-family: Manrope;
							font-size: 12px;
							font-style: normal;
							font-weight: 700;
							line-height: normal;
							text-wrap: nowrap;
							pointer-events: none;
						}
					}
		
					.typeTooltip {
						background: rgb(255, 255, 255);
						color: #000433;
						border: 1px solid var(--Text-Gray-300, #d9dbe1);
						border-radius: 12px;
						font-size: 14px;
						font-weight: 600;
						font-family: 'Manrope', sans-serif;
						line-height: 20px;
						opacity: 1;
						z-index: 1;
		
						.react-tooltip-arrow {
							border-bottom: 1px solid var(--Text-Gray-300, #d9dbe1);
							border-right: 1px solid var(--Text-Gray-300, #d9dbe1);
						}
					}
		
					.favouriteBtn {
						padding: 8px;
						border-radius: 50%;
						background-color: #ffffff;
						border-color: #969bab2f;
						box-shadow: none;
						position: absolute;
						top: 12px;
						right: 12px;
		
						img {
							filter: brightness(0) saturate(100%) invert(11%) sepia(25%)
								saturate(7002%) hue-rotate(225deg) brightness(58%) contrast(115%);
						}
					} */
				}

				.types {
					// position: absolute;
					// top: 15px;
					// left: 15px;
					// right: 15px;
					width: 100%;
					display: flex;
					align-items: center;
					gap: 8px;
					// width: calc(100% - 87px);
					// overflow-y: scroll;
					// scrollbar-width: none;
					// cursor: grab;

					/* &.grabbing {
						cursor: grabbing;
					} */

					/* p {
						color: var(--Text-Gray-900, #000433);
						font-feature-settings: 'liga' off, 'clig' off;
						font-family: Manrope;
						font-size: 12px;
						font-style: normal;
						font-weight: 700;
						line-height: normal;
					} */

					.type {
						padding: 4px 12px;
						background-color: #23ce6b;
						border-radius: 5px;
						width: fit-content;

						p {
							color: var(--Default-White, #fff);
							font-feature-settings: 'liga' off, 'clig' off;
							font-family: Manrope;
							font-size: 12px;
							font-style: normal;
							font-weight: 700;
							line-height: normal;
							text-wrap: nowrap;
							// pointer-events: none;
						}
					}
				}

				.typeTooltip {
					padding: 8px 16px;
					background: rgb(255, 255, 255);
					color: #000433;
					border: 1px solid var(--Text-Gray-300, #d9dbe1);
					border-radius: 12px;
					font-size: 14px;
					font-weight: 600;
					font-family: 'Manrope', sans-serif;
					line-height: 20px;
					opacity: 1;
					z-index: 1;

					.react-tooltip-arrow {
						border-bottom: 1px solid var(--Text-Gray-300, #d9dbe1);
						border-right: 1px solid var(--Text-Gray-300, #d9dbe1);
					}
				}
			}

			.info {
				display: flex;
				flex-direction: column;
				align-items: start;
				gap: 12px;
				height: 100%;

				.mainInfoEnterBtn {
					display: flex;
					flex-direction: row;
					align-items: start;
					justify-content: space-between;
					gap: 12px;

					.nameLocationId {
						display: flex;
						flex-direction: column;
						align-items: start;
						gap: 0;

						.name {
							color: var(--Text-Gray-900, #000433);
							font-feature-settings: 'liga' off;
							/* Heading/H5 */
							font-family: Manrope;
							font-size: 24px;
							font-style: normal;
							font-weight: 700;
							line-height: 32px; /* 133.333% */
						}

						.location {
							display: flex;
							align-items: center;
							gap: 4px;
							text-decoration: none;
							box-shadow: none;
							border: none;
							background: none;

							&:hover {
								text-decoration: underline;
								text-decoration-color: #8c30f5;
							}

							p {
								color: var(--Brand-Purple, #8c30f5);
								font-feature-settings: 'liga' off;
								/* Lead / Lead 2 */
								font-family: Manrope;
								font-size: 14px;
								font-style: normal;
								font-weight: 500;
								line-height: 24px; /* 171.429% */
								text-align: start;
							}
						}
					}

					.id {
						position: absolute;
						top: 5px;
						right: 5px;
						width: fit-content;
						display: flex;
						align-items: start;
						gap: 0;

						h4 {
							color: var(--Text-Gray-900, #969bab);
							font-feature-settings: 'liga' off;
							/* Heading/H6 */
							font-family: Manrope;
							font-size: 10px;
							font-style: normal;
							font-weight: 100;
							line-height: 10px; /* 150% */
							text-wrap: nowrap;
						}
					}

					/* .enterBtn {
						border-radius: 50%;
						padding: 4.5px;
						border-color: #d9dbe1 !important;
						box-shadow: none !important;
						background: none !important;
						transform: rotate(180deg);
	
						img {
							width: 24px;
							height: 24px;
						}
					} */
				}

				.description {
					color: var(--Text-Gray-900, #000433);
					font-feature-settings: 'liga' off;
					/* Body / Body 2 */
					font-family: Manrope;
					font-size: 14px;
					font-style: normal;
					font-weight: 400;
					line-height: 24px; /* 171.429% */
					text-wrap: pretty;
				}

				.extraInfo {
					display: flex;
					align-items: center;
					width: 100%;
					justify-content: space-between;

					/* .datesFiles {
						display: flex;
						align-items: center;
						justify-content: space-between; */

					.createDate,
					.terminDate,
					.actualDate {
						display: flex;
						flex-direction: column;
						gap: 0;
						align-items: start;

						p {
							color: var(--Text-Gray-700, #969bab);
							font-feature-settings: 'liga' off, 'clig' off;
							font-family: Manrope;
							font-size: 12px;
							font-style: normal;
							font-weight: 400;
							line-height: 18px; /* 150% */
						}
					}

					.price {
						color: var(--Text-Gray-900, #000433);
						font-feature-settings: 'liga' off;
						/* Heading/H6 */
						font-family: Manrope;
						font-size: 20px;
						font-style: normal;
						font-weight: 600;
						line-height: 30px; /* 150% */
						text-wrap: nowrap;

						span {
							color: var(--Text-Gray-900, #000433);
							font-feature-settings: 'liga' off;
							/* Heading/H6 */
							font-family: Manrope;
							font-size: 15px;
							font-style: normal;
							font-weight: 600;
							line-height: 20px; /* 150% */
							text-wrap: nowrap;
						}
					}
					// }
				}

				.metaInfo {
					display: flex;
					width: 100%;
					align-items: center;
					justify-content: space-between;

					.id {
						display: flex;
						align-items: start;
						gap: 0;

						h4 {
							color: var(--Text-Gray-900, #000433);
							font-feature-settings: 'liga' off;
							/* Heading/H6 */
							font-family: Manrope;
							font-size: 20px;
							font-style: normal;
							font-weight: 600;
							line-height: 30px; /* 150% */
							text-wrap: nowrap;
						}
					}

					.files {
						background: none;
						box-shadow: none;
						border: none;
						position: relative;
						padding: 0;
						margin: 0;

						.filesCount {
							position: absolute;
							padding: 0px 6px;
							background-color: #8c30f5;
							border-radius: 50%;
							top: -3px;
							left: -4px;

							color: var(--Default-White, #fff);
							font-family: Manrope;
							font-size: 11px;
							font-style: normal;
							font-weight: 600;
							line-height: normal;
						}
					}
				}
			}
		}
	}

	.objectCard {
		position: relative;
		padding: 20px;
		display: flex;
		align-items: start;
		gap: 30px;
		width: 768px;
		// height: 238px;

		border-radius: 12px;
		background: var(--Default-White, #fff);
		/* Grey Shadow */
		box-shadow: 0px 30px 40px 0px rgba(212, 217, 232, 0.2);

		&.terminated {
			// position: relative;
			// opacity: 0.5;
			/* &::before {
			position: absolute;
			content: '';
			width: 100%;
			height: 100%;
			top: 0;
			left: 0;
			background-color: rgba(255, 255, 255, 0.6);
			z-index: 1;
			pointer-events: none;
		} */

			&::before {
				content: '';
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				background-color: rgba(255, 255, 255, 0.5);
				pointer-events: none;
				z-index: 1;
			}

			.extraInfo {
				.createDate {
					p {
						z-index: 1;
					}
				}

				.terminDate {
					p {
						color: #f5306b !important;
						z-index: 1;
					}
				}
			}
		}

		.photosTags {
			display: flex;
			flex-direction: column;
			gap: 12px;

			.photos {
				width: 305px;
				height: 100%;
				position: relative;
				display: flex;
				flex-direction: column;
				align-items: center;
				gap: 12px;

				&:hover {
					.slick-slider {
						.slick-prev,
						.slick-next {
							display: block !important;
						}
					}
				}

				.noPhoto {
					width: 305px;
					height: 204px;
					border-radius: 12px;
				}

				.logo {
					position: absolute;
					top: 86.5px;
					left: 93.115px;
					filter: brightness(0) saturate(100%) invert(11%) sepia(25%)
						saturate(7002%) hue-rotate(225deg) brightness(58%) contrast(115%);

					span {
						color: var(--Text-Gray-900, #000433);
						font-feature-settings: 'liga' off, 'clig' off;
						font-family: Caros;
						font-size: 18px;
						font-style: normal;
						font-weight: 700;
						line-height: 20px; /* 111.111% */
					}
				}

				.slick-slider {
					z-index: 0;
					margin: 0;
					width: 305px;
					height: 204px;
					position: relative;
					// border: 2px solid #8C30F5;
					// border-radius: 12px;

					.slick-prev,
					.slick-next {
						position: absolute;
						background-image: url('../../../../assets/icons/chevron-right.svg');
						background-size: 32px 32px;
						background-position: center;
						background-repeat: no-repeat;
						padding: 16px;
						z-index: 1;
						display: none !important;

						&::before {
							display: none;
						}
					}

					.slick-prev {
						left: 12px;
						top: 80px;
						transform: rotate(180deg);
					}

					.slick-next {
						right: 12px;
					}

					.slick-list {
						height: 100%;
						border-radius: 12px !important;

						.slick-track {
							height: 100%;
							.slick-slide {
								height: 100%;
								div {
									height: 100%;
									width: 100%;
								}
								img {
									object-fit: cover;
									height: 100%;
									width: 100%;
								}
							}
						}
					}
				}

				.favouriteBtn {
					padding: 8px;
					border-radius: 50%;
					background-color: #ffffff;
					border-color: #969bab2f;
					box-shadow: none;
					position: absolute;
					top: 15px;
					right: 15px;

					img {
						filter: brightness(0) saturate(100%) invert(11%) sepia(25%)
							saturate(7002%) hue-rotate(225deg) brightness(58%) contrast(115%);
					}
				}

				/* .priceFiles {
					width: 100%;
					display: flex;
					align-items: center;
					justify-content: space-between;
					padding: 0 8px;
	
					.price {
						color: var(--Text-Gray-900, #000433);
						font-feature-settings: 'liga' off;
						font-family: Manrope;
						font-size: 20px;
						font-style: normal;
						font-weight: 600;
						line-height: 30px;
						text-wrap: nowrap;
					}
	
					.files {
						background: none;
						box-shadow: none;
						border: none;
						position: relative;
						padding: 0;
						margin: 0;
	
						.filesCount {
							position: absolute;
							padding: 0px 6px;
							background-color: #8c30f5;
							border-radius: 50%;
							top: -3px;
							left: -4px;
	
							color: var(--Default-White, #fff);
							font-family: Manrope;
							font-size: 11px;
							font-style: normal;
							font-weight: 600;
							line-height: normal;
						}
					}
				} */
			}

			.types {
				// position: absolute;
				// top: 15px;
				// left: 15px;
				// right: 15px;
				width: 100%;
				display: flex;
				align-items: center;
				gap: 8px;
				// width: calc(100% - 87px);
				// overflow-y: scroll;
				// scrollbar-width: none;
				// cursor: grab;

				/* &.grabbing {
					cursor: grabbing;
				} */

				/* p {
					color: var(--Text-Gray-900, #000433);
					font-feature-settings: 'liga' off, 'clig' off;
					font-family: Manrope;
					font-size: 12px;
					font-style: normal;
					font-weight: 700;
					line-height: normal;
				} */

				.type {
					padding: 4px 12px;
					background-color: #23ce6b;
					border-radius: 5px;
					width: fit-content;

					p {
						color: var(--Default-White, #fff);
						font-feature-settings: 'liga' off, 'clig' off;
						font-family: Manrope;
						font-size: 12px;
						font-style: normal;
						font-weight: 700;
						line-height: normal;
						text-wrap: nowrap;
						// pointer-events: none;
					}
				}
			}

			.typeTooltip {
				padding: 8px 16px;
				background: rgb(255, 255, 255);
				color: #000433;
				border: 1px solid var(--Text-Gray-300, #d9dbe1);
				border-radius: 12px;
				font-size: 14px;
				font-weight: 600;
				font-family: 'Manrope', sans-serif;
				line-height: 20px;
				opacity: 1;
				z-index: 1030;

				.react-tooltip-arrow {
					border-bottom: 1px solid var(--Text-Gray-300, #d9dbe1);
					border-right: 1px solid var(--Text-Gray-300, #d9dbe1);
				}
			}
		}

		.info {
			display: flex;
			flex-direction: column;
			align-items: center;
			gap: 12px;
			justify-content: space-between;
			height: 100%;
			width: 100%;
			min-height: 245px;

			.mainInfoEnterBtn {
				width: 100%;
				display: flex;
				align-items: start;
				justify-content: space-between;

				.nameLocationId {
					width: 100%;
					display: flex;
					flex-direction: column;
					align-items: start;
					gap: 0;

					.name {
						// width: 50%;
						color: var(--Text-Gray-900, #000433);
						font-feature-settings: 'liga' off;
						/* Heading/H5 */
						font-family: Manrope;
						font-size: 20px;
						font-style: normal;
						font-weight: 700;
						line-height: 28px; /* 133.333% */
						word-wrap: normal; /* или overflow-wrap: break-word; */
					}

					.location {
						display: flex;
						align-items: center;
						gap: 4px;
						text-decoration: none;
						box-shadow: none;
						border: none;
						background: none;
						padding: 0;
						margin: 0;

						&:hover {
							text-decoration: underline;
							text-decoration-color: #8c30f5;
						}

						p {
							color: var(--Brand-Purple, #8c30f5);
							font-feature-settings: 'liga' off;
							/* Lead / Lead 2 */
							font-family: Manrope;
							font-size: 14px;
							font-style: normal;
							font-weight: 500;
							line-height: 24px; /* 171.429% */
							text-align: start;
						}
					}
				}

				.id {
					position: absolute;
					top: 5px;
					right: 5px;
					width: fit-content;
					display: flex;
					align-items: start;
					gap: 0;

					h4 {
						color: var(--Text-Gray-900, #969bab);
						font-feature-settings: 'liga' off;
						/* Heading/H6 */
						font-family: Manrope;
						font-size: 10px;
						font-style: normal;
						font-weight: 100;
						line-height: 10px; /* 150% */
						text-wrap: nowrap;
					}
				}

				/* .enterBtn {
					border-radius: 50%;
					padding: 9px;
					border-color: #d9dbe1 !important;
					box-shadow: none !important;
					background: none !important;
					transform: rotate(180deg);
				} */
			}

			.description {
				color: var(--Text-Gray-900, #000433);
				font-feature-settings: 'liga' off;
				/* Body / Body 2 */
				font-family: Manrope;
				font-size: 14px;
				font-style: normal;
				font-weight: 400;
				line-height: 24px; /* 171.429% */
				text-wrap: pretty;
			}

			.extraInfo {
				display: flex;
				align-items: center;
				justify-content: space-between;
				width: 100%;
				gap: 12px;

				/* .dates {
					display: flex;
					align-items: center;
					justify-content: space-between;
					width: 100%; */

				.createDate,
				.terminDate,
				.actualDate {
					display: flex;
					flex-direction: column;
					gap: 0;
					align-items: start;

					p {
						color: var(--Text-Gray-700, #969bab);
						font-feature-settings: 'liga' off, 'clig' off;
						font-family: Manrope;
						font-size: 12px;
						font-style: normal;
						font-weight: 400;
						line-height: 18px; /* 150% */
					}
				}

				.price {
					color: var(--Text-Gray-900, #000433);
					font-feature-settings: 'liga' off;
					/* Heading/H6 */
					font-family: Manrope;
					font-size: 20px;
					font-style: normal;
					font-weight: 600;
					line-height: 30px; /* 150% */
					text-wrap: nowrap;

					span {
						color: var(--Text-Gray-900, #000433);
						font-feature-settings: 'liga' off;
						/* Heading/H6 */
						font-family: Manrope;
						font-size: 15px;
						font-style: normal;
						font-weight: 600;
						line-height: 20px; /* 150% */
						text-wrap: nowrap;
					}
				}
				// }
			}
		}

		.googleMapLink {
			position: absolute;
			font-size: 12px;
			line-height: 12px;
			bottom: 5px;
			right: 5px;
			color: var(--Brand-Purple, #8c30f5);
			text-decoration: none;
			pointer-events: all;

			&:hover {
				text-decoration: underline;
				text-decoration-color: #8c30f5;
				pointer-events: all;
			}
		}
	}
}

.mobile {
	.objectCard {
		position: relative;
		padding: 20px;
		display: flex;
		flex-direction: column;
		align-items: center;
		gap: 12px;
		width: 350px;
		// height: 238px;

		* {
			margin: 0;
			padding: 0;
		}

		border-radius: 12px;
		background: var(--Default-White, #fff);
		/* Grey Shadow */
		// box-shadow: 0px 30px 40px 0px rgba(212, 217, 232, 0.2);

		&.terminated {
			position: relative;
			opacity: 0.5;
			/* &::before {
			position: absolute;
			content: '';
			width: 100%;
			height: 100%;
			top: 0;
			left: 0;
			background-color: rgba(255, 255, 255, 0.6);
			z-index: 1;
			pointer-events: none;
		} */

			.extraInfo {
				.datesFiles {
					.createDate {
						p {
							z-index: 1;
						}
					}

					.terminDate {
						p {
							color: #f5306b !important;
							z-index: 1;
						}
					}
				}
			}
		}

		.photosTags {
			display: flex;
			flex-direction: column;
			gap: 12px;

			.photos {
				width: 300px;
				height: 198px;
				position: relative;
				align-self: center;
				justify-self: center;

				&:hover {
					.slick-slider {
						.slick-prev,
						.slick-next {
							display: block !important;
						}
					}
				}

				.noPhoto {
					width: 300px;
					height: 198px;
					border-radius: 12px;
				}

				.logo {
					position: absolute;
					top: 83.5px;
					left: 90.615px;
					filter: brightness(0) saturate(100%) invert(11%) sepia(25%)
						saturate(7002%) hue-rotate(225deg) brightness(58%) contrast(115%);

					span {
						color: var(--Text-Gray-900, #000433);
						font-feature-settings: 'liga' off, 'clig' off;
						font-family: Caros;
						font-size: 18px;
						font-style: normal;
						font-weight: 700;
						line-height: 20px; /* 111.111% */
					}
				}

				.slick-slider {
					z-index: 0;
					margin: 0;
					width: 300px;
					height: 100%;
					position: relative;

					.slick-prev,
					.slick-next {
						position: absolute;
						background-image: url('../../../../assets/icons/chevron-right.svg');
						background-size: 32px 32px;
						background-position: center;
						background-repeat: no-repeat;
						padding: 16px;
						z-index: 1;
						display: none !important;

						&::before {
							display: none;
						}
					}

					.slick-prev {
						left: 12px;
						top: 80px;
						transform: rotate(180deg);
					}

					.slick-next {
						right: 12px;
					}

					.slick-list {
						height: 100%;
						border-radius: 12px !important;

						.slick-track {
							height: 100%;
							.slick-slide {
								height: 100%;
								div {
									height: 100%;
									width: 100%;
								}
								img {
									object-fit: cover;
									height: 100%;
									width: 100%;
								}
							}
						}
					}
				}

				/* .types {
					position: absolute;
					top: 15px;
					left: 15px;
					right: 15px;
					display: flex;
					align-items: center;
					gap: 8px;
					width: calc(100% - 87px);
					overflow-y: scroll;
					scrollbar-width: none;
					cursor: grab;
	
					&.grabbing {
						cursor: grabbing;
					}
				}
	
				.type {
					padding: 4px 12px;
					background-color: #23ce6b;
					border-radius: 5px;
					width: fit-content;
	
					p {
						color: var(--Default-White, #fff);
						font-feature-settings: 'liga' off, 'clig' off;
						font-family: Manrope;
						font-size: 12px;
						font-style: normal;
						font-weight: 700;
						line-height: normal;
						text-wrap: nowrap;
						pointer-events: none;
					}
				}
	
				.typeTooltip {
					background: rgb(255, 255, 255);
					color: #000433;
					border: 1px solid var(--Text-Gray-300, #d9dbe1);
					border-radius: 12px;
					font-size: 14px;
					font-weight: 600;
					font-family: 'Manrope', sans-serif;
					line-height: 20px;
					opacity: 1;
					z-index: 1;
	
					.react-tooltip-arrow {
						border-bottom: 1px solid var(--Text-Gray-300, #d9dbe1);
						border-right: 1px solid var(--Text-Gray-300, #d9dbe1);
					}
				}
	
				.favouriteBtn {
					padding: 8px;
					border-radius: 50%;
					background-color: #ffffff;
					border-color: #969bab2f;
					box-shadow: none;
					position: absolute;
					top: 12px;
					right: 12px;
	
					img {
						filter: brightness(0) saturate(100%) invert(11%) sepia(25%)
							saturate(7002%) hue-rotate(225deg) brightness(58%) contrast(115%);
					}
				} */
			}

			.types {
				// position: absolute;
				// top: 15px;
				// left: 15px;
				// right: 15px;
				width: 100%;
				display: flex;
				align-items: center;
				gap: 8px;
				// width: calc(100% - 87px);
				// overflow-y: scroll;
				// scrollbar-width: none;
				// cursor: grab;

				/* &.grabbing {
					cursor: grabbing;
				} */

				/* p {
					color: var(--Text-Gray-900, #000433);
					font-feature-settings: 'liga' off, 'clig' off;
					font-family: Manrope;
					font-size: 12px;
					font-style: normal;
					font-weight: 700;
					line-height: normal;
				} */

				.type {
					padding: 4px 12px;
					background-color: #23ce6b;
					border-radius: 5px;
					width: fit-content;

					p {
						color: var(--Default-White, #fff);
						font-feature-settings: 'liga' off, 'clig' off;
						font-family: Manrope;
						font-size: 12px;
						font-style: normal;
						font-weight: 700;
						line-height: normal;
						text-wrap: nowrap;
						// pointer-events: none;
					}
				}
			}

			.typeTooltip {
				padding: 8px 16px;
				background: rgb(255, 255, 255);
				color: #000433;
				border: 1px solid var(--Text-Gray-300, #d9dbe1);
				border-radius: 12px;
				padding: 4px;
				font-size: 14px;
				font-weight: 600;
				font-family: 'Manrope', sans-serif;
				line-height: 20px;
				opacity: 1;
				z-index: 1;

				.react-tooltip-arrow {
					border-bottom: 1px solid var(--Text-Gray-300, #d9dbe1);
					border-right: 1px solid var(--Text-Gray-300, #d9dbe1);
				}
			}
		}

		.info {
			display: flex;
			flex-direction: column;
			align-items: start;
			gap: 12px;
			height: 100%;

			.mainInfoEnterBtn {
				display: flex;
				flex-direction: row;
				align-items: start;
				justify-content: space-between;
				gap: 12px;

				.nameLocationId {
					display: flex;
					flex-direction: column;
					align-items: start;
					gap: 0;

					.name {
						color: var(--Text-Gray-900, #000433);
						font-feature-settings: 'liga' off;
						/* Heading/H5 */
						font-family: Manrope;
						font-size: 24px;
						font-style: normal;
						font-weight: 700;
						line-height: 32px; /* 133.333% */
					}

					.location {
						display: flex;
						align-items: center;
						gap: 4px;
						text-decoration: none;
						box-shadow: none;
						border: none;
						background: none;

						&:hover {
							text-decoration: underline;
							text-decoration-color: #8c30f5;
						}

						p {
							color: var(--Brand-Purple, #8c30f5);
							font-feature-settings: 'liga' off;
							/* Lead / Lead 2 */
							font-family: Manrope;
							font-size: 14px;
							font-style: normal;
							font-weight: 500;
							line-height: 24px; /* 171.429% */
							text-align: start;
						}
					}
				}

				.id {
					position: absolute;
					top: 5px;
					right: 5px;
					width: fit-content;
					display: flex;
					align-items: start;
					gap: 0;

					h4 {
						color: var(--Text-Gray-900, #969bab);
						font-feature-settings: 'liga' off;
						/* Heading/H6 */
						font-family: Manrope;
						font-size: 10px;
						font-style: normal;
						font-weight: 100;
						line-height: 10px; /* 150% */
						text-wrap: nowrap;
					}
				}

				/* .enterBtn {
					border-radius: 50%;
					padding: 4.5px;
					border-color: #d9dbe1 !important;
					box-shadow: none !important;
					background: none !important;
					transform: rotate(180deg);

					img {
						width: 24px;
						height: 24px;
					}
				} */
			}

			.description {
				color: var(--Text-Gray-900, #000433);
				font-feature-settings: 'liga' off;
				/* Body / Body 2 */
				font-family: Manrope;
				font-size: 14px;
				font-style: normal;
				font-weight: 400;
				line-height: 24px; /* 171.429% */
				text-wrap: pretty;
			}

			.extraInfo {
				display: flex;
				align-items: center;
				width: 100%;
				justify-content: space-between;

				/* .datesFiles {
					display: flex;
					align-items: center;
					justify-content: space-between; */

				.createDate,
				.terminDate,
				.actualDate {
					display: flex;
					flex-direction: column;
					gap: 0;
					align-items: start;

					p {
						color: var(--Text-Gray-700, #969bab);
						font-feature-settings: 'liga' off, 'clig' off;
						font-family: Manrope;
						font-size: 12px;
						font-style: normal;
						font-weight: 400;
						line-height: 18px; /* 150% */
					}
				}

				.price {
					color: var(--Text-Gray-900, #000433);
					font-feature-settings: 'liga' off;
					/* Heading/H6 */
					font-family: Manrope;
					font-size: 20px;
					font-style: normal;
					font-weight: 600;
					line-height: 30px; /* 150% */
					text-wrap: nowrap;

					span {
						color: var(--Text-Gray-900, #000433);
						font-feature-settings: 'liga' off;
						/* Heading/H6 */
						font-family: Manrope;
						font-size: 15px;
						font-style: normal;
						font-weight: 600;
						line-height: 20px; /* 150% */
						text-wrap: nowrap;
					}
				}
				// }
			}

			.metaInfo {
				display: flex;
				width: 100%;
				align-items: center;
				justify-content: space-between;

				.id {
					display: flex;
					align-items: start;
					gap: 0;

					h4 {
						color: var(--Text-Gray-900, #000433);
						font-feature-settings: 'liga' off;
						/* Heading/H6 */
						font-family: Manrope;
						font-size: 20px;
						font-style: normal;
						font-weight: 600;
						line-height: 30px; /* 150% */
						text-wrap: nowrap;
					}
				}

				.files {
					background: none;
					box-shadow: none;
					border: none;
					position: relative;
					padding: 0;
					margin: 0;

					.filesCount {
						position: absolute;
						padding: 0px 6px;
						background-color: #8c30f5;
						border-radius: 50%;
						top: -3px;
						left: -4px;

						color: var(--Default-White, #fff);
						font-family: Manrope;
						font-size: 11px;
						font-style: normal;
						font-weight: 600;
						line-height: normal;
					}
				}
			}
		}
	}
}
