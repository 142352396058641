.fileBtn {
	position: relative;
	padding: 12px 24px 12px 12px !important;
	display: flex !important;
	align-items: center;
	gap: 4px;

	background: var(--Brand-Purple-100, #f1e4ff) !important;
	border-color: #f1e4ff !important;
	border-radius: 12px !important;

	p {
		color: var(--Text-Gray-900, #000433);
		font-feature-settings: 'liga' off;
		/* Body / Body 2 */
		font-family: Manrope;
		font-size: 14px;
		font-style: normal;
		font-weight: 400;
		line-height: 24px; /* 171.429% */
	}

	.fileWeight {
		position: absolute;
		right: 12px;
		top: -7px;
		padding: 0 8px;
		border-radius: 24px;
		background: var(--Brand-Purple-800, #d6b1ff);

		color: var(--Default-White, #fff);
		font-feature-settings: 'liga' off;
		font-family: Manrope;
		font-size: 12px;
		font-style: normal;
		font-weight: 700;
		line-height: normal;
	}
}
