.desktop {
	.mainPage {
		position: relative;

		.landSelector,
		.citySelector,
		.typeSelector,
		.terminDateSelector {
			width: 100%;
		}

		.landSelector__control,
		.typeSelector__control,
		.citySelector__control,
		.sortBySelector__control,
		.terminDateSelector__control {
			border-radius: 8px;
			padding: 8px 8px 8px 16px;
			display: flex;
			gap: 32px;
			align-items: center;
			border: 1px solid #d9dbe1;
			box-shadow: none;

			&:focus,
			&:active,
			&:hover {
				border: 1px solid #8c30f5;
				box-shadow: none;
			}

			.landSelector__value-container,
			.typeSelector__value-container,
			.citySelector__value-container,
			.sortBySelector__value-container,
			.terminDateSelector__value-container {
				padding: 0;
				overflow-x: auto;
				flex-wrap: nowrap;
				scrollbar-width: thin; /* Hide scrollbar in Firefox */
				scrollbar-color: #d9dbe1 transparent;
				scroll-behavior: smooth;

				display: flex;
				align-items: center;

				.prefix {
					color: var(--Text-Gray-800, #474a57);
					font-family: Manrope;
					font-size: 14px;
					font-style: normal;
					font-weight: 500;
					line-height: 24px; /* 171.429% */
				}

				.landSelector__placeholder,
				.typeSelector__placeholder,
				.citySelector__placeholder,
				.sortBySelector__placeholder,
				.terminDateSelector__placeholder {
					color: var(--Text-Gray-900, #000433);
					font-family: Manrope;
					font-size: 14px;
					font-style: normal;
					font-weight: 500;
					line-height: 24px; /* 171.429% */
				}

				.landSelector__multi-value,
				.typeSelector__multi-value,
				.citySelector__multi-value,
				.terminDateSelector__multi-value {
					min-width: fit-content;
				}

				.sortBySelector__control__single-value {
					margin: 0;

					.prefix {
						color: var(--Text-Gray-700, #969bab);
						font-family: Manrope;
						font-size: 14px;
						font-style: normal;
						font-weight: 500;
						line-height: 24px; // 171.429%
					}

					.selectedOption {
						color: var(--Text-Gray-900, #000433);
						font-family: Manrope;
						font-size: 14px;
						font-style: normal;
						font-weight: 500;
						line-height: 24px; // 171.429%
					}
				}
			}
		}

		.landSelector__menu,
		.typeSelector__menu,
		.citySelector__menu,
		.sortBySelector__menu,
		.terminDateSelector__menu {
			margin: 12px 0;

			.landSelector__menu-list,
			.typeSelector__menu-list,
			.citySelector__menu-list,
			.sortBySelector__menu-list,
			.terminDateSelector__menu-list {
				padding: 14px 0 !important;

				.landSelector__option,
				.typeSelector__option,
				.citySelector__option,
				.sortBySelector__option,
				.terminDateSelector__option {
					color: var(--Text-Gray-900, #000433);
					font-feature-settings: 'liga' off;
					/* Body / Body 2 */
					font-family: Manrope;
					font-size: 14px;
					font-style: normal;
					font-weight: 400;
					line-height: 24px; /* 171.429% */
					text-wrap: pretty;

					&:hover,
					&:active,
					&:focus {
						background-color: #f1e4ff;
						color: var(--Brand-Purple, #8c30f5);
						font-feature-settings: 'liga' off;
						/* Body / Body 2 */
						font-family: Manrope;
						font-size: 14px;
						font-style: normal;
						font-weight: 400;
						line-height: 24px; /* 171.429% */
					}

					.selectMultiOption {
						display: flex;
						align-items: center;
						justify-content: space-between;
						gap: 4px;

						.optionCheckbox {
							width: 24px;
							height: 24px;
							border: 2px solid #d9dbe1;
							border-radius: 4px;
							flex-shrink: 0;

							&.checked {
								background-color: #8c30f5;
								border-color: #8c30f5;
								// background-image: url('../../assets/icons/Line.svg');
								box-shadow: none;
								position: relative;
								background-image: none;

								&::before {
									content: '';
									position: absolute;
									top: 0;
									left: 0;
									width: 100%;
									height: 100%;
									background-image: url('../../assets/icons/Line.svg');
									background-size: cover;
									background-repeat: no-repeat;
									filter: brightness(0) saturate(100%) invert(100%) sepia(0%)
										saturate(0%) hue-rotate(182deg) brightness(106%)
										contrast(101%);
								}
							}
						}
					}
				}

				.landSelector__option--is-focused,
				.typeSelector__option--is-focused,
				.citySelector__option--is-focused,
				.terminDateSelector__option--is-focused {
					background-color: #f1e4ff !important;
					color: var(--Brand-Purple, #8c30f5) !important;

					.optionCheckbox {
						&:not(.checked) {
							border-color: white !important;
						}
					}
				}

				.landSelector__option--is-selected,
				.typeSelector__option--is-selected,
				.citySelector__option--is-selected,
				.terminDateSelector__option--is-selected {
					background-color: white;
					// color: var(--Brand-Purple, #8c30f5);
				}

				.sortBySelector__option--is-focused {
					background-color: #f1e4ff;
					color: var(--Brand-Purple, #8c30f5);
				}

				.sortBySelector__option--is-selected {
					background-color: #f1e4ff;
					color: var(--Brand-Purple, #8c30f5);
				}
			}
		}

		.landSelector__indicator-separator,
		.typeSelector__indicator-separator,
		.citySelector__indicator-separator,
		.sortBySelector__indicator-separator,
		.terminDateSelector__indicator-separator {
			display: none;
		}

		//__control--is-focused

		.landSelector__dropdown-indicator,
		.typeSelector__dropdown-indicator,
		.citySelector__dropdown-indicator,
		.sortBySelector__dropdown-indicator {
			border: 1px solid #d9dbe1;
			border-radius: 50%;
			padding: 4px;
			margin: 0;
		}

		.mainContent {
			display: flex;
			padding-top: 128px;
			padding-bottom: 128px;
			align-items: start;
			gap: 32px;

			.filtersMap {
				display: flex;
				flex-direction: column;
				gap: 32px;
				align-items: start;
				width: 310px;
				padding: 32px 0 0 0 !important;

				.toTop {
					align-self: center;
					width: fit-content;
					text-wrap: nowrap;

					background: none;
					border: none;

					color: var(--Brand-Purple, #8c30f5);
					font-feature-settings: 'liga' off;
					font-family: Manrope;
					font-size: 16px;
					font-style: normal;
					font-weight: 700;
					line-height: 24px;
				}
			}

			.objectsControl {
				display: flex;
				flex-direction: column;
				gap: 12px;
				align-items: start;

				.totalNumber {
					p {
						color: var(--Text-Gray-900, #000433);
						font-family: Manrope;
						font-size: 16px;
						font-style: normal;
						font-weight: 500;
						line-height: 24px;
					}
				}

				.upperControlsMeta {
					display: flex;
					align-items: center;
					justify-content: space-between;
					gap: 12px;
					width: 100%;

					/* .totalPagination {
						display: flex;
						flex-direction: column;
						align-items: start;
						gap: 0;

						p {
							color: var(--Text-Gray-900, #000433);
							font-family: Manrope;
							font-size: 16px;
							font-style: normal;
							font-weight: 500;
							line-height: 24px;
						}
					} */

					.sortFavsLayout {
						display: flex;
						align-items: center;
						gap: 16px;

						.favourites {
							padding: 0;
							border: none;
							background: none;
							box-shadow: none;
							position: relative;

							img {
								width: 24px;
								height: 24px;
							}

							.favouritesCount {
								position: absolute;
								padding: 0 4px;
								background-color: #00ffd0;
								border-radius: 50%;
								top: -2px;
								right: -6px;

								color: var(--Text-Gray-900, #000433);
								font-family: Manrope;
								font-size: 10px;
								font-style: normal;
								font-weight: 700;
								line-height: normal;
							}
						}

						.layoutBtns {
							display: flex;
							align-items: center;
							gap: 12px;

							.stringsBtn,
							.squaresBtn {
								padding: 10px;
								border: none;
								background: none;
								box-shadow: none;

								border-radius: 8px;
								border: 1px solid var(--Text-Gray-300, #d9dbe1);
								background: var(--Default-White, #fff);

								img {
									width: 24px;
									height: 24px;
								}
							}
						}
					}
				}

				.objects {
					display: flex;
					flex-direction: column;
					align-items: center;
					gap: 24px;
				}

				.lowerControls {
					display: flex;
					flex-direction: column;
					align-items: center;
					gap: 16px;
					align-self: center;
					padding-top: 30px;

					p {
						color: var(--Text-Gray-900, #000433);
						font-feature-settings: 'liga' off;
						/* Body / Body 2 */
						font-family: Manrope;
						font-size: 14px;
						font-style: normal;
						font-weight: 400;
						line-height: 24px; /* 171.429% */
					}
				}
			}
		}
	}
}

.tablet {
	.mainPage {
		position: relative;

		.landSelector,
		.citySelector {
			width: 100%;
			max-width: 40%;
		}

		.typeSelector {
			width: 100%;
		}

		.landSelector__control,
		.typeSelector__control,
		.citySelector__control,
		.sortBySelector__control {
			border-radius: 8px;
			padding: 8px 8px 8px 16px;
			display: flex;
			gap: 32px;
			align-items: center;
			border: 1px solid #d9dbe1;
			box-shadow: none;

			&:focus,
			&:active,
			&:hover {
				border: 1px solid #8c30f5;
				box-shadow: none;
			}

			.landSelector__value-container,
			.typeSelector__value-container,
			.citySelector__value-container,
			.sortBySelector__value-container {
				padding: 0;
				overflow-x: auto;
				flex-wrap: nowrap;
				scrollbar-width: thin; /* Hide scrollbar in Firefox */
				scrollbar-color: #d9dbe1 transparent;
				scroll-behavior: smooth;

				display: flex;
				align-items: center;

				.prefix {
					color: var(--Text-Gray-800, #474a57);
					font-family: Manrope;
					font-size: 14px;
					font-style: normal;
					font-weight: 500;
					line-height: 24px; /* 171.429% */
				}

				.landSelector__placeholder,
				.typeSelector__placeholder,
				.citySelector__placeholder,
				.sortBySelector__placeholder {
					color: var(--Text-Gray-900, #000433);
					font-family: Manrope;
					font-size: 14px;
					font-style: normal;
					font-weight: 500;
					line-height: 24px; /* 171.429% */
				}

				.landSelector__multi-value,
				.typeSelector__multi-value,
				.citySelector__multi-value {
					min-width: fit-content;
				}

				.sortBySelector__control__single-value {
					margin: 0;

					.prefix {
						color: var(--Text-Gray-700, #969bab);
						font-family: Manrope;
						font-size: 14px;
						font-style: normal;
						font-weight: 500;
						line-height: 24px; // 171.429%
					}

					.selectedOption {
						color: var(--Text-Gray-900, #000433);
						font-family: Manrope;
						font-size: 14px;
						font-style: normal;
						font-weight: 500;
						line-height: 24px; // 171.429%
					}
				}
			}
		}

		.landSelector__menu,
		.typeSelector__menu,
		.citySelector__menu,
		.sortBySelector__menu {
			margin: 12px 0;

			.landSelector__menu-list,
			.typeSelector__menu-list,
			.citySelector__menu-list,
			.sortBySelector__menu-list {
				padding: 14px 0 !important;

				.landSelector__option,
				.typeSelector__option,
				.citySelector__option,
				.sortBySelector__option {
					color: var(--Text-Gray-900, #000433);
					font-feature-settings: 'liga' off;
					/* Body / Body 2 */
					font-family: Manrope;
					font-size: 14px;
					font-style: normal;
					font-weight: 400;
					line-height: 24px; /* 171.429% */
					text-wrap: pretty;

					&:hover,
					&:active,
					&:focus {
						background-color: #f1e4ff;
						color: var(--Brand-Purple, #8c30f5);
						font-feature-settings: 'liga' off;
						/* Body / Body 2 */
						font-family: Manrope;
						font-size: 14px;
						font-style: normal;
						font-weight: 400;
						line-height: 24px; /* 171.429% */
					}

					.selectMultiOption {
						display: flex;
						align-items: center;
						justify-content: space-between;
						gap: 4px;

						.optionCheckbox {
							width: 24px;
							height: 24px;
							border: 2px solid #d9dbe1;
							border-radius: 4px;
							flex-shrink: 0;

							&.checked {
								background-color: #8c30f5;
								border-color: #8c30f5;
								// background-image: url('../../assets/icons/Line.svg');
								box-shadow: none;
								position: relative;
								background-image: none;

								&::before {
									content: '';
									position: absolute;
									top: 0;
									left: 0;
									width: 100%;
									height: 100%;
									background-image: url('../../assets/icons/Line.svg');
									background-size: cover;
									background-repeat: no-repeat;
									filter: brightness(0) saturate(100%) invert(100%) sepia(0%)
										saturate(0%) hue-rotate(182deg) brightness(106%)
										contrast(101%);
								}
							}
						}
					}
				}

				.landSelector__option--is-focused,
				.typeSelector__option--is-focused,
				.citySelector__option--is-focused {
					background-color: #f1e4ff !important;
					color: var(--Brand-Purple, #8c30f5) !important;

					.optionCheckbox {
						&:not(.checked) {
							border-color: white !important;
						}
					}
				}

				.landSelector__option--is-selected,
				.typeSelector__option--is-selected,
				.citySelector__option--is-selected {
					background-color: white;
					// color: var(--Brand-Purple, #8c30f5);
				}

				.sortBySelector__option--is-focused {
					background-color: #f1e4ff;
					color: var(--Brand-Purple, #8c30f5);
				}

				.sortBySelector__option--is-selected {
					background-color: #f1e4ff;
					color: var(--Brand-Purple, #8c30f5);
				}
			}
		}

		.landSelector__indicator-separator,
		.typeSelector__indicator-separator,
		.citySelector__indicator-separator,
		.sortBySelector__indicator-separator {
			display: none;
		}

		//__control--is-focused

		.landSelector__dropdown-indicator,
		.typeSelector__dropdown-indicator,
		.citySelector__dropdown-indicator,
		.sortBySelector__dropdown-indicator {
			border: 1px solid #d9dbe1;
			border-radius: 50%;
			padding: 4px;
			margin: 0;
		}

		.mainContent {
			display: flex;
			flex-direction: column;
			padding-top: 54px;
			padding-bottom: 100px;
			align-items: center;
			gap: 42px;

			.filtersMap {
				padding: 16px 0 10px 0 !important;
				display: flex;
				gap: 29px;
				align-items: center;
				justify-content: center;
				width: 100%;

				.openFiltersBtn {
					padding: 12px 42px;
					width: 431px;
					height: 72px;
					display: flex;
					align-items: center;
					justify-content: center;
					gap: 10px;

					border-radius: 8px;
					background: var(--Brand-Purple-100, #f1e4ff);
					border-color: #f1e4ff;

					p {
						color: var(--Brand-Purple, #8c30f5);
						text-align: center;
						font-feature-settings: 'liga' off;
						/* Label/Large Label */
						font-family: Manrope;
						font-size: 20px;
						font-style: normal;
						font-weight: 700;
						line-height: 30px; /* 150% */
						text-wrap: nowrap;
					}
				}
			}

			.objectsControl {
				display: flex;
				flex-direction: column;
				gap: 12px;
				align-items: center;

				.totalNumber {
					align-self: start;
					p {
						color: var(--Text-Gray-900, #000433);
						font-family: Manrope;
						font-size: 16px;
						font-style: normal;
						font-weight: 500;
						line-height: 24px;
					}
				}

				.upperControlsMeta {
					display: flex;
					align-items: center;
					justify-content: space-between;
					gap: 12px;
					width: 100%;

					/* .totalPagination {
						display: flex;
						flex-direction: column;
						align-items: start;
						gap: 0;

						p {
							color: var(--Text-Gray-900, #000433);
							font-family: Manrope;
							font-size: 16px;
							font-style: normal;
							font-weight: 500;
							line-height: 24px;
						}
					} */

					.sortFavsLayout {
						display: flex;
						align-items: center;
						gap: 16px;

						.favourites {
							padding: 0;
							border: none;
							background: none;
							box-shadow: none;
							position: relative;

							img {
								width: 24px;
								height: 24px;
							}

							.favouritesCount {
								position: absolute;
								padding: 0 4px;
								background-color: #00ffd0;
								border-radius: 50%;
								top: -2px;
								right: -6px;

								color: var(--Text-Gray-900, #000433);
								font-family: Manrope;
								font-size: 10px;
								font-style: normal;
								font-weight: 700;
								line-height: normal;
							}
						}

						.layoutBtns {
							display: flex;
							align-items: center;
							gap: 12px;

							.stringsBtn,
							.squaresBtn {
								padding: 10px;
								border: none;
								background: none;
								box-shadow: none;

								border-radius: 8px;
								border: 1px solid var(--Text-Gray-300, #d9dbe1);
								background: var(--Default-White, #fff);

								img {
									width: 24px;
									height: 24px;
								}
							}
						}
					}
				}

				.objects {
					display: flex;
					flex-direction: column;
					align-items: center;
					gap: 24px;
				}

				.lowerControls {
					display: flex;
					flex-direction: column;
					align-items: center;
					gap: 16px;
					align-self: center;
					padding-top: 30px;

					p {
						color: var(--Text-Gray-900, #000433);
						font-feature-settings: 'liga' off;
						/* Body / Body 2 */
						font-family: Manrope;
						font-size: 14px;
						font-style: normal;
						font-weight: 400;
						line-height: 24px; /* 171.429% */
					}
				}
			}
		}
	}
}

.mobile {
	.mainPage {
		position: relative;

		.landSelector,
		.citySelector {
			width: 100%;
		}

		.sortBySelector {
			width: 100%;
		}

		.landSelector__control,
		.typeSelector__control,
		.citySelector__control,
		.sortBySelector__control {
			border-radius: 8px;
			padding: 8px 8px 8px 16px;
			display: flex;
			gap: 32px;
			align-items: center;
			border: 1px solid #d9dbe1;
			box-shadow: none;

			&:focus,
			&:active,
			&:hover {
				border: 1px solid #8c30f5;
				box-shadow: none;
			}

			.landSelector__value-container,
			.typeSelector__value-container,
			.citySelector__value-container,
			.sortBySelector__value-container {
				padding: 0;
				overflow-x: auto;
				flex-wrap: nowrap;
				scrollbar-width: thin; /* Hide scrollbar in Firefox */
				scrollbar-color: #d9dbe1 transparent;
				scroll-behavior: smooth;

				display: flex;
				align-items: center;

				.prefix {
					color: var(--Text-Gray-800, #474a57);
					font-family: Manrope;
					font-size: 14px;
					font-style: normal;
					font-weight: 500;
					line-height: 24px; /* 171.429% */
				}

				.landSelector__placeholder,
				.typeSelector__placeholder,
				.citySelector__placeholder,
				.sortBySelector__placeholder {
					color: var(--Text-Gray-900, #000433);
					font-family: Manrope;
					font-size: 14px;
					font-style: normal;
					font-weight: 500;
					line-height: 24px; /* 171.429% */
				}

				.landSelector__multi-value,
				.typeSelector__multi-value,
				.citySelector__multi-value {
					min-width: fit-content;
				}

				.sortBySelector__control__single-value {
					margin: 0;

					.prefix {
						color: var(--Text-Gray-700, #969bab);
						font-family: Manrope;
						font-size: 14px;
						font-style: normal;
						font-weight: 500;
						line-height: 24px; // 171.429%
					}

					.selectedOption {
						color: var(--Text-Gray-900, #000433);
						font-family: Manrope;
						font-size: 14px;
						font-style: normal;
						font-weight: 500;
						line-height: 24px; // 171.429%
					}
				}
			}
		}

		.landSelector__menu,
		.typeSelector__menu,
		.citySelector__menu,
		.sortBySelector__menu {
			margin: 12px 0;

			.landSelector__menu-list,
			.typeSelector__menu-list,
			.citySelector__menu-list,
			.sortBySelector__menu-list {
				padding: 14px 0 !important;

				.landSelector__option,
				.typeSelector__option,
				.citySelector__option,
				.sortBySelector__option {
					color: var(--Text-Gray-900, #000433);
					font-feature-settings: 'liga' off;
					/* Body / Body 2 */
					font-family: Manrope;
					font-size: 14px;
					font-style: normal;
					font-weight: 400;
					line-height: 24px; /* 171.429% */
					text-wrap: pretty;

					&:hover,
					&:active,
					&:focus {
						background-color: #f1e4ff;
						color: var(--Brand-Purple, #8c30f5);
						font-feature-settings: 'liga' off;
						/* Body / Body 2 */
						font-family: Manrope;
						font-size: 14px;
						font-style: normal;
						font-weight: 400;
						line-height: 24px; /* 171.429% */
					}

					.selectMultiOption {
						display: flex;
						align-items: center;
						justify-content: space-between;
						gap: 4px;

						.optionCheckbox {
							width: 24px;
							height: 24px;
							border: 2px solid #d9dbe1;
							border-radius: 4px;
							flex-shrink: 0;

							&.checked {
								background-color: #8c30f5;
								border-color: #8c30f5;
								// background-image: url('../../assets/icons/Line.svg');
								box-shadow: none;
								position: relative;
								background-image: none;

								&::before {
									content: '';
									position: absolute;
									top: 0;
									left: 0;
									width: 100%;
									height: 100%;
									background-image: url('../../assets/icons/Line.svg');
									background-size: cover;
									background-repeat: no-repeat;
									filter: brightness(0) saturate(100%) invert(100%) sepia(0%)
										saturate(0%) hue-rotate(182deg) brightness(106%)
										contrast(101%);
								}
							}
						}
					}
				}

				.landSelector__option--is-focused,
				.typeSelector__option--is-focused,
				.citySelector__option--is-focused {
					background-color: #f1e4ff !important;
					color: var(--Brand-Purple, #8c30f5) !important;

					.optionCheckbox {
						&:not(.checked) {
							border-color: white !important;
						}
					}
				}

				.landSelector__option--is-selected,
				.typeSelector__option--is-selected,
				.citySelector__option--is-selected {
					background-color: white;
					// color: var(--Brand-Purple, #8c30f5);
				}

				.sortBySelector__option--is-focused {
					background-color: #f1e4ff;
					color: var(--Brand-Purple, #8c30f5);
				}

				.sortBySelector__option--is-selected {
					background-color: #f1e4ff;
					color: var(--Brand-Purple, #8c30f5);
				}
			}
		}

		.landSelector__indicator-separator,
		.typeSelector__indicator-separator,
		.citySelector__indicator-separator,
		.sortBySelector__indicator-separator {
			display: none;
		}

		//__control--is-focused

		.landSelector__dropdown-indicator,
		.typeSelector__dropdown-indicator,
		.citySelector__dropdown-indicator,
		.sortBySelector__dropdown-indicator {
			border: 1px solid #d9dbe1;
			border-radius: 50%;
			padding: 4px;
			margin: 0;
		}

		.mainContent {
			display: flex;
			flex-direction: column;
			padding-top: 94px;
			padding-bottom: 99px;
			align-items: center;
			justify-content: space-between;
			gap: 42px;

			.filtersMap {
				display: flex;
				flex-direction: column;
				gap: 18px;
				align-items: center;
				justify-content: center;
				width: 100%;
				margin-bottom: 10px;

				.openFiltersBtn {
					padding: 12px 42px;
					height: 72px;
					width: 100%;
					display: flex;
					align-items: center;
					justify-content: center;
					gap: 10px;

					border-radius: 8px;
					background: var(--Brand-Purple-100, #f1e4ff);
					border-color: #f1e4ff;

					p {
						color: var(--Brand-Purple, #8c30f5);
						text-align: center;
						font-feature-settings: 'liga' off;
						/* Label/Large Label */
						font-family: Manrope;
						font-size: 20px;
						font-style: normal;
						font-weight: 700;
						line-height: 30px; /* 150% */
						text-wrap: nowrap;
					}
				}
			}

			.objectsControl {
				display: flex;
				flex-direction: column;
				gap: 16px;
				align-items: center;
				width: 100%;

				.totalNumber {
					p {
						color: var(--Text-Gray-900, #000433);
						font-family: Manrope;
						font-size: 16px;
						font-style: normal;
						font-weight: 500;
						line-height: 24px;
					}
				}

				.upperControlsMeta {
					display: flex;
					flex-direction: column;
					align-items: center;
					justify-content: space-between;
					gap: 16px;
					width: 100%;
					margin-bottom: 8px;

					/* .totalPagination {
						display: flex;
						flex-direction: column;
						align-items: center;
						gap: 0;
						width: 100%;

						p {
							color: var(--Text-Gray-900, #000433);
							font-family: Manrope;
							font-size: 16px;
							font-style: normal;
							font-weight: 500;
							line-height: 24px;
						}
					} */

					.sortFavsLayout {
						padding: 0 12px;
						display: flex;
						align-items: center;
						gap: 24px;
						width: 100%;

						.favourites {
							padding: 0;
							border: none;
							background: none;
							box-shadow: none;
							position: relative;

							img {
								width: 24px;
								height: 24px;
							}

							.favouritesCount {
								position: absolute;
								padding: 0 4px;
								background-color: #00ffd0;
								border-radius: 50%;
								top: -2px;
								right: -6px;

								color: var(--Text-Gray-900, #000433);
								font-family: Manrope;
								font-size: 10px;
								font-style: normal;
								font-weight: 700;
								line-height: normal;
							}
						}
					}
				}

				.objects {
					display: flex;
					flex-direction: column;
					align-items: center;
					gap: 16px;
				}

				.lowerControls {
					display: flex;
					flex-direction: column;
					align-items: center;
					gap: 16px;
					align-self: center;

					p {
						color: var(--Text-Gray-900, #000433);
						font-feature-settings: 'liga' off;
						/* Body / Body 2 */
						font-family: Manrope;
						font-size: 14px;
						font-style: normal;
						font-weight: 400;
						line-height: 24px; /* 171.429% */
					}
				}
			}
		}
	}
}
