.App {
	p,
	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		margin: 0;
	}

	&.desktop {
		max-width: 100%;
		margin: 0 auto;

		.landingBlock,
		.serviceBlock {
			width: 1440px;
			padding-left: 165px;
			padding-right: 165px;
			margin: 0 auto;
			max-width: 100%;
		}
	}

	&.tablet {
		max-width: 100%;
		margin: 0 auto;

		.landingBlock,
		.serviceBlock {
			width: 1440px;
			padding-left: 32px;
			padding-right: 32px;
			margin: 0 auto;
			max-width: 100%;
		}
	}

	&.mobile {
		overflow-x: hidden;

		.landingBlock,
		.serviceBlock {
			// padding-left: 5.3332vw;
			// padding-right: 5.3332vw;
			padding-left: 16px;
			padding-right: 16px;
		}
	}

	.errorText {
		color: var(--Text-Gray-900, #000433);
		text-align: left;
		font-feature-settings: 'liga' off;
		font-family: Manrope;
		font-size: 48px;
		font-style: normal;
		font-weight: 700;
		line-height: 56px;
		padding: 12px;
	}
}

/* .innerLandingBG {
	position: absolute;
	background: linear-gradient(
		194deg,
		#d9e8ef 19.2%,
		#eaf2f5 89.64%
	); Текущий градиент

	width: 100%;
	z-index: -1;
	left: 0;
	top: 0;

	min-height: 100vh;
	display: flex;
	flex-direction: column;
}

.historyBackgroundWrapper {
	width: 100%;
	position: absolute;

	&.desktop {
		top: 1593px;
		background: url('../../../public/History.png') no-repeat;
		background-size: cover;
		background-position: center;
		width: 100%;
		height: 763px;
		position: absolute;
		filter: grayscale(0.9);

		@media (max-width: 1940px) {
			background-size: 110vw 100%;
		}

		.bottomLinearBg {
			background: linear-gradient(
				180deg,
				rgba(244, 245, 247, 0) 65%,
				white 100%
			);
			width: 100%;
			height: 252px;
			bottom: 0;
			position: absolute;
		}
	}

	&.mobile {
		.betweenLiteCoin {
			display: none;
		}
	}

	.betweenLiteCoin {
		width: 170px;
		height: 170px;
		position: absolute;
		transform: translateY(-80px);
		z-index: 100;
	}
} */

/* .footerBackgroundWrapper {
	position: absolute;

	.betweenEtherium {
		display: none;
	}

	&.desktop {
		width: 100%;
		background-color: #0b0d17;
		height: 492px;

		.betweenEtherium {
			display: none; // ???????????????
			position: absolute;
			top: -130px;
			right: 0;
		}
	}
} */
