.desktop {
	.footer {
		padding: 86px 165px 64px 165px;
		display: flex;
		flex-direction: column;
		gap: 32px;
		align-items: center;
		background-color: black;

		.logoLines {
			display: flex;
			align-items: center;
			justify-content: center;
			width: 100%;

			hr {
				width: 476px;
				height: 1px;
			}

			.logo {
				padding: 0px 20.5px;
			}
		}
	}
}

.tablet {
	.footer {
		padding: 57px 0px 64px 0px;
		display: flex;
		flex-direction: column;
		gap: 32px;
		align-items: center;
		background-color: black;

		.logoLines {
			display: flex;
			align-items: center;
			justify-content: center;
			width: 100%;

			hr {
				width: 476px;
				height: 1px;
			}

			.logo {
				padding: 0px 20.5px;
			}
		}
	}
}

.mobile {
	.footer {
		padding: 57px 0px 64px 0px;
		display: flex;
		flex-direction: column;
		gap: 32px;
		align-items: center;
		background-color: black;

		.logoLines {
			display: flex;
			align-items: center;
			justify-content: center;
			width: 100%;

			hr {
				width: 476px;
				height: 1px;
			}

			.logo {
				padding: 0px 20.5px;
			}
		}
	}
}
