.modalError {
	width: 100%;
	min-width: 360px;
	background-color: #fae1e1;
	border-radius: 5px;
	margin: 0;
	padding: 12px 4px;

	color: #d94040;
	border-left: 8px solid var(--Accent-Peach, #f39f9f);

	text-align: center;
	font-feature-settings:
		'liga' off,
		'clig' off;
	/* Label / Medium Label */
	font-family: Manrope;
	font-size: 14px;
	font-style: normal;
	font-weight: 700;
	line-height: 20px; /* 142.857% */

	@media (max-width: 1000px) {
		min-width: 290px;
	}
}

.modalSuccess {
	width: 100%;
	min-width: 360px;
	background-color: #e2f9f2;
	border-radius: 5px;
	margin: 0;
	padding: 12px 4px;

	color: #13dfba;
	border-left: 8px solid var(--Brand-Turquoise, #00ffd0);

	text-align: center;
	font-feature-settings:
		'liga' off,
		'clig' off;
	/* Label / Medium Label */
	font-family: Manrope;
	font-size: 14px;
	font-style: normal;
	font-weight: 700;
	line-height: 20px; /* 142.857% */

	@media (max-width: 1000px) {
		min-width: 290px;
	}
}
