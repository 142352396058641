//////////////////////////////////////////////////// BASE ///////////////////////////////////////////////////////////////
.navbar-brand span {
	color: white;
}

.logo {
	display: flex;
	align-items: center;
	gap: 12px;

	.logoText {
		display: flex;
		flex-direction: column;
		gap: 0;

		.logoName {
			font-family: 'Caros', sans-serif;
			color: var(--Text-Gray-900, white);
			font-feature-settings: 'liga' off, 'clig' off;
			font-size: 18px;
			font-style: normal;
			font-weight: 700;
			line-height: 20px; /* 111.111% */
			z-index: 1;
		}

		.parserLabel {
			padding: 0px 4px 0px 5px;
			background-color: #00ffd0;
			border-radius: 4px;
			color: var(--Text-Gray-900, #000433);
			font-feature-settings: 'liga' off, 'clig' off;
			font-family: Caros;
			font-size: 10px;
			font-style: normal;
			font-weight: 600;
			line-height: 20px; /* 200% */
			bottom: 0;
			width: fit-content;
		}
	}
}

//////////////////////////////////////////////////// DESKTOP ///////////////////////////////////////////////////////////////
.desktop {
	.navbarWrapperBG {
		background: linear-gradient(
				to right,
				rgb(0, 0, 0) 10%,
				rgba(0, 0, 0, 0) 50%
			),
			linear-gradient(rgba(0, 4, 51, 0.6), rgba(0, 4, 51, 0.6)),
			url('../../../public/signInBackground.png');
		backdrop-filter: blur(8px);
		background-position: 80% 36.5%;
		background-size: 140%;
		width: 100%;
		height: 220px;
		position: absolute;

		&.mapNavbar {
			height: 160px;
		}
	}

	.navbarComponent {
		width: 1440px;
		margin: 0 auto;
		max-width: 100%;
		padding: 23px 165px 7px 165px;
		height: 220px;
		align-items: start;

		&.mapNavbar {
			height: 160px;
		}

		.header {
			width: 100%;
			display: flex;
			align-items: center;
			justify-content: space-between;

			// .logo {
			// 	flex-grow: 0.6;
			// }

			.navbar-collapse {
				width: fit-content;
				flex-basis: content;
				flex-grow: 0;

				.nav-link {
					font-size: 14px;
					font-weight: bold;
					line-height: 20px;
					color: white;
					padding: 11px 0 !important;
					border-bottom: 3px solid transparent;

					&.active {
						color: white;
					}

					&.disabled {
						color: rgba(var(--bs-emphasis-color-rgb), 0.3);
					}
				}

				/* @media (max-width: 1220px) {
			.nav-link {
				font-size: 10px;
			}
		} */

				.links {
					display: flex;
					flex-direction: row;
					gap: 32px;
					align-items: center;

					& > a {
						&.active {
							border-bottom: 3px solid #00ffd0;
						}

						&:hover {
							color: white;
							border-bottom: 3px solid #00ffd0;
						}
					}

					.dropdown {
						.dropdown-toggle {
							color: var(--Default-White, #fff);
							font-feature-settings: 'liga' off, 'clig' off;
							/* Label / Medium Label */
							font-family: Manrope;
							font-size: 14px;
							font-style: normal;
							font-weight: 700;
							line-height: 20px; /* 142.857% */

							display: flex;
							align-items: center;
							gap: 4px;
							padding: 0;
							background: none;
							border: none;

							box-shadow: 0px 10px 20px 0px rgba(41, 41, 42, 0.07);

							&::after {
								display: none;
							}

							&.show {
								.dropdownArrow {
									transform: rotate(180deg);
									transition: transform 0.5s;
								}
							}

							.dropdownArrow {
								transition: transform 0.5s;
							}
						}

						.dropdown-menu {
							margin-top: 12px;
							padding: 11px 0;
							min-width: fit-content;
							z-index: 1040;

							border-radius: 5px;
							border: 1px solid var(--Text-Gray-300, #d9dbe1);
							background: var(--Default-White, #fff);
							/* Dark Shadow */
							box-shadow: 0px 10px 20px 0px rgba(41, 41, 42, 0.07);

							.dropdown-item {
								padding: 5px 16px;

								color: var(--Text-Gray-900, #000433);
								font-feature-settings: 'liga' off;
								/* Body / Body 2 */
								font-family: Manrope;
								font-size: 14px;
								font-style: normal;
								font-weight: 400;
								line-height: 24px; /* 171.429% */

								&:hover,
								&:active {
									background: var(--Brand-Purple-100, #f1e4ff);
								}
							}
						}
					}

					.exitBtn {
						padding: 0;
						background: none;
						border: none;
					}
				}
			}
		}
	}
}

//////////////////////////////////////////////////// TABLET ///////////////////////////////////////////////////////////////

.tablet {
	.navbarWrapperBG {
		background: linear-gradient(
				to right,
				rgb(0, 0, 0) 10%,
				rgba(0, 0, 0, 0) 50%
			),
			linear-gradient(rgba(0, 4, 51, 0.6), rgba(0, 4, 51, 0.6)),
			url('../../../public/signInBackground.png');
		backdrop-filter: blur(8px);
		background-position: 80% 36.5%;
		background-size: 140%;
		width: 100%;
		height: 220px;
		position: absolute;

		&.objectPageNavbar {
			height: 120px;
		}

		&.mapNavbar {
			height: 105px;
		}
	}

	.navbarComponent {
		width: 1440px;
		margin: 0 auto;
		max-width: 100%;
		padding: 12px 32px 17px 42px;
		height: 220px;
		align-items: start;

		&.objectPageNavbar {
			height: 120px;
		}

		&.mapNavbar {
			height: 105px;
		}

		.header {
			width: 100%;
			display: flex;
			align-items: center;
			justify-content: space-between;

			// .logo {
			// 	flex-grow: 0.6;
			// }

			.navbar-collapse {
				width: fit-content;
				flex-basis: content;
				flex-grow: 0;

				.nav-link {
					font-size: 14px;
					font-weight: bold;
					line-height: 20px;
					color: white;
					padding: 11px 0 !important;
					border-bottom: 3px solid transparent;

					&.active {
						color: white;
					}

					&.disabled {
						color: rgba(var(--bs-emphasis-color-rgb), 0.3);
					}
				}

				/* @media (max-width: 1220px) {
			.nav-link {
				font-size: 10px;
			}
		} */

				.links {
					display: flex;
					flex-direction: row;
					gap: 32px;
					align-items: center;

					& > a {
						&.active {
							border-bottom: 3px solid #00ffd0;
						}

						&:hover {
							color: white;
							border-bottom: 3px solid #00ffd0;
						}
					}

					.dropdown {
						.dropdown-toggle {
							color: var(--Default-White, #fff);
							font-feature-settings: 'liga' off, 'clig' off;
							/* Label / Medium Label */
							font-family: Manrope;
							font-size: 14px;
							font-style: normal;
							font-weight: 700;
							line-height: 20px; /* 142.857% */

							display: flex;
							align-items: center;
							gap: 4px;
							padding: 0;
							background: none;
							border: none;

							box-shadow: 0px 10px 20px 0px rgba(41, 41, 42, 0.07);

							&::after {
								display: none;
							}

							&.show {
								.dropdownArrow {
									transform: rotate(180deg);
									transition: transform 0.5s;
								}
							}

							.dropdownArrow {
								transition: transform 0.5s;
							}
						}

						.dropdown-menu {
							margin-top: 12px;
							padding: 11px 0;
							min-width: fit-content;
							z-index: 1040;

							border-radius: 5px;
							border: 1px solid var(--Text-Gray-300, #d9dbe1);
							background: var(--Default-White, #fff);
							/* Dark Shadow */
							box-shadow: 0px 10px 20px 0px rgba(41, 41, 42, 0.07);

							.dropdown-item {
								padding: 5px 16px;

								color: var(--Text-Gray-900, #000433);
								font-feature-settings: 'liga' off;
								/* Body / Body 2 */
								font-family: Manrope;
								font-size: 14px;
								font-style: normal;
								font-weight: 400;
								line-height: 24px; /* 171.429% */

								&:hover,
								&:active {
									background: var(--Brand-Purple-100, #f1e4ff);
								}
							}
						}
					}

					.exitBtn {
						padding: 0;
						background: none;
						border: none;
					}
				}
			}
		}
	}
}

//////////////////////////////////////////////////// MOBILE ///////////////////////////////////////////////////////////////
.mobile {
	.navbarWrapperBG {
		background: linear-gradient(
				to right,
				rgb(0, 0, 0) 10%,
				rgba(0, 0, 0, 0) 50%
			),
			linear-gradient(rgba(0, 4, 51, 0.6), rgba(0, 4, 51, 0.6)),
			url('../../../public/signInBackground.png');
		backdrop-filter: blur(8px);
		background-position: 80% 36.5%;
		background-size: 140%;
		width: 100%;
		height: 220px;
		position: absolute;

		&.objectPageNavbar {
			height: 100px;
		}

		&.mapNavbar {
			height: 89.75px;
		}
	}

	.navbarComponent {
		justify-content: space-between;
		align-items: start;
		padding: 24px 32px 17px 42px;
		height: 220px;

		&.objectPageNavbar {
			height: 100px;
		}

		&.mapNavbar {
			height: 89.75px;
		}

		.menuBtn {
			border: 0;
			padding: 0;
		}
	}
}
