.pagination {
	display: flex;
	align-items: center;
	gap: 8px;
	margin: 0;

	li {
		a {
			color: var(--Text-Gray-700, #969bab) !important;
			text-align: center;
			font-family: Poppins;
			font-size: 14px;
			font-style: normal;
			font-weight: 500;
			line-height: 144%; /* 20.16px */
			padding: 0 3.5px;
		}

		&.selected {
			a {
				color: var(--Brand-Purple, #8c30f5) !important;
				text-align: center !important;
				font-family: Poppins !important;
				font-size: 14px !important;
				font-style: normal !important;
				font-weight: 500 !important;
				line-height: 144% !important; /* 20.16px */
				padding: 2px 9px !important;
				border-radius: 50% !important;
				background: var(--Brand-Purple-100, #f1e4ff) !important;
			}
		}

		&.disabled {
			a {
				/* Удаляем ::before и используем background-image для самого элемента */
				width: 36px !important;
				height: 36px !important;
				display: block !important;
				background-image: url('../../../../assets/icons/chevron-right-disabled.svg') !important;
				background-size: 24px 24px !important;
				background-position: center !important;
				background-repeat: no-repeat !important;
				border-radius: 50% !important;
				padding: 6px !important;
				background-color: white !important; /* Добавляем белый фон */
			}

			&.next a {
				transform: rotate(180deg) !important;
			}
		}

		&:not(.disabled).next {
			a {
				/* Удаляем ::before и используем background-image для самого элемента */
				width: 36px !important;
				height: 36px !important;
				display: block !important;
				background-image: url('../../../../assets/icons/chevron-right-enabled.svg') !important;
				background-size: 24px 24px !important;
				background-position: center !important;
				background-repeat: no-repeat !important;
				border-radius: 50% !important;
				padding: 6px !important;
				background-color: #8c30f5 !important; /* Добавляем белый фон */
			}
		}

		&:not(.disabled).previous {
			a {
				/* Удаляем ::before и используем background-image для самого элемента */
				width: 36px !important;
				height: 36px !important;
				display: block !important;
				background-image: url('../../../../assets/icons/chevron-right-enabled.svg') !important;
				background-size: 24px 24px !important;
				background-position: center !important;
				background-repeat: no-repeat !important;
				border-radius: 50% !important;
				padding: 6px !important;
				background-color: #8c30f5 !important; /* Добавляем белый фон */
				transform: rotate(180deg) !important;
			}
		}
	}
}
